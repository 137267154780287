import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./SingleUser.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import { HashLoader } from "react-spinners";
import BackElement from "../../components/BackElement";
import Input from "../../components/Input";
import MultiSelector from "../../components/MultiSelector";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import { findWithAttr, reformatDateReverse } from "../../functions/utils";
import SubTitle from "../../components/SubTitle";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    userId: string;
  }>;

export default class SingleUser extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    userId: string;
    newRole: number;
    oldRole: number;
    newStatus: number;
    oldStatus: number;
    statusOptions: any;
    roleOptions: any;
    branch: any;
    branchLoaded: boolean;
    user: any;
    userLoaded: boolean;
    selectedUserRoleObj: Array<any>;
    userRoles: Array<any>;
    change: boolean;
    errMsgDeleteUser: null | string;
    errMsgSaveUser: null | string;
    showConfirmKick: boolean;
    newRoleArr: Array<any>;
    oldRoleArr: Array<any>;
    updateLoading: boolean;
    removeUserLoading: boolean;
    redirect: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      userId: this.props.match.params.userId,
      newRole: 0,
      oldRole: 0,
      newStatus: 0,
      oldStatus: 0,
      statusOptions: [
        { value: 1, label: "Nicht verifiziert" },
        { value: 2, label: "Aktiv" },
      ],
      roleOptions: [
        { value: 1, label: "Mitglied" },
        { value: 2, label: "Besitzer" },
      ],
      branch: {},
      branchLoaded: false,
      user: {},
      userLoaded: false,
      selectedUserRoleObj: [],
      userRoles: [],
      change: false,
      errMsgDeleteUser: null,
      errMsgSaveUser: null,
      showConfirmKick: false,
      newRoleArr: [],
      oldRoleArr: [],
      updateLoading: false,
      removeUserLoading: false,
      redirect: null,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
        },
        this.requestUser
      );
    }
  };

  handleFullyLoaded = () => {
    if (this.state.userLoaded && this.state.branchLoaded) {
      const roles = this.state.branch.roles;
      const roleArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          return {
            id: roleId,
            name: roles[roleId].name,
            selected: this.state.user.roles.includes(roleId),
          };
        }
      );
      this.setState(
        {
          newRoleArr: roleArr,
          oldRoleArr: JSON.parse(JSON.stringify(roleArr)),
        },
        this.checkChange
      );
    }
  };

  requestUser = () => {
    fetchRetry(
      "getUserFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleUser)
      .catch(this.handleError);
  };

  handleUser = ({ data }: any) => {
    this.setState({ userLoaded: true });
    if (data.success) {
      const user = data.data;
      const roleDics: any = { Owner: 2, ViewOnly: 1, Member: 0 };
      this.setState(
        {
          user,
          newStatus: user.accepted ? 1 : 0,
          oldStatus: user.accepted ? 1 : 0,
          newRole: roleDics[user.role],
          oldRole: roleDics[user.role],
        },
        this.handleFullyLoaded
      );
    }
  };

  handleStatusSelected = (e: any) => {
    this.setState(
      { newStatus: e.target.options.selectedIndex },
      this.checkChange
    );
  };

  handleRoleSelected = (e: any) => {
    this.setState(
      { newRole: e.target.options.selectedIndex },
      this.checkChange
    );
  };

  checkRoleArrChange = () => {
    const oldPartnerArr = this.state.oldRoleArr
      .filter((item: any) => item.selected)
      .map((item: any) => item.id);
    const newPartnerObj = this.state.newRoleArr.filter(
      (item: any) => item.selected
    );
    if (newPartnerObj.length !== oldPartnerArr.length) {
      return true;
    } else {
      for (const item of newPartnerObj) {
        if (!oldPartnerArr.includes(item.id)) {
          return true;
        }
      }
    }
    return false;
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.newRole !== this.state.oldRole ||
        this.state.newStatus !== this.state.oldStatus ||
        this.checkRoleArrChange(),
    });
  };

  handleKickUser = () => {
    this.setState({ showConfirmKick: true });
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.newRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ newRoleArr }, this.checkChange);
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.newRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ newRoleArr }, this.checkChange);
  };

  hideConfirmKickModal = () => {
    this.setState({ showConfirmKick: false });
  };

  kickUserFromBranch = () => {
    this.setState({ showConfirmKick: false, removeUserLoading: true });
    fetchRetry(
      "kickUserFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleSuccessKick)
      .catch(this.handleError);
  };

  handleSuccessKick = ({ data }: any) => {
    this.setState({ removeUserLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Der Benutzer wurde erfolgreich aus diesem Bereich entfernt.",
        "success",
        4
      );
      this.setState({
        redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users`,
      });
    } else {
      this.setState({
        errMsgDeleteUser: data.errorMsgDe,
      });
    }
  };

  updateUserInBranch = () => {
    this.setState({ updateLoading: true });
    const roleArr = this.state.newRoleArr
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.id;
      });
    const roleDict = ["Member", "ViewOnly", "Owner"];

    fetchRetry(
      "updateUserInBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        userId: this.state.userId,
        roleArr,
        role: roleDict[this.state.newRole],
        accepted: this.state.newStatus === 1,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdateUserInBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdateUserInBranch = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestUser();
    } else {
      this.setState({ errMsgSaveUser: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded || !this.state.userLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmKick}
          title="Benutzer entfernen"
          msg="Bist du sicher das du diesen Nutzer aus dem Bereich entfernen willst? Die Daten dieses Benutzers werden entgültig gelöscht."
          handleClose={this.hideConfirmKickModal}
          handleConfirm={this.kickUserFromBranch}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Nutzerübersicht"
              to={`/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users`}
            />
            <div className="modal-title-container">
              <span className="modal-title">Nutzerdaten bearbeiten</span>
            </div>
            {/* <MessageContainer msg={this.props.msg} type={this.props.msgType} /> */}
            <br />
            <div>
              <div className="modal-user-container">
                {this.state.branch?.clubSettings?.useMembershipNumber && (
                  <>
                    <SubTitle title="Mitgliedsnummer" />
                    <Input
                      name="Mitgliedsnummer"
                      placeholder="Mitgliedsnummer"
                      value={this.state.user?.membershipNumber}
                      onChange={() => {}}
                      disabled
                    />
                  </>
                )}
                <SubTitle title="Name" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.user.fName}
                    onChange={() => {}}
                    placeholder="Vorname"
                    disabled
                  />
                  <Input
                    value={this.state.user.lName}
                    onChange={() => {}}
                    placeholder="Nachname"
                    disabled
                  />
                </div>
                <SubTitle title="E-Mail" />
                <Input
                  name="email"
                  placeholder="E-Mail"
                  value={this.state.user?.email}
                  onChange={() => {}}
                  disabled
                />
                <SubTitle title="Adresse" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.user?.address?.street}
                    onChange={() => {}}
                    placeholder="Straße"
                    className="street-input"
                    disabled
                  />
                  <Input
                    value={this.state.user?.address?.houseNumber}
                    onChange={() => {}}
                    placeholder="Nr."
                    className="house-number-input"
                    disabled
                  />
                </div>
                <div className="profile-location-container">
                  <Input
                    value={this.state.user?.address?.zipcode}
                    onChange={() => {}}
                    placeholder="PLZ"
                    className="zipcode-input"
                    disabled
                  />
                  <Input
                    value={this.state.user?.address?.city}
                    onChange={() => {}}
                    placeholder="Stadt"
                    className="city-input"
                    disabled
                  />
                </div>
                <SubTitle title="Telefonnummer" />
                <Input
                  value={this.state.user.phoneNumber}
                  onChange={() => {}}
                  placeholder="Telefonnummer"
                  disabled
                />
                <SubTitle title="Geburtsdatum" />
                <input
                  type="date"
                  className="course-input"
                  value={reformatDateReverse(this.state.user?.birthDate)}
                  onChange={() => {}}
                  disabled
                />
                <SubTitle title="Rechte" />
                <select
                  placeholder="Rechte"
                  className="input-container"
                  value={this.state.newRole}
                  onChange={this.handleRoleSelected}
                >
                  <option value="0">Mitglied</option>
                  <option value="1">Mitarbeiter</option>
                  <option value="2">Admin</option>
                </select>
                <SubTitle title="Status" />
                <select
                  placeholder="Status"
                  className="input-container"
                  value={this.state.newStatus}
                  onChange={this.handleStatusSelected}
                >
                  {this.state.user?.requested && (
                    <option value="0">Nicht verifiziert</option>
                  )}
                  <option value="1">Aktiv</option>
                </select>
                <SubTitle title="Rollen" />
                <MultiSelector
                  searchTextPlaceholder="Rolle"
                  onSelect={this.handleRoleSelect}
                  onUnselect={this.handleRoleUnselect}
                  arr={this.state.newRoleArr}
                />
              </div>
            </div>
            <div className="medium-space"></div>
            <ErrorMessage message={this.state.errMsgSaveUser} />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateUserInBranch}
              title="Speichern"
            />
            <ErrorMessage message={this.state.errMsgDeleteUser} />
            <SecButton
              change={true}
              color="red"
              loading={this.state.removeUserLoading}
              onClick={this.handleKickUser}
              title="Nutzer entfernen"
            />
          </div>
        </div>
      </>
    );
  }
}
