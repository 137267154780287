import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./Locations.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
    locationId: string;
  }>;

export default class Locations extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    locationId: string;
    branch: any;
    branchLoaded: boolean;
    locationName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchLoaded: false,
      branchId: this.props.match.params.branchId,
      locationId: this.props.match.params.locationId,
      locationName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  componentDidUpdate = () => {
    const propsLocationId = this.props.match.params.locationId;
    if (this.state.locationId !== propsLocationId) {
      if (propsLocationId in this.state.branch.locations) {
        this.setState({ locationId: propsLocationId }, this.updateLocationData);
      } else {
        this.setState({ locationId: propsLocationId }, this.requestBranch);
      }
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdate = () => {
    this.setState({ updateLoading: false });
    this.props.refreshNavbar();
    this.requestBranch();
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
        },
        this.updateLocationData
      );
    }
  };

  updateLocationData = () => {
    if (
      this.state.locationId &&
      this.state.locationId in this.state.branch.locations
    ) {
      const location = this.state.branch.locations[this.state.locationId];
      this.setState(
        {
          locationName: location.name,
        },
        this.checkChange
      );
    }
  };

  updateLocation = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateLocation",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        locationId: this.state.locationId,
        name: this.state.locationName,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleLocationNameChange = (val: any) => {
    this.setState({ locationName: val }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.locationName !==
        this.state.branch.locations[this.state.locationId].name,
    });
  };

  handleDeleteLocation = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleLocationDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteLocation",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        locationId: this.state.locationId,
      },
      1,
      5
    )
      .then(this.handleSuccessLocationDeleted)
      .catch(this.handleError);
  };

  handleSuccessLocationDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Örtlichkeit wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState(
        {
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/locations`,
        },
        () => {
          this.updateLocationData();
          this.props.refreshNavbar();
        }
      );
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du die Örtlichkeit "${
            this.state.locationId &&
            this.state.branch?.locations &&
            this.state.branch?.locations[this.state.locationId]?.name
              ? this.state.branch.locations[this.state.locationId].name
              : "-"
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleLocationDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.locationId &&
              this.state.branch?.locations &&
              Object.keys(this.state.branch.locations).length > 0 && (
                <>
                  <div className="subscreen-box-container">
                    <Title title="Einstellungen" />
                    <div className="branch-subtitle-container">
                      <h3>Name</h3>
                    </div>
                    <Input
                      name="role-name-input"
                      value={this.state.locationName}
                      placeholder="Örtlichkeit name"
                      onChange={this.handleLocationNameChange}
                    />

                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updateLocation}
                      title="Speichern"
                    />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.deleteLoading}
                      onClick={this.handleDeleteLocation}
                      title="Örtlichkeit löschen"
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
