import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./CreateClubModal.css";
import "./TextInputModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Input";
import SecButton from "../SecButton";

export default class CreateClubModal extends React.Component<
  {
    show: boolean;
    handleClose: any;
    handleConfirm: any;
    loading: boolean;
    title: string;
    btnText: string;
  },
  { branchName: string; identifier: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      branchName: "",
      identifier: "",
    };
  }

  handleBranchNameChange = (val: any) => {
    this.setState({ branchName: val });
  };

  handleBranchIdentifierChange = (val: any) => {
    this.setState({ identifier: val });
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="create Branch"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="user-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={["fas", "times"]} />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">{this.props.title}</span>
            </div>
            <Input
              name="text-input"
              placeholder="Vereinsname"
              value={this.state.branchName}
              onChange={this.handleBranchNameChange}
            />
            <Input
              name="text-input"
              placeholder="Subdomain"
              value={this.state.identifier}
              onChange={this.handleBranchIdentifierChange}
            />
            <SecButton
              change={this.state.branchName.length !== 0}
              color="green"
              loading={this.props.loading}
              onClick={() => {
                this.props.handleConfirm(
                  this.state.branchName,
                  this.state.identifier
                );
              }}
              title={this.props.btnText}
            />
          </div>
        </Modal>
      </>
    );
  }
}
