import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Booking.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import Switch from "../../components/Switch";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Booking extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branch: any;
    branchLoaded: boolean;
    anonymousBooking: boolean;
    change: boolean;
    updateLoading: boolean;
  }
> {
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branch: {},
      branchLoaded: false,
      anonymousBooking: false,
      change: false,
      updateLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
          anonymousBooking: branch.settings.anonymousBooking,
        },
        this.checkChange
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleAnonymousBookingChange = () => {
    this.setState(
      { anonymousBooking: !this.state.anonymousBooking },
      this.checkChange
    );
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.anonymousBooking !==
        this.state.branch.settings.anonymousBooking,
    });
  };

  updateBranch = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        settings: {
          anonymousBooking: this.state.anonymousBooking,
        },
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    this.requestBranch();
  };

  render() {
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Buchungssystem" />
            <div className="branch-subtitle-container">
              <h3>Buchungsansicht anonymisieren</h3>
            </div>
            <Switch
              value={this.state.anonymousBooking}
              onChange={this.handleAnonymousBookingChange}
            />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateBranch}
              title="Speichern"
            />
          </div>
        </div>
      </>
    );
  }
}
