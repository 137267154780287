import React from "react";
import { MainContext } from "../../contexts/MainContext";
import { RouteComponentProps } from "react-router-dom";
import "./SingleUser.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import { HashLoader } from "react-spinners";
import BackElement from "../../components/BackElement";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import { reformatDateReverse } from "../../functions/utils";
import SubTitle from "../../components/SubTitle";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    userId: string;
  }>;

export default class SingleUser extends React.Component<
  ComposedProps,
  {
    clubId: string;
    userId: string;
    club: any;
    clubLoaded: boolean;
    user: any;
    userLoaded: boolean;
    change: boolean;
    errMsgDeleteUser: null | string;
    errMsgSaveUser: null | string;
    updateLoading: boolean;
    removeUserLoading: boolean;
    membershipNumber: string;
    addAdminLoading: boolean;
    addAdminErrMsg: null | string;
    removeAdminLoading: boolean;
    removeAdminErrMsg: null | string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      userId: this.props.match.params.userId,
      club: {},
      clubLoaded: false,
      user: {},
      userLoaded: false,
      change: false,
      errMsgDeleteUser: null,
      errMsgSaveUser: null,
      updateLoading: false,
      removeUserLoading: false,
      membershipNumber: "",
      addAdminLoading: false,
      addAdminErrMsg: null,
      removeAdminLoading: false,
      removeAdminErrMsg: null,
    };
  }

  componentDidMount = () => {
    this.requestUser();
    this.requestClub();
  };

  requestClub = () => {
    fetchRetry(
      "getClubsFromClubId",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data[this.state.clubId];
      this.setState({
        club: club,
        clubLoaded: true,
      });
    }
  };

  requestUser = () => {
    fetchRetry(
      "getUserFromClub",
      {
        clubId: this.state.clubId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleUser)
      .catch(this.handleError);
  };

  handleUser = ({ data }: any) => {
    this.setState({ userLoaded: true });
    if (data.success) {
      const user = data.data;
      this.setState(
        {
          user,
          membershipNumber: user.membershipNumber,
        },
        this.checkChange
      );
    }
  };

  checkChange = () => {
    this.setState({
      change: this.state.user.membershipNumber !== this.state.membershipNumber,
    });
  };

  updateUserInClub = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateUserInClub",
      {
        clubId: this.state.clubId,
        userId: this.state.userId,
        membershipNumber: this.state.membershipNumber,
      },
      1,
      5
    )
      .then(this.updateLoadingSuccess)
      .catch(this.handleError);
  };

  updateLoadingSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestUser();
    }
  };

  addAdmin = () => {
    this.setState({ addAdminLoading: true });
    fetchRetry(
      "addOwnerToClub",
      { clubId: this.state.clubId, userId: this.state.userId },
      1,
      5
    )
      .then(this.handleAddAdminSuccess)
      .catch(this.handleError);
  };

  handleAddAdminSuccess = ({ data }: any) => {
    this.setState({ addAdminLoading: false });
    if (data.success) {
      this.requestUser();
      this.setState({ addAdminErrMsg: null });
    } else {
      this.setState({ addAdminErrMsg: data.errorMsgDe });
    }
  };

  removeAdmin = () => {
    this.setState({ removeAdminLoading: true });
    fetchRetry(
      "removeOwnerFromClub",
      { clubId: this.state.clubId, userId: this.state.userId },
      1,
      5
    )
      .then(this.handleRemoveAdminSuccess)
      .catch(this.handleError);
  };

  handleRemoveAdminSuccess = ({ data }: any) => {
    this.setState({ removeAdminLoading: false });
    if (data.success) {
      this.requestUser();
      this.setState({ removeAdminErrMsg: null });
    } else {
      this.setState({ removeAdminErrMsg: data.errorMsgDe });
    }
  };

  handleMembershipNumberChange = (val: string) => {
    this.setState({ membershipNumber: val }, this.checkChange);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (!this.state.userLoaded && !this.state.clubLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Nutzerübersicht"
              to={`/club-settings/${this.state.clubId}/users`}
            />
            <div className="modal-title-container">
              <span className="modal-title">Nutzerdaten bearbeiten</span>
            </div>
            <br />
            <div>
              <div className="modal-user-container">
                {this.state.club?.settings?.useMembershipNumber && (
                  <>
                    <SubTitle title="Mitgliedsnummer" />
                    <Input
                      name="Mitgliedsnummer"
                      placeholder="Mitgliedsnummer"
                      value={this.state.membershipNumber}
                      onChange={this.handleMembershipNumberChange}
                    />
                  </>
                )}
                <SubTitle title="Name" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.user.fName}
                    onChange={() => {}}
                    placeholder="Vorname"
                    disabled
                  />
                  <Input
                    value={this.state.user.lName}
                    onChange={() => {}}
                    placeholder="Nachname"
                    disabled
                  />
                </div>
                <SubTitle title="E-Mail" />
                <Input
                  name="email"
                  placeholder="E-Mail"
                  value={this.state.user?.email}
                  onChange={() => {}}
                  disabled
                />
                <SubTitle title="Adresse" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.user?.address?.street}
                    onChange={() => {}}
                    placeholder="Straße"
                    className="street-input"
                    disabled
                  />
                  <Input
                    value={this.state.user?.address?.houseNumber}
                    onChange={() => {}}
                    placeholder="Nr."
                    className="house-number-input"
                    disabled
                  />
                </div>
                <div className="profile-location-container">
                  <Input
                    value={this.state.user?.address?.zipcode}
                    onChange={() => {}}
                    placeholder="PLZ"
                    className="zipcode-input"
                    disabled
                  />
                  <Input
                    value={this.state.user?.address?.city}
                    onChange={() => {}}
                    placeholder="Stadt"
                    className="city-input"
                    disabled
                  />
                </div>
                <SubTitle title="Telefonnummer" />
                <Input
                  value={this.state.user.phoneNumber}
                  onChange={() => {}}
                  placeholder="Telefonnummer"
                  disabled
                />
                <SubTitle title="Geburtsdatum" />
                <input
                  type="date"
                  className="course-input"
                  value={reformatDateReverse(this.state.user?.birthDate)}
                  onChange={() => {}}
                  disabled
                />
                <SubTitle title="Rechte" />
                <select
                  placeholder="Rechte"
                  className="input-container"
                  value={this.state.user.role === "Owner" ? 1 : 0}
                  onChange={() => {}}
                  disabled
                >
                  <option value="0">Mitglied</option>
                  <option value="1">Admin</option>
                </select>
                <SubTitle title="Status" />
                <select
                  placeholder="Status"
                  className="input-container"
                  value={this.state.user.accepted ? 0 : 1}
                  onChange={() => {}}
                  disabled
                >
                  {this.state.user?.requested && (
                    <option value="0">Nicht verifiziert</option>
                  )}
                  <option value="1">Aktiv</option>
                </select>
              </div>
            </div>
            <div className="medium-space"></div>
            <ErrorMessage message={this.state.errMsgSaveUser} />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateUserInClub}
              title="Speichern"
            />

            {this.context.isAdmin && (
              <>
                {this.state.user.role === "Owner" ? (
                  <>
                    <ErrorMessage message={this.state.removeAdminErrMsg} />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.removeAdminLoading}
                      onClick={this.removeAdmin}
                      title="Admin Rechte entfernen"
                    />
                  </>
                ) : (
                  <>
                    <ErrorMessage message={this.state.addAdminErrMsg} />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.addAdminLoading}
                      onClick={this.addAdmin}
                      title="Zum Admin machen"
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
