import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import app from "./services/firebase";
import Cookies from "universal-cookie";
import "./App.css";

import Home from "./screens/Home";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import ClubSearch from "./screens/ClubSearch";
import Club from "./screens/Club";
import ClubSettings from "./screens/ClubSettings";
import Branch from "./screens/Branch";
import BranchSettings from "./screens/BranchSettings";
import MollieEndpoint from "./screens/MollieEndpoint";
import Impressum from "./screens/Impressum";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";
// import { AuthProvider } from "./contexts/MainContext";
// import { NotificationProvider } from "./contexts/NotificationContext";
// import { InfoProvider } from "./contexts/MainContext";
import { MainProvider } from "./contexts/MainContext";
import ForgotPassword from "./screens/ForgotPassword";
import setAdmin from "./screens/setAdmin";

export default class App extends React.Component<
  {},
  { showCookieBanner: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showCookieBanner: false,
    };
  }

  componentDidMount = () => {
    // check if the cookie exists
    const cookies = new Cookies();
    const cookiesAllowed = cookies.get("cookies");
    if (cookiesAllowed === undefined) {
      // show cookie banner
      this.setState({ showCookieBanner: true });
    } else {
      if (cookiesAllowed) {
        // cookies are allowed
        this.activateAnalytics();
      } else {
        // cookies are not allowed
      }
    }
  };

  denyCookies = () => {
    this.setState({ showCookieBanner: false });
    const cookies = new Cookies();
    cookies.set("cookies", false, { path: "/", maxAge: 31536000 });
  };

  acceptCookies = () => {
    this.setState({ showCookieBanner: false });
    const cookies = new Cookies();
    cookies.set("cookies", true, { path: "/", maxAge: 31536000 });
    this.activateAnalytics();
  };

  activateAnalytics = () => {
    app.performance();
    app.analytics();
  };

  render() {
    return (
      <>
        <Router>
          <MainProvider>
            {/* <AuthProvider> */}
            {/* <NotificationProvider> */}
              <div className="main-inner-container">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route
                    exact
                    path="/forgotPassword"
                    component={ForgotPassword}
                  />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/sign-up" component={SignUp} />
                  <Route exact path="/clubsearch" component={ClubSearch} />
                  <Route exact path="/setAdmin" component={setAdmin} />
                  <Route
                    path="/club/:clubId/branch/:branchId/:site"
                    component={Branch}
                  />
                  <Route
                    path="/club/:clubId/branch/:branchId/"
                    component={Branch}
                  />
                  <Route
                    path="/club/:clubId/branch-settings/:branchId/:site/:objectId"
                    component={BranchSettings}
                  />
                  <Route
                    path="/club/:clubId/branch-settings/:branchId/:site"
                    component={BranchSettings}
                  />
                  <Route path="/club/:clubId/:site" component={Club} />
                  <Route path="/club/:clubId" component={Club} />
                  <Route
                    path="/club-settings/:clubId/:site/:branchId"
                    component={ClubSettings}
                  />
                  <Route
                    path="/club-settings/:clubId/:site"
                    component={ClubSettings}
                  />
                  <Route path="/mollie-endpoint" component={MollieEndpoint} />
                  <Route path="/impressum" component={Impressum} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/privacy" component={Privacy} />
                </Switch>
              </div>
            {/* </NotificationProvider> */}
            {/* </AuthProvider> */}
          </MainProvider>
        </Router>
        {this.state.showCookieBanner && (
          <div className="cookie-banner">
            <h2>Diese Seite verwendet Cookies.</h2>
            <p>
              Wir verwenden Cookies, um die Webseite zu verbessern. Dazu werden
              Cookies verwendet, die für unsere kommerziellen Unternehmensziele
              notwendig sind. Diese dienen lediglich für anonymen Statistiken.
            </p>
            <div className="cookie-btn-container">
              <div className="cookie-deny-btn" onClick={this.denyCookies}>
                ablehnen
              </div>
              <div className="cookie-accept-btn" onClick={this.acceptCookies}>
                Akzeptieren
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
