import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { dateToFullStr, firebaseDateToDateObj } from "../functions/utils";

export default class BlockCourseElementManage extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    title: string;
    description: string;
    when: string;
    courses: Array<any>;
    freePlaces: number;
    price: string;
    imgUrl: string;
    editable: boolean;
    viewable: boolean;
    deleted: boolean;
  },
  { showMore: boolean }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    return (
      <>
        <div
          className={`course-container ${
            this.props.deleted ? " grayscale" : ""
          }`}
        >
          {(this.props.editable || this.props.viewable) && (
            <div className="course-edit-icons">
              <Link
                to={`/club/${this.props.clubId}/branch/${this.props.branchId}/participants/${this.props.courseId}`}
                className="black"
              >
                <FontAwesomeIcon
                  icon={["fas", "users"]}
                  size="lg"
                  onClick={() => {}}
                  className="course-settings-icon"
                />
              </Link>
              {this.props.editable && (
                <Link
                  to={`/club/${this.props.clubId}/branch/${this.props.branchId}/edit-course/${this.props.courseId}`}
                  className="black"
                >
                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="lg"
                    onClick={() => {}}
                    className="course-settings-icon"
                  />
                </Link>
              )}
            </div>
          )}
          <div
            className={`inner-course-container ${
              this.props.editable ? "inner-course-container-editable" : ""
            }`}
          >
            <div>
              {!this.props.imgUrl ? (
                <div className="course-img">
                  <FontAwesomeIcon
                    icon={["fas", "image"]}
                    size="6x"
                    className="image-icon"
                  />
                </div>
              ) : (
                <img
                  className="course-img"
                  src={this.props.imgUrl}
                  alt="Kursbild"
                />
              )}
            </div>
            <div className="course-info-container">
              <div className="course-info-inner-container">
                <div className="course-title-container">{this.props.title}</div>
                <div>{this.props.description}</div>
                <div className="course-text-container">
                  Termine:{" "}
                  <span className="normal-weight">
                    {this.props.courses.length}
                  </span>
                </div>
                <div className="course-text-container">
                  Wann:{" "}
                  <span className="normal-weight">
                    {this.props.courses
                      .slice(
                        0,
                        this.state.showMore ? this.props.courses.length : 3
                      )
                      .map((course: any) => (
                        <div>
                          -{" "}
                          {dateToFullStr(
                            firebaseDateToDateObj(course.startTime)
                          )}
                        </div>
                      ))}
                  </span>
                  {this.props.courses.length > 3 && (
                    <div
                      className="more-info-container"
                      onClick={this.toggleShowMore}
                    >
                      <div className="more-info-inner-container">
                        {!this.state.showMore ? (
                          <FontAwesomeIcon
                            icon={["fas", "chevron-down"]}
                            size="sm"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={["fas", "chevron-up"]}
                            size="sm"
                          />
                        )}
                      </div>
                      {this.state.showMore
                        ? "weniger Anzeigen"
                        : "mehr Anzeigen"}
                    </div>
                  )}
                </div>
                <div className="course-text-container">
                  Freie Plätze:{" "}
                  <span className="normal-weight">{this.props.freePlaces}</span>
                </div>
                <div className="course-text-container">
                  Preis:{" "}
                  <span className="normal-weight">{this.props.price}€</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
