import React from "react";
import { Link } from "react-router-dom";
import "./NavbarElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class LogoNavbarElement extends React.Component<
  {
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Link
        to={"/"}
        className="navbar-element navbar-element-logo"
        onClick={() => {}}
      >
        <div className="navbar-icon-logo-container">
          <FontAwesomeIcon
            icon={["fas", "burn"]}
            size="lg"
          />
        </div>
        <div>
          <span>bookable</span>
        </div>
      </Link>
    );
  }
}
