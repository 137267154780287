import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { dateToFullStr } from "../functions/utils";

export default class SingleCourseElementClub extends React.Component<
  {
    clubId: string;
    courseId: string;
    branchId: string;
    title: string;
    description: string;
    when: string;
    where: string;
    freePlaces: number;
    price: string;
    imgUrl: string;
    bookableFrom: any;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    const bookableFrom = this.props.bookableFrom
      ? this.props.bookableFrom > new Date()
        ? this.props.bookableFrom
        : null
      : null;
    return (
      <>
        <div className="course-container">
          <div>
            {!this.props.imgUrl ? (
              <div className="course-img">
                <FontAwesomeIcon
                  icon={["fas", "image"]}
                  size="6x"
                  className="image-icon"
                />
              </div>
            ) : (
              <img
                className="course-img"
                src={this.props.imgUrl}
                alt="Kursbild"
              />
            )}
          </div>
          <div className="course-info-container">
            <div className="course-info-inner-container">
              <div className="course-title-container">{this.props.title}</div>
              <div>{this.props.description}</div>
              <div className="course-text-container">
                Wann: <span className="normal-weight">{this.props.when}</span>
              </div>
              {this.props.where && (
                <div className="course-text-container">
                  Wo: <span className="normal-weight">{this.props.where}</span>
                </div>
              )}
              <div className="course-text-container">
                Freie Plätze:{" "}
                <span className="normal-weight">{this.props.freePlaces}</span>
              </div>
              <div className="course-text-container">
                Preis:{" "}
                <span className="normal-weight">{this.props.price}€</span>
              </div>
            </div>
            <div>
              <Link
                to={`/club/${this.props.clubId}/branchCourse/${this.props.branchId}/${this.props.courseId}`}
                className={`course-book-btn no-underline${
                  bookableFrom ? " course-book-btn-gray" : ""
                }`}
              >
                {bookableFrom
                  ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                  : this.context.userData
                  ? "Kurs Buchen"
                  : "Kurs als Gast buchen"}
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
