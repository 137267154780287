import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import "./ClubSettings.css";
import ClubSettingsNavbar from "../components/navbars/ClubSettingsNavbar";
import TopMenuBar from "../components/TopMenuBar";
import Appearance from "./ClubSettingSubScreens/Appearance";
import Users from "./ClubSettingSubScreens/Users";
import Branches from "./ClubSettingSubScreens/Branches";
import TextInputModal from "../components/modals/TextInputModal";
import Footer from "../components/Footer";
import { fetchRetry } from "../functions/request";
import Settings from "./ClubSettingSubScreens/Settings";
import SingleUser from "./ClubSettingSubScreens/SingleUser";
import { MainContext } from "../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    site: string;
    branchId: string;
  }>;

export default class ClubSettings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    branchArr: Array<any>;
    showMobileNav: boolean;
    showCreateBranchModal: boolean;
    createBranchLoading: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      branchArr: [],
      showMobileNav: false,
      showCreateBranchModal: false,
      createBranchLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestClub();
  };

  requestClub = () => {
    this.context.requestClub(this.state.clubId);
    fetchRetry(
      "getClubsFromClubId",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data[this.state.clubId];
      const branchArr: any = [];
      for (const branchId in club.branches) {
        const branch = club.branches[branchId];
        branch.id = branchId;
        branchArr.push(branch);
      }
      branchArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );

      this.setState({
        club,
        branchArr,
      });
    }
  };

  handleCreateBranch = (name: string) => {
    this.setState({ createBranchLoading: true });
    fetchRetry(
      "createBranch",
      {
        clubId: this.state.clubId,
        name: name,
        type: "sport",
      },
      1,
      5
    )
      .then(this.handleSuccessBranchCreated)
      .catch(this.handleError);
  };

  handleSuccessBranchCreated = ({ data }: any) => {
    this.setState({ createBranchLoading: false });
    if (data.success) {
      this.setState(
        {
          showCreateBranchModal: false,
        },
        this.requestClub
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleShowMobileNav = () => {
    this.setState({ showMobileNav: true });
  };

  handleHideMobileNav = () => {
    this.setState({ showMobileNav: false });
  };

  handleShowCreateBranchModal = () => {
    this.setState({ showCreateBranchModal: true });
  };

  handleHideCreateBranchModal = () => {
    this.setState({ showCreateBranchModal: false });
  };

  render() {
    const branchArr: any = [];
    for (const branchId in this.context.club.branches) {
      const branch = this.context.club.branches[branchId];
      branch.id = branchId;
      branchArr.push(branch);
    }
    branchArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );

    return (
      <>
        <div className="cs-main-container">
          <TextInputModal
            show={this.state.showCreateBranchModal}
            handleClose={this.handleHideCreateBranchModal}
            handleConfirm={this.handleCreateBranch}
            loading={this.state.createBranchLoading}
            btnText="Bereich erstellen"
            textBoxPlaceholder="Bereichname"
            title="Erstellen"
          />
          <ClubSettingsNavbar
            selectedScreen={this.props.match.params.site}
            clubId={this.state.clubId}
            branchArr={branchArr}
            showMobile={this.state.showMobileNav}
            selectedBranch={this.props.match.params.branchId}
            onCreateBranch={this.handleShowCreateBranchModal}
            hideMobileNav={this.handleHideMobileNav}
          />
          <TopMenuBar handleShowMobileNav={this.handleShowMobileNav} />
          <div className="cs-inner-container">
            <div className="br-padding-container">
              <Route
                exact
                path="/club-settings/:clubId/appearance"
                component={Appearance}
              />
              <Route
                exact
                path="/club-settings/:clubId/users"
                component={Users}
              />
              <Route
                exact
                path="/club-settings/:clubId/users/:userId"
                component={SingleUser}
              />
              <Route
                exact
                path="/club-settings/:clubId/settings"
                component={Settings}
              />

              <Route
                path="/club-settings/:clubId/branches/:branchId"
                render={(props) => (
                  <Branches {...props} refreshNavbar={this.requestClub} />
                )}
              />
              {/* <Route
                exact
                path="/club-settings/:clubId/branches/"
                render={(props) => (
                  <Branches {...props} refreshNavbar={this.requestClub} />
                )}
              /> */}
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
