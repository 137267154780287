import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { isToday } from "../functions/utils";
import { month } from "../services/constants";
import "./Calendar.css";

export default class Calendar extends React.Component<
  {
    setSelectedDates: any;
    onSelectedNegativeDatesChange?: any;
    weekDay?: number;
    negativeDates?: Array<string>;
    startDate?: Date;
    endDate?: Date | null;
    selectedDates: Array<string>;
  },
  {
    month: number;
    year: number;
    days: Array<any>;
    daysSplit: Array<Array<any>>;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      days: [],
      daysSplit: [],
    };
  }

  componentWillReceiveProps = (nextProps: any) => {
    this.updateCalendar(
      nextProps.selectedDates,
      nextProps.weekDay,
      nextProps.negativeDates ? nextProps.negativeDates : [],
      nextProps.startDate,
      nextProps.endDate
    );
  };

  daysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate();
  };

  startDayOfWeek = (year: number, month: number) => {
    const dayOfWeek = new Date(year, month - 1, 1).getDay();
    return dayOfWeek !== 0 ? dayOfWeek - 1 : 6;
  };

  componentDidMount = () => {
    this.updateCalendar(
      this.props.selectedDates,
      this.props.weekDay,
      this.props.negativeDates ? this.props.negativeDates : [],
      this.props.startDate,
      this.props.endDate
    );
  };

  updateCalendar = (
    selectedDates: Array<string>,
    weekDay: number | undefined,
    negativeDates: Array<any>,
    startDate?: null | Date,
    endDate?: null | Date
  ) => {
    const daysInMonth = this.daysInMonth(this.state.year, this.state.month);
    const daysInLastMonth = this.daysInMonth(
      this.state.year,
      this.state.month - 1
    );
    const startDayOfWeek = this.startDayOfWeek(
      this.state.year,
      this.state.month
    );
    const days = [];
    const daysSplit: Array<Array<any>> = [];
    for (let i = 0; i < startDayOfWeek; i++) {
      const dayNum = daysInLastMonth - startDayOfWeek + i + 1;
      const date = new Date(this.state.year, this.state.month - 2, dayNum);
      const dateStr = `${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()}`;
      const selected =
        weekDay !== undefined
          ? weekDay === date.getDay() &&
            !negativeDates.includes(dateStr) &&
            (!startDate || date >= startDate) &&
            (!endDate || date <= endDate)
          : selectedDates.includes(dateStr);
      const negSelected = negativeDates.includes(dateStr);
      days.push({
        day: dayNum,
        currMonth: false,
        lastMonth: true,
        nextMonth: false,
        selected: selected,
        negSelected: negSelected,
        today: isToday(date),
      });
    }
    for (let i = 0; i < daysInMonth; i++) {
      const dayNum = i + 1;
      const date = new Date(this.state.year, this.state.month - 1, dayNum);
      const dateStr = `${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()}`;
      const selected =
        weekDay !== undefined
          ? weekDay === date.getDay() &&
            !negativeDates.includes(dateStr) &&
            (!startDate || date >= startDate) &&
            (!endDate || date <= endDate)
          : selectedDates.includes(dateStr);

      const negSelected = negativeDates.includes(dateStr);
      days.push({
        day: dayNum,
        currMonth: true,
        lastMonth: false,
        nextMonth: false,
        selected: selected,
        negSelected: negSelected,
        today: isToday(date),
      });
    }
    const daysLeft = 42 - days.length;
    for (let i = 0; i < daysLeft; i++) {
      const dayNum = i + 1;
      const date = new Date(this.state.year, this.state.month, dayNum);
      const dateStr = `${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()}`;
      const selected =
        weekDay !== undefined
          ? weekDay === date.getDay() &&
            !negativeDates.includes(dateStr) &&
            (!startDate || date >= startDate) &&
            (!endDate || date <= endDate)
          : selectedDates.includes(dateStr);

      const negSelected = negativeDates.includes(dateStr);
      days.push({
        day: dayNum,
        currMonth: false,
        lastMonth: false,
        nextMonth: true,
        selected: selected,
        negSelected: negSelected,
        today: isToday(date),
      });
    }

    let dateArr: Array<any> = [];
    days.forEach((day: any, index: number) => {
      if (index % 7 === 0) {
        dateArr = [];
        daysSplit.push(dateArr);
      }
      dateArr.push(day);
    });
    this.setState({ daysSplit, days });
  };

  handlePreviousMonth = () => {
    const nextMonth = this.state.month - 1;
    if (nextMonth < 1) {
      this.setState({ year: this.state.year - 1, month: 12 }, () => {
        this.updateCalendar(
          this.props.selectedDates,
          this.props.weekDay,
          this.props.negativeDates ? this.props.negativeDates : [],
          this.props.startDate,
          this.props.endDate
        );
      });
    } else {
      this.setState({ month: nextMonth }, () => {
        this.updateCalendar(
          this.props.selectedDates,
          this.props.weekDay,
          this.props.negativeDates ? this.props.negativeDates : [],
          this.props.startDate,
          this.props.endDate
        );
      });
    }
  };

  handleNextMonth = () => {
    const nextMonth = this.state.month + 1;
    if (nextMonth > 12) {
      this.setState({ year: this.state.year + 1, month: 1 }, () => {
        this.updateCalendar(
          this.props.selectedDates,
          this.props.weekDay,
          this.props.negativeDates ? this.props.negativeDates : [],
          this.props.startDate,
          this.props.endDate
        );
      });
    } else {
      this.setState({ month: nextMonth }, () => {
        this.updateCalendar(
          this.props.selectedDates,
          this.props.weekDay,
          this.props.negativeDates ? this.props.negativeDates : [],
          this.props.startDate,
          this.props.endDate
        );
      });
    }
  };

  handleDayClick = (day: any) => {
    if (day.lastMonth) {
      this.handlePreviousMonth();
    } else if (day.nextMonth) {
      this.handleNextMonth();
    } else {
      const strDate = `${day.day}.${this.state.month}.${this.state.year}`;
      let selectedArr = this.props.selectedDates;
      let negativeDates = this.props.negativeDates
        ? this.props.negativeDates
        : [];
      if (negativeDates.includes(strDate)) {
        negativeDates = negativeDates.filter((s) => s !== strDate);
      } else {
        negativeDates.push(strDate);
      }
      if (selectedArr.includes(strDate)) {
        selectedArr = selectedArr.filter((s) => s !== strDate);
      } else {
        selectedArr.push(strDate);
      }
      this.props.setSelectedDates(selectedArr);
      this.props.onSelectedNegativeDatesChange(negativeDates);
    }
  };

  render() {
    return (
      <>
        <div className="calendar-title-container">
          <span className="calendar-date">
            {month[this.state.month - 1].name} {this.state.year}
          </span>
          <div className="arrow-container">
            <div>
              <FontAwesomeIcon
                icon={["fas", "chevron-left"]}
                size="lg"
                onClick={this.handlePreviousMonth}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={["fas", "chevron-right"]}
                size="lg"
                onClick={this.handleNextMonth}
              />
            </div>
          </div>
        </div>
        <table className="calendar-table">
          <thead>
            <tr>
              <th>Mo</th>
              <th>Di</th>
              <th>Mi</th>
              <th>Do</th>
              <th>Fr</th>
              <th>Sa</th>
              <th>So</th>
            </tr>
          </thead>
          <tbody>
            {this.state.daysSplit.map((week: any, index: number) => (
              <tr key={index}>
                {week.map((day: any, index: number) => (
                  <td
                    className={`${
                      day.lastMonth || day.nextMonth ? "day-no-focus" : ""
                    }`}
                    onClick={() => {
                      this.handleDayClick(day);
                    }}
                    key={index}
                  >
                    <div
                      className={`${
                        day.negSelected ? "day-outer-circle " : ""
                      } ${
                        day.selected
                          ? day.lastMonth || day.nextMonth
                            ? "day-circle day-circle-low "
                            : "day-circle "
                          : "day-circle-hover "
                      } ${day.today ? "day-today " : ""}`}
                    >
                      {day.day}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}
