import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Roles.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Input from "../../components/Input";
import { objectToArray, findWithAttr } from "../../functions/utils";
import Switch from "../../components/Switch";
import MultiSelector from "../../components/MultiSelector";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import ErrorMessage from "../../components/ErrorMessage";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
    roleId: string;
  }>;

export default class Role extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    roleId: string;
    branch: any;
    branchLoaded: boolean;
    roleName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
    roleArr: Array<any>;
    maxBookingLength: number;
    minBookingLength: number;
    mayBook: boolean;
    maxActiveBookings: number;
    default: boolean;
    bookingTypeArr: Array<any>;
    errorMessageDelete: string | null;
    errorMessage: string | null;
    rolePriorityArr: Array<any>;
    errorMessagePriority: string | null;
    updatePrioritysLoading: boolean;
    changePrioritys: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchLoaded: false,
      branchId: this.props.match.params.branchId,
      roleId: this.props.match.params.roleId,
      roleName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
      roleArr: [],
      minBookingLength: 0,
      maxBookingLength: 0,
      mayBook: false,
      maxActiveBookings: 0,
      default: false,
      bookingTypeArr: [],
      errorMessageDelete: null,
      errorMessage: null,
      rolePriorityArr: [],
      errorMessagePriority: null,
      updatePrioritysLoading: false,
      changePrioritys: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.rolePriorityArr,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        rolePriorityArr: items,
      },
      this.checkRolePriorityChange
    );
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  componentDidUpdate = () => {
    const propsRoleId = this.props.match.params.roleId;
    if (this.state.roleId !== propsRoleId) {
      if (propsRoleId in this.state.branch.roles) {
        this.setState(
          { roleId: this.props.match.params.roleId },
          this.updateRoleData
        );
      } else {
        this.setState(
          { roleId: this.props.match.params.roleId },
          this.requestBranch
        );
      }
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.setState({ errorMessage: null });
      this.props.refreshNavbar();
      this.requestBranch();
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      const roleArr: any = objectToArray(branch.roles);
      roleArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      const rolePriorityArr: any = objectToArray(branch.roles);
      rolePriorityArr.sort((a: any, b: any) =>
        a.priority > b.priority ? -1 : 1
      );
      this.setState(
        {
          branch: branch,
          roleArr,
          rolePriorityArr: rolePriorityArr,
        },
        () => {
          this.updateRoleData();
          this.checkRolePriorityChange();
        }
      );
    }
  };

  updateRoleData = () => {
    if (this.state.roleId && this.state.roleId in this.state.branch.roles) {
      const role = this.state.branch.roles[this.state.roleId];
      let roleBookingTypes = role.bookingTypes;
      const bookingTypeArr = objectToArray(this.state.branch.bookingTypes).map(
        (branchBookingType: any) => {
          const bookingTypeId = branchBookingType.id;
          const roleBookingType = roleBookingTypes[bookingTypeId];
          return {
            ...branchBookingType,
            ...roleBookingType,
            ...{ selected: roleBookingType },
          };
        }
      );
      bookingTypeArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      this.setState(
        {
          roleName: role.name,
          default: role.default,
          minBookingLength: role.settings.minBookingLength,
          maxBookingLength: role.settings.maxBookingLength,
          mayBook: role.settings.mayBook,
          maxActiveBookings: role.settings.maxActiveBookings,
          bookingTypeArr: bookingTypeArr,
        },
        this.checkChange
      );
    }
  };

  updateRole = () => {
    this.setState({ updateLoading: true });
    const bookingTypes: any = {};
    this.state.bookingTypeArr
      .filter((item: any) => item.selected)
      .forEach((item: any) => {
        bookingTypes[item.id] = {
          pricing: {
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
          },
        };
      });

    fetchRetry(
      "updateRole",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        roleId: this.state.roleId,
        name: this.state.roleName,
        default: this.state.default,
        bookingTypes: bookingTypes,
        settings: {
          minBookingLength: this.state.minBookingLength,
          maxBookingLength: this.state.maxBookingLength,
          mayBook: this.state.mayBook,
          maxActiveBookings: this.state.maxActiveBookings,
        },
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  updateRolePrioritys = () => {
    this.setState({ updatePrioritysLoading: true });
    const roleArr: Array<any> = this.state.rolePriorityArr.map(
      (role: any) => role.id
    );

    fetchRetry(
      "updateRolePrioritys",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        roleArr: roleArr.reverse(),
      },
      1,
      5
    )
      .then(this.handleSuccessUpdatePrioritys)
      .catch(this.handleError);
  };

  handleSuccessUpdatePrioritys = ({ data }: any) => {
    this.setState({ updatePrioritysLoading: false });
    if (data.success) {
      this.setState({ errorMessagePriority: null });
      this.props.refreshNavbar();
      this.requestBranch();
    } else {
      this.setState({ errorMessagePriority: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleRoleNameChange = (val: any) => {
    this.setState({ roleName: val }, this.checkChange);
  };

  checkChange = () => {
    // check if the bookingTypes have changed
    let bookingTypeChange = false;
    const oldBookingTypeIds = Object.keys(
      this.state.branch.roles[this.state.roleId].bookingTypes
    );
    const newBookingTypeObj = this.state.bookingTypeArr.filter(
      (item: any) => item.selected
    );
    if (newBookingTypeObj.length !== oldBookingTypeIds.length) {
      bookingTypeChange = true;
    } else {
      newBookingTypeObj.forEach((item: any) => {
        if (!oldBookingTypeIds.includes(item.id)) {
          bookingTypeChange = true;
        }
      });
    }
    ///

    this.setState({
      change:
        this.state.roleName !==
          this.state.branch.roles[this.state.roleId].name ||
        this.state.maxBookingLength !==
          this.state.branch.roles[this.state.roleId].settings
            .maxBookingLength ||
        this.state.minBookingLength !==
          this.state.branch.roles[this.state.roleId].settings
            .minBookingLength ||
        this.state.mayBook !==
          this.state.branch.roles[this.state.roleId].settings.mayBook ||
        this.state.maxActiveBookings !==
          this.state.branch.roles[this.state.roleId].settings
            .maxActiveBookings ||
        this.state.default !==
          this.state.branch.roles[this.state.roleId].default ||
        bookingTypeChange,
    });
  };

  handleDeleteRole = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleRoleDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteRole",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        roleId: this.state.roleId,
      },
      1,
      5
    )
      .then(this.handleSuccessRoleDeleted)
      .catch(this.handleError);
  };

  handleSuccessRoleDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Rolle wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState(
        {
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/roles`,
          errorMessageDelete: null,
        },
        () => {
          this.updateRoleData();
          this.props.refreshNavbar();
        }
      );
    } else {
      this.setState({
        errorMessageDelete: data.errorMsgDe,
      });
    }
  };

  handleMinBookingLengthChange = (val: any) => {
    this.setState({ minBookingLength: val }, this.checkChange);
  };

  handleMaxBookingLengthChange = (val: any) => {
    this.setState({ maxBookingLength: val }, this.checkChange);
  };

  handleMaxActiveBookingsChange = (val: any) => {
    this.setState({ maxActiveBookings: val }, this.checkChange);
  };

  handleMayBookChange = () => {
    this.setState({ mayBook: !this.state.mayBook }, this.checkChange);
  };

  handleDefaultChange = () => {
    this.setState({ default: !this.state.default }, this.checkChange);
  };

  handleBookingTypeSelect = (bookingTypeId: string) => {
    const newBookingTypeArr: any = this.state.bookingTypeArr;
    const index = findWithAttr(newBookingTypeArr, "id", bookingTypeId);
    newBookingTypeArr[index].selected = true;
    this.setState({ bookingTypeArr: newBookingTypeArr }, this.checkChange);
  };

  handleBookingTypeUnselect = (bookingTypeId: string) => {
    const newBookingTypeArr: any = this.state.bookingTypeArr;
    const index = findWithAttr(newBookingTypeArr, "id", bookingTypeId);
    newBookingTypeArr[index].selected = false;
    this.setState({ bookingTypeArr: newBookingTypeArr }, this.checkChange);
  };

  checkRolePriorityChange = () => {
    let change = false;
    let lastPrio = -1;
    this.state.rolePriorityArr.forEach((role: any) => {
      if (role.priority > lastPrio && lastPrio !== -1) {
        change = true;
      }
      lastPrio = role.priority;
    });
    this.setState({ changePrioritys: change });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du die Rolle "${
            this.state.roleId &&
            this.state.branch?.roles &&
            this.state.branch?.roles[this.state.roleId]?.name
              ? this.state.branch.roles[this.state.roleId].name
              : "-"
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleRoleDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.roleId &&
              this.state.branch?.roles &&
              Object.keys(this.state.branch.roles).length > 0 && (
                <>
                  <div className="subscreen-box-container">
                    <Title title="Einstellungen" />
                    <SubTitle title="Name" />
                    <Input
                      name="role-name-input"
                      value={this.state.roleName}
                      placeholder="Rollen name"
                      onChange={this.handleRoleNameChange}
                    />
                    <ErrorMessage message={this.state.errorMessage} />
                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updateRole}
                      title="Speichern"
                    />
                    <SubTitle title="Priorität" />
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            className="roles-priority-container"
                            ref={provided.innerRef}
                          >
                            {this.state.rolePriorityArr.map(
                              (role: any, index: number) => (
                                <Draggable
                                  key={role.id}
                                  draggableId={role.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      draggable="true"
                                      className="role-priority-container"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {role.name}
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <ErrorMessage message={this.state.errorMessagePriority} />
                    <SecButton
                      change={this.state.changePrioritys}
                      color="green"
                      loading={this.state.updatePrioritysLoading}
                      onClick={this.updateRolePrioritys}
                      title="Speichern"
                    />
                    {this.state.branch.settings.useBooking && (
                      <>
                        <Title title="Buchungseinstellungen" />
                        <SubTitle title="Darf buchen" />
                        <Switch
                          value={this.state.mayBook}
                          onChange={this.handleMayBookChange}
                        />
                        <div className="branch-subtitle-container">
                          <h3>Standardrolle</h3>
                        </div>
                        <Switch
                          value={this.state.default}
                          onChange={this.handleDefaultChange}
                        />
                        <div className="branch-subtitle-container">
                          <h3>Minimale Buchungslänge in Minuten</h3>
                        </div>
                        <Input
                          name="min-booking-length"
                          type="number"
                          max={1440}
                          min={1}
                          value={this.state.minBookingLength}
                          onChange={this.handleMinBookingLengthChange}
                        />
                        <div className="branch-subtitle-container">
                          <h3>Maximale Buchungslänge in Minuten</h3>
                        </div>
                        <Input
                          name="max-booking-length"
                          type="number"
                          max={1440}
                          min={1}
                          value={this.state.maxBookingLength}
                          placeholder="Buchungsintervall"
                          onChange={this.handleMaxBookingLengthChange}
                        />
                        <div className="branch-subtitle-container">
                          <h3>Maximale Aktive Buchungen (-1 für ∞)</h3>
                        </div>
                        <Input
                          name="max-booking-length"
                          type="number"
                          min={-1}
                          value={this.state.maxActiveBookings}
                          placeholder="Buchungsintervall"
                          onChange={this.handleMaxActiveBookingsChange}
                        />
                        <div className="branch-subtitle-container">
                          <h3>Buchungsarten</h3>
                        </div>
                        <MultiSelector
                          searchTextPlaceholder="Buchungsart"
                          arr={this.state.bookingTypeArr}
                          onSelect={this.handleBookingTypeSelect}
                          onUnselect={this.handleBookingTypeUnselect}
                        />
                      </>
                    )}
                    <ErrorMessage message={this.state.errorMessageDelete} />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.deleteLoading}
                      onClick={this.handleDeleteRole}
                      title="Rolle löschen"
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
