import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./ConfirmationModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ConfirmationModal extends React.Component<
  {
    show: boolean;
    title: string;
    msg: string;
    handleClose: any;
    handleConfirm: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="confirm"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="confirm-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={["fas", "times"]} />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">{this.props.title}</span>
            </div>
            <span>{this.props.msg}</span>
            <div className="modal-btn-container">
              <button
                className={"modal-btn"}
                onClick={() => {
                  this.props.handleConfirm();
                }}
              >
                Ja
              </button>
              <button
                className={"modal-btn modal-btn-red"}
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                Nein
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
