import React from "react";
import "./VertNavbar.css";
import "./BranchSettingsNavbar.css";
import { Link } from "react-router-dom";

import BranchSettingsNavbarElement from "./elements/BranchSettingsNavbarElement";
import BranchSelectorNavbarElement from "./elements/BranchSelectorNavbarElement";
import LogoNavbarElement from "./elements/LogoNavbarElement";
import UserNavbarElement from "./elements/UserNavbarElement";

export default class BranchSettingsNavbar extends React.Component<
  {
    selectedScreen: string;
    clubId: string;
    branchId: string;
    objectArr: Array<any>;
    roleArr: Array<any>;
    locationArr: Array<any>;
    bookingTypeArr: Array<any>;
    pricingProfileArr: Array<any>;
    showMobile?: boolean;
    hideMobileNav: any;
    selectedObject: string;
    onCreateBranch: any;
    onCreateRole: any;
    onCreateBookingType: any;
    onCreatePricingProfile: any;
    useBooking: boolean;
    useCourse: boolean;
    useBilling: boolean;
    onCreateLocation: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`nav-background ${
            this.props.showMobile && " show-nav-background"
          }`}
          onClick={this.props.hideMobileNav}
        ></div>
        <nav
          className={`vert-navbar br-navbar ${
            this.props.showMobile && " vert-navbar-mobile"
          }`}
        >
          <LogoNavbarElement />
          <div className="vert-navbar-scrollable">
            <UserNavbarElement
              to={`/club/${this.props.clubId}/branch/${this.props.branchId}/profile`}
            />
            <BranchSettingsNavbarElement
              title="Benutzer"
              iconPrefix="fas"
              iconName="users"
              selected={this.props.selectedScreen === "users"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/users`}
            />
            <BranchSettingsNavbarElement
              title="Rollen"
              iconPrefix="fas"
              iconName="user-tag"
              selected={this.props.selectedScreen === "roles"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${
                this.props.branchId
              }/roles${
                this.props.roleArr.length > 0
                  ? `/${this.props.roleArr[0].id}`
                  : ""
              }`}
            />
            <div
              className={`navbar-objects ${
                this.props.selectedScreen === "roles" &&
                "navbar-objects-selected"
              }`}
            >
              {this.props.roleArr.map((role: any) => (
                <BranchSelectorNavbarElement
                  title={role.name}
                  selected={this.props.selectedObject === role.id}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/roles/${role.id}`}
                  key={role.id}
                />
              ))}
              <div
                className={"navbar-element navbar-create-element"}
                onClick={this.props.onCreateRole}
              >
                <span>+</span>
              </div>
            </div>
            {this.props.useCourse && (
              <>
                <BranchSettingsNavbarElement
                  title="Kurs"
                  iconPrefix="fas"
                  iconName="calendar-plus"
                  selected={this.props.selectedScreen === "course"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/course`}
                />
                <BranchSettingsNavbarElement
                  title="Örtlichkeiten"
                  iconPrefix="fas"
                  iconName="warehouse"
                  selected={this.props.selectedScreen === "locations"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/locations${
                    this.props.locationArr.length > 0
                      ? `/${this.props.locationArr[0].id}`
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "locations" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.locationArr.map((location: any) => (
                    <BranchSelectorNavbarElement
                      title={location.name}
                      selected={this.props.selectedObject === location.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/locations/${location.id}`}
                      key={location.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateLocation}
                  >
                    <span>+</span>
                  </div>
                </div>
              </>
            )}

            {this.props.useBooking && (
              <>
                <BranchSettingsNavbarElement
                  title="Buchungsarten"
                  iconPrefix="fas"
                  iconName="list-ul"
                  selected={this.props.selectedScreen === "booking-type"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/booking-type${
                    this.props.bookingTypeArr.length > 0
                      ? `/${this.props.bookingTypeArr[0].id}`
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "booking-type" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.bookingTypeArr.map((role: any) => (
                    <BranchSelectorNavbarElement
                      title={role.name}
                      selected={this.props.selectedObject === role.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/booking-type/${role.id}`}
                      key={role.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateBookingType}
                  >
                    <span>+</span>
                  </div>
                </div>
                <BranchSettingsNavbarElement
                  title="Buchungen"
                  iconPrefix="fas"
                  iconName="calendar-alt"
                  selected={this.props.selectedScreen === "booking"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/booking`}
                />
                <BranchSettingsNavbarElement
                  title="Objekte"
                  iconPrefix="fas"
                  iconName="cube"
                  selected={this.props.selectedScreen === "objects"}
                  onClick={() => {}}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/objects/${
                    this.props.objectArr[0]?.id
                      ? this.props.objectArr[0]?.id
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "objects" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.objectArr.map((object: any) => (
                    <BranchSelectorNavbarElement
                      title={object.name}
                      selected={this.props.selectedObject === object.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/objects/${object.id}`}
                      key={object.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateBranch}
                  >
                    <span>+</span>
                  </div>
                </div>
                <BranchSettingsNavbarElement
                  title="Preisprofile"
                  iconPrefix="fas"
                  iconName="euro-sign"
                  selected={this.props.selectedScreen === "pricing-profiles"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/pricing-profiles`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "pricing-profiles" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.pricingProfileArr.map((object: any) => (
                    <BranchSelectorNavbarElement
                      title={object.name}
                      selected={this.props.selectedObject === object.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/pricing-profiles/${object.id}`}
                      key={object.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreatePricingProfile}
                  >
                    <span>+</span>
                  </div>
                </div>
              </>
            )}
            {this.props.useBilling && (
              <BranchSettingsNavbarElement
                title="Abrechnungen"
                iconPrefix="fas"
                iconName="credit-card"
                selected={this.props.selectedScreen === "billing"}
                onClick={this.props.hideMobileNav}
                linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/billing`}
              />
            )}
            {/* <BranchSettingsNavbarElement
              title="Module"
              iconPrefix="fas"
              iconName="box-open"
              selected={this.props.selectedScreen === "modules"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/modules`}
            /> */}
            <BranchSettingsNavbarElement
              title="Einstellungen"
              iconPrefix="fas"
              iconName="cog"
              selected={this.props.selectedScreen === "settings"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/settings`}
            />
          </div>
          <Link
            to={`/club/${this.props.clubId}/branch/${this.props.branchId}`}
            className={"navbar-element navbar-back-element"}
            onClick={this.props.hideMobileNav}
          >
            zum System
          </Link>
        </nav>
      </>
    );
  }
}
