import React from "react";
import { RouteComponentProps, Redirect, Link } from "react-router-dom";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import ErrorMessage from "../components/ErrorMessage";
import { fetchRetry } from "../functions/request";

type Props = {};
type ComposedProps = Props & RouteComponentProps<{}>;

export default class MollieEndpoint extends React.Component<
  ComposedProps,
  {
    authCode: string | null;
    state: string | null;
    error: string | null;
    error_description: string | null;
    redirect: string | null;
    loading: boolean;
    errorMessage: string | null;
    clubId: string | null;
    branchId: string | null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      authCode: "",
      state: "",
      error: "",
      error_description: "",
      redirect: null,
      loading: false,
      errorMessage: null,
      clubId: null,
      branchId: null,
    };
  }

  componentDidMount = () => {
    const params = this.props.location.search;
    const authCode = new URLSearchParams(params).get("code");
    const state = new URLSearchParams(params).get("state");
    const error = new URLSearchParams(params).get("error");
    const error_description = new URLSearchParams(params).get(
      "error_description"
    );

    this.setState({
      authCode,
      state,
      error,
      error_description,
    });

    if (!error) {
      this.setState({ loading: true });
      fetchRetry(
        "handleNewAuthCode",
        {
          identifier: state,
          authCode,
        },
        1,
        5
      )
        .then(this.handleSuccess)
        .catch(this.handleError);
    } else {
      this.setState({
        errorMessage: error,
      });
    }
  };

  handleSuccess = ({ data }: any) => {
    this.setState({ loading: false });
    if (data.success) {
      this.setState({
        redirect: `/club/${data.clubId}/branch-settings/${data.branchId}/billing`,
        branchId: data.branchId,
        clubId: data.clubId,
        errorMessage: null,
      });
    } else {
      this.setState({
        errorMessage: data.errorMsgDe,
        branchId: data.branchId,
        clubId: data.clubId,
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <div className="center-container">
          <ClimbingBoxLoader
            color={"#c31924"}
            size={20}
            loading={this.state.loading}
          />
          <ErrorMessage message={this.state.errorMessage} />
          {this.state.errorMessage && (
            <Link
              to={
                this.state.clubId && this.state.branchId
                  ? `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/billing`
                  : "/"
              }
              className="black"
            >
              zurück zum System
            </Link>
          )}
        </div>
      </>
    );
  }
}
