import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./SearchInput.css";

export default class SearchInput extends React.Component<
  {
    value: string | number;
    onChange: any;
    name?: string;
    placeholder?: string;
    type?: "text" | "number";
    min?: number;
    max?: number;
    className?: string;
    step?: number;
    disabled?: boolean;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  handleChange = (val: any) => {
    if (this.props.type === "number") {
      this.props.onChange(parseFloat(val.target.value));
    } else {
      this.props.onChange(val.target.value);
    }
  };

  render() {
    return (
      <>
        <div className={`input-container-search ${this.props.className}`}>
          <input
            name={this.props.name ? this.props.name : "input"}
            type={this.props.type ? this.props.type : "text"}
            value={this.props.value}
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            onChange={this.handleChange}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            disabled={this.props.disabled}
          />
          <div className="icon-container">
            <FontAwesomeIcon
              icon={["fas", "search"]}
              size="lg"
              className="course-settings-icon"
            />
          </div>
        </div>
      </>
    );
  }
}
