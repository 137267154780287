import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./DateComponent.css";
import DropDown from "./DropDown";
import SubTitle from "./SubTitle";

export default class DateComponent extends React.Component<
  {
    date: string;
    endDate: string;
    courseTime: string;
    endTime: string;
    handleTimeChange: any;
    handleEndTimeChange: any;
    handleLocationChange: any;
    places: any;
    selectedLocation: string;
    handleEndDateChange: any;
  },
  {
    showCustomSettings: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showCustomSettings: false,
    };
  }

  toggleMoreInfo = () => {
    this.setState({ showCustomSettings: !this.state.showCustomSettings });
  };

  render() {
    return (
      <div className="course-date-container">
        <SubTitle
          title="Start Datum/Uhrzeit"
          helpText="Startzeitpunkt des Kurses."
        />
        <div className="course-time-input-container">
          <input
            type="date"
            className="course-input"
            value={this.props.date}
            disabled
          />
          <input
            type="time"
            className="course-input"
            value={this.props.courseTime}
            onChange={(val: any) => {
              this.props.handleTimeChange(val.target.value);
            }}
          />
        </div>
        <SubTitle
          title="End Datum/Uhrzeit"
          helpText="Endzeitpunkt des Kurses."
        />
        <div className="course-time-input-container">
          <input
            type="date"
            className="course-input"
            value={this.props.endDate}
            onChange={(val: any) => {
              this.props.handleEndDateChange(val.target.value);
            }}
          />
          <input
            type="time"
            className="course-input"
            value={this.props.endTime}
            onChange={(val: any) => {
              this.props.handleEndTimeChange(val.target.value);
            }}
          />
        </div>
        <div className="more-info-container" onClick={this.toggleMoreInfo}>
          <div className="more-info-inner-container">
            {this.state.showCustomSettings ? (
              <FontAwesomeIcon icon={["fas", "chevron-down"]} size="sm" />
            ) : (
              <FontAwesomeIcon icon={["fas", "chevron-up"]} size="sm" />
            )}
          </div>
          erweiterte Einstellungen
        </div>
        {this.state.showCustomSettings && (
          <div className="show-more-info-container">
            <SubTitle
              title="Örtlichkeit"
              helpText="Örtlichkeiten können in den Bereich Einstellungen verwaltet werden."
            />
            <DropDown
              options={this.props.places}
              onChange={this.props.handleLocationChange}
              value={this.props.selectedLocation}
              name="locations-dropdown"
            />
          </div>
        )}
      </div>
    );
  }
}
