import React from "react";
import "./Impressum.css";

export default class Impressum extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="main-info-screen">
          <h1>Impressum Plattformbetreiber</h1>
          <h2>Angaben gemäß § 5 TMG</h2>
          Weimer und Teller – Web Applikationen GbR
          <br />
          Lenzhalde 1<br />
          71332 Waiblingen
          <br />
          Vertreten durch die Gesellschafter:
          <br />
          Moritz Weimer
          <br />
          Tim Teller
          <br />
          <h2>Kontakt</h2>
          Telefon: 0178 5302489 E-Mail: contact@network-sports.de
          <br />
          <br />
          <br />
          <h1>Impressum Verein</h1>
          <h2>nach § 6 TDG oder § 10 Abs. 2 und 3 MDStV1</h2>
          <b>Sportverein Fellbach</b>
          <br />
          <b> SVF Geschäftsstelle</b>
          <br />
          <br />
          Schillerstraße 8 (Wichernschule)
          <br />
          Eingang Kirchhofstraße 70734 Fellbach
          <br />
          Telefon : 0711 / 58 69 05
          <br />
          Telefax : 0711 / 58 90 57
          <br />
          E-Mail : info@svfellbach.de
          <br />
          <br />
          Geschäftsführer: Udo Wente
          <br />
          Sitz der Gesellschaft: 70734 Fellbach
          <br />
          <br />
          Vereinsregisternummer: Amtsgericht Waiblingen 200
          <br />
        </div>
      </>
    );
  }
}
