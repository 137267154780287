import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./PricingProfiles.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class PricingProfiles extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branch: any;
    branchLoaded: boolean;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    roleDependent: boolean;
    weekDayDependent: boolean;
  }
> {
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchLoaded: false,
      branchId: this.props.match.params.branchId,
      change: false,
      updateLoading: false,
      redirect: null,
      roleDependent: false,
      weekDayDependent: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  componentDidUpdate = () => {};

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdate = () => {
    this.setState({ updateLoading: false });
    this.props.refreshNavbar();
    this.requestBranch();
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  roleDependentChange = () => {
    this.setState({ roleDependent: !this.state.roleDependent });
  };

  weekDayDependentChange = () => {
    this.setState({ weekDayDependent: !this.state.weekDayDependent });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              Rollen abhängig
              <input
                type="checkbox"
                checked={this.state.roleDependent}
                onChange={this.roleDependentChange}
              />
              Tages abhängig
              <input
                type="checkbox"
                checked={this.state.weekDayDependent}
                onChange={this.weekDayDependentChange}
              />
              <table id="price-table">
                <thead>
                  {this.state.roleDependent && <th>Rolle</th>}
                  <th>Buchungsart</th>
                  {this.state.weekDayDependent ? (
                    <>
                      <th>Montag</th>
                      <th>Dienstag</th>
                      <th>Mittwoch</th>
                      <th>Donnerstag</th>
                      <th>Freitag</th>
                      <th>Samstag</th>
                      <th>Sonntag</th>
                    </>
                  ) : (
                    <th>Preisprofil</th>
                  )}
                </thead>
                <tbody>
                  {this.state.roleDependent && <td></td>}
                  <td></td>
                  {this.state.weekDayDependent ? (
                    <>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </>
                  ) : (
                    <td></td>
                  )}
                </tbody>
              </table>
              <SecButton
                change={this.state.change}
                color="green"
                loading={this.state.updateLoading}
                onClick={() => {}}
                title="Speichern"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
