import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../contexts/MainContext";
import { weekDays } from "../services/constants";
import { Link } from "react-router-dom";

export default class InfinityCourseElementOwn extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBooking: any;
    title: string;
    description: string;
    when: string;
    bookingCreated: string;
    where: string;
    freePlaces: number;
    price: string;
    imgUrl: string;
    weekDay: number;
    notice: string;
    status: string;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    const courseBookingId = this.props.courseBooking.courseBookingId;

    return (
      <>
        <div className="course-container">
          <div>
            {!this.props.imgUrl ? (
              <div className="course-img">
                <FontAwesomeIcon
                  icon={["fas", "image"]}
                  size="6x"
                  className="image-icon"
                />
              </div>
            ) : (
              <img
                className="course-img"
                src={this.props.imgUrl}
                alt="Kursbild"
              />
            )}
          </div>
          <div className="course-info-container">
            <div className="course-info-inner-container">
              <div className="course-title-container">{this.props.title}</div>
              <div>{this.props.description}</div>
              <div className="course-text-container">
                Status:{" "}
                <span className="normal-weight">{this.props.status}</span>
              </div>
              <div className="course-text-container">
                Wann:{" "}
                <span className="normal-weight">
                  jeden {weekDays[this.props.weekDay].name}
                </span>
              </div>
              {this.props.where && (
                <div className="course-text-container">
                  Wo: <span className="normal-weight">{this.props.where}</span>
                </div>
              )}
              <div className="course-text-container">
                Gebucht:{" "}
                <span className="normal-weight">
                  {this.props.bookingCreated}
                </span>
              </div>
              {/* <div className="course-text-container">
                Preis:{" "}
                <span className="normal-weight">{this.props.price}€</span>
              </div> */}
              {this.props.notice && (
                <div className="course-text-container">
                  Notiz:{" "}
                  <span className="normal-weight">{this.props.notice}</span>
                </div>
              )}
            </div>
            <div>
              <Link
                to={`/club/${this.props.clubId}/branch/${this.props.branchId}/mycourses/${this.props.courseId}/courseBooking/${courseBookingId}`}
                className="course-booking-detail-btn no-underline"
              >
                Details anzeigen
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
