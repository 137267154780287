import React from "react";
import "./MessageContainer.css";

export default class MessageContainer extends React.Component<
  { msg: null | string; type: "error" | "success" },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.msg) {
      return (
        <div className={`message-container message-${this.props.type}`}>
          <span>{this.props.msg}</span>
        </div>
      );
    } else {
      return null;
    }
  }
}
