import React from "react";
import { Redirect } from "react-router-dom";
import {
  addressToStr,
  dateTosimpleDateStr,
  firebaseDateToDateObj,
} from "../functions/utils";
import { molliePaymentStatus } from "../services/constants";
import "./CourseParticipantsTable.css";
import PaymentStatus from "./PaymentStatus";

export default class CourseParticipantsTable extends React.Component<
  {
    participants: Array<any>;
    moreInfo: boolean;
    clubId: string;
    courseId: string;
    branchId: string;
  },
  {
    redirect: string | null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  handleRedirect = (redirect: string) => {
    this.setState({ redirect: redirect });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <table className="table-course-users">
        <thead>
          <tr>
            <th>#</th>
            <th>Konto</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th className="table-course-users-email">E-Mail</th>
            <th>Methode</th>
            <th>Status</th>
            <th className="table-course-users-date">Datum</th>
          </tr>
        </thead>
        <tbody>
          {this.props.participants.map((user: any, index: number) => (
            <>
              <tr
                key={index}
                style={{
                  opacity:
                    molliePaymentStatus[user.paymentStatus]?.active &&
                    !user.refunded
                      ? 1
                      : 0.5,
                }}
                onClick={() => {
                  this.handleRedirect(
                    `/club/${this.props.clubId}/branch/${this.props.branchId}/participants/${this.props.courseId}/participant/${user.courseBookingId}`
                  );
                }}
              >
                <td>{index + 1}</td>
                <td>{user.userId ? "Konto" : "Gast"}</td>
                <td>{user.user.fName}</td>
                <td>{user.user.lName}</td>
                <td className="table-course-users-email">{user.user.email}</td>
                <td>{user.method}</td>
                <td>
                  <PaymentStatus type={user.paymentStatus} paid={user.paid} />
                  {user.refunded && (
                    <div className="margin-left">
                      <PaymentStatus type={user.refundStatus} />
                    </div>
                  )}
                </td>
                <td className="table-course-users-date">
                  {dateTosimpleDateStr(firebaseDateToDateObj(user.created_at))}
                </td>
              </tr>
              {this.props.moreInfo && (
                <tr
                  style={{
                    opacity: molliePaymentStatus[user.paymentStatus]?.active
                      ? 1
                      : 0.4,
                  }}
                  onClick={() => {
                    this.handleRedirect(
                      `/club/${this.props.clubId}/branch/${this.props.branchId}/participants/${this.props.courseId}/participant/${user.courseBookingId}`
                    );
                  }}
                >
                  <td></td>
                  <td colSpan={5}>{addressToStr(user.address)}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.birthDate}</td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    );
  }
}
