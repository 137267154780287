import { month } from "../services/constants";

export const strTimeToMin = (str: string) => {
  const [hours, min] = str.split(":");
  return parseInt(hours) * 60 + parseInt(min);
};

const addLeadingZero = (str: string) => {
  return ("0" + str).slice(-2);
};

export const minTimeToStr = (min: number) => {
  return `${Math.floor(min / 60)}:${addLeadingZero((min % 60) + "")}`;
};
export const minTimeToStrLeadingZero = (min: number) => {
  return `${addLeadingZero(Math.floor(min / 60) + "")}:${addLeadingZero(
    (min % 60) + ""
  )}`;
};

export const dateToTimeStr = (date: Date | undefined) => {
  if (date === undefined) {
    return "00:00";
  }
  return `${addLeadingZero(date.getHours() + "")}:${addLeadingZero(
    date.getMinutes() + ""
  )}`;
};

export const dateToStr = (date: Date | undefined) => {
  if (date === undefined) {
    return "";
  }
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const dateToStrInput = (date: Date | undefined) => {
  if (date === undefined) {
    return "2000-01-01";
  }
  return `${date.getFullYear()}-${addLeadingZero(
    date.getMonth() + 1 + ""
  )}-${addLeadingZero(date.getDate() + "")}`;
};

export const strToDate = (str: string) => {
  const strArr = str.split(".");
  return new Date(
    parseInt(strArr[2]),
    parseInt(strArr[1]) - 1,
    parseInt(strArr[0])
  );
};

export const strToDateWithMinutes = (str: string, minutes: number) => {
  const strArr = str.split(".");
  return new Date(
    parseInt(strArr[2]),
    parseInt(strArr[1]) - 1,
    parseInt(strArr[0]),
    0,
    minutes
  );
};

export const inputStrToDate = (str: string) => {
  const strArr = str.split("-");
  return new Date(
    parseInt(strArr[0]),
    parseInt(strArr[1]) - 1,
    parseInt(strArr[2])
  );
};

export const addDayToDate = (date: Date | undefined, num: number) => {
  if (!date) {
    return null;
  }
  date.setDate(date.getDate() + num);
  return date;
};

const weekDayNames = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

export const getDayOfWeek = (date: Date) => {
  return weekDayNames[date.getDay()];
};

export const objectToArray = (obj: any) => {
  const arr: Array<any> = [];
  for (const objId in obj) {
    const currObj = obj[objId];
    currObj.id = objId;
    arr.push(currObj);
  }
  return arr;
};

export const valToArray = (obj: any) => {
  const arr: Array<any> = [];
  for (const objId in obj) {
    const currObj: any = { val: obj[objId] };
    currObj.id = objId;
    arr.push(currObj);
  }
  return arr;
};

export const addIdToObject = (obj: any) => {
  for (const id in obj) {
    obj[id].id = id;
  }
  return obj;
};

export const startOfDay = (date: Date) => {
  const currDate = new Date(date.getTime());
  currDate.setHours(0, 0, 0, 0);
  return currDate;
};

export const addADay = (date: Date, days: number) => {
  const currDate = new Date(date.getTime());
  currDate.setDate(currDate.getDate() + days);
  return currDate;
};

export const findWithAttr = (array: any, attr: any, value: any) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};

export const getCurrentDayStr = () => {
  const curr = new Date();
  return `${curr.getFullYear()}-${addLeadingZero(
    "" + (curr.getMonth() + 1)
  )}-${addLeadingZero("" + curr.getDate())}`;
};

export const reformatDate = (date: string): any => {
  if (!date) {
    return null;
  }
  const dateArr = date.split("-");
  return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
};

export const reformatDateReverse = (date: string | undefined) => {
  if (!date) {
    return "";
  }
  const dateArr = date.split(".");
  return `${dateArr[2]}-${addLeadingZero(dateArr[1])}-${addLeadingZero(
    dateArr[0]
  )}`;
};

export const firebaseDateToDateObj = (fbDate: any): any => {
  if (!fbDate) {
    return undefined;
  }
  return new Date(fbDate._seconds * 1000);
};

export const dateToFullStr = (date: Date | undefined | null) => {
  if (!date) {
    return "-";
  }
  return `${getDayOfWeek(date)}, ${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()} ${date.getHours()}:${addLeadingZero(
    "" + date.getMinutes()
  )} Uhr`;
};

const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const dateDurationToFullStr = (
  date: Date | undefined | null,
  endDate: Date | undefined | null
) => {
  if (!date || !endDate) {
    return "-";
  }

  if (datesAreOnSameDay(date, endDate)) {
    // check if on same day
    if (date)
      return `${getDayOfWeek(date)}, ${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()} ${date.getHours()}:${addLeadingZero(
        "" + date.getMinutes()
      )} Uhr -  ${endDate.getHours()}:${addLeadingZero(
        "" + endDate.getMinutes()
      )} Uhr`;
  } else {
    return `${getDayOfWeek(date)}, ${date.getDate()}.${
      date.getMonth() + 1
    }.${date.getFullYear()} ${date.getHours()}:${addLeadingZero(
      "" + date.getMinutes()
    )} Uhr - ${getDayOfWeek(endDate)}, ${endDate.getDate()}.${
      endDate.getMonth() + 1
    }.${endDate.getFullYear()} ${endDate.getHours()}:${addLeadingZero(
      "" + endDate.getMinutes()
    )} Uhr`;
  }
};

export const dateToSmallStr = (date: Date | undefined) => {
  if (!date) {
    return "-";
  }
  return `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()} ${date.getHours()}:${addLeadingZero(
    "" + date.getMinutes()
  )} Uhr`;
};

export const priceToFullStr = (price: number) => {
  return `${Math.floor(price / 100)}.${addLeadingZero("" + (price % 100))}`;
};

export const priceToStr = (price: number) => {
  if (price % 100 === 0) {
    return `${Math.floor(price / 100)}`;
  } else {
    return `${Math.floor(price / 100)}.${addLeadingZero("" + (price % 100))}`;
  }
};

export function dateTosimpleDateStr(date: Date | undefined): string {
  if (date === undefined) {
    return "-";
  }
  const now = new Date();
  if (
    now.getDate() === date.getDate() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  ) {
    return `Heute um ${date.getHours()}:${addLeadingZero(
      "" + date.getMinutes()
    )} Uhr`;
  }
  if (
    now.getDate() - 1 === date.getDate() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  ) {
    return `Gestern um ${date.getHours()}:${addLeadingZero(
      "" + date.getMinutes()
    )} Uhr`;
  }
  return `${date.getDate()}. ${month[date.getMonth()].shortName} ${(
    date.getFullYear() + ""
  ).substring(2)}, ${date.getHours()}:${addLeadingZero(
    "" + date.getMinutes()
  )} Uhr`;
}

export function inPast(date: Date | undefined) {
  if (!date) {
    return false;
  }
  return new Date().getTime() > date.getTime();
}

export function addMinutesToDate(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}

export function addDaysToDate(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  return date;
}

export function copyDate(date: Date) {
  const newDate = new Date(date.getTime());
  return newDate;
}

export function addressToStr(address: any) {
  return `${address?.zipcode ? address?.zipcode : ""} ${
    address?.city ? address?.city : ""
  }${
    (address?.zipcode || address?.city) &&
    (address?.street || address?.houseNumber)
      ? ","
      : ""
  } ${address?.street ? address?.street : ""} ${
    address?.houseNumber ? address?.houseNumber : ""
  }`;
}

export const formatCode = (str: string | undefined) => {
  if (!str) {
    return "-";
  }
  return `${str.slice(0, 4)}-${str.slice(4, 8)}-${str.slice(8, 12)}`;
};

export const formatDateUnit = (str: string, period: number) => {
  if (str === "day") {
    return period === 1 ? "Tag" : "Tage";
  } else if (str === "month") {
    return period === 1 ? "Monat" : "Monate";
  } else if (str === "year") {
    return period === 1 ? "Jahr" : "Jahre";
  }
};

export const addMonthToDate = (date: Date) => {
  date.setMonth(date.getMonth() + 1);
  return date;
};

export const setToMonthBegin = (date: Date) => {
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};

export function calcSubscription(
  price: number,
  startTime: Date | undefined
): Array<any> {
  if (!startTime) {
    return [null, null];
  }
  const now = new Date();

  const dayOfMonth = now.getDate();
  const startTimeBegin = setToMonthBegin(startTime);
  if (startTimeBegin < now) {
    // start subscription now
    if (dayOfMonth < 15) {
      // pay full for the remaining month
      const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      return [startDate, price];
    } else {
      // pay half for the remaining month
      const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      return [startDate, Math.ceil(price / 2)];
    }
  } else {
    // start subscription for the startTime
    // pay for the starting Month
    const startDate = new Date(
      startTime.getFullYear(),
      startTime.getMonth(),
      1
    );
    return [startDate, price];
  }
}

export function getMonthNameFromDate(date: Date) {
  return month[date.getMonth() - 1].name;
}

export function isToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function stringDateAndTimeToDate(date: string, time: string) {
  const dateArr = date.split(".");
  const day = parseInt(dateArr[0]);
  const month = parseInt(dateArr[1]);
  const year = parseInt(dateArr[2]);
  const timeArr = time.split(":");
  const hours = parseInt(timeArr[0]);
  const minutes = parseInt(timeArr[1]);
  return new Date(year, month - 1, day, hours, minutes);
}

export function removeTimeZoneOffset(date: Date | undefined): any {
  if (!date) {
    return undefined;
  }
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}
