import React from "react";
import { Link } from "react-router-dom";
import "./NavbarElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class BranchNavbarElement extends React.Component<
  {
    title: string;
    iconPrefix: any;
    iconName: any;
    selected: boolean;
    onClick: any;
    linkTo: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Link
        to={this.props.linkTo}
        className={
          this.props.selected
            ? "navbar-element navbar-element-selected"
            : "navbar-element"
        }
        onClick={this.props.onClick}
      >
        <div className="navbar-icon-container">
          <FontAwesomeIcon
            icon={[this.props.iconPrefix, this.props.iconName]}
            size="lg"
          />
        </div>
        <div>
          <span>{this.props.title}</span>
        </div>
      </Link>
    );
  }
}
