import React from "react";
import { Link } from "react-router-dom";
import "./NavbarElement.css";

export default class BranchSelectorNavbarElement extends React.Component<
  {
    title: string;
    selected: boolean;
    onClick: any;
    linkTo: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Link
        to={this.props.linkTo}
        className={
          this.props.selected
            ? "navbar-element navbar-element-indented navbar-element-selected"
            : "navbar-element navbar-element-indented"
        }
        onClick={this.props.onClick}
      >
        <span>{this.props.title}</span>
      </Link>
    );
  }
}
