import React from "react";
import "./TopMenuBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader";
import { MainContext } from "../contexts/MainContext";
import { fetchRetry } from "../functions/request";

export default class TopMenuBar extends React.Component<
  { handleShowMobileNav: any },
  {
    user: any;
    showNotifications: boolean;
    notificationArr: any;
    notificationsLoaded: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      showNotifications: false,
      user: {},
      notificationArr: [],
      notificationsLoaded: true,
    };
  }

  componentDidMount = () => {};

  handleShowNotifications = () => {
    this.setState({ showNotifications: true });
  };

  handleHideNotifications = () => {
    this.setState({ showNotifications: false });
  };

  handleToggleNotifications = () => {
    this.setState({ showNotifications: !this.state.showNotifications });
  };

  handleMarkNotificationsAsSeen = () => {
    this.setState({ notificationArr: [] });
    fetchRetry("markAllNotificationsAsRead", {}, 1, 5)
      .then(this.handleMarkNotificationsAsReadSuccess)
      .catch(this.handleError);
  };

  handleMarkNotificationsAsReadSuccess = () => {
    this.context.requestUnreadNotifications();
  };

  handleError = ({ data }: any) => {
    console.error(data);
  };

  render() {
    return (
      <div className="menubar-container">
        <div className="menubar-path-main-container">
          <div className="menubar-container-icon">
            <FontAwesomeIcon
              icon={["fas", "bars"]}
              size="lg"
              onClick={this.props.handleShowMobileNav}
              className="menu-icon"
            />
          </div>
          {this.context.path && (
            <div className="menubar-path-container">
              {this.context.path.map((path: any, index: number) => (
                <>
                  <a href={path.link} className="menubar-path-text">
                    {path.name}
                  </a>
                  {this.context.path &&
                    this.context.path.length - 1 > index &&
                    " ▸ "}
                </>
              ))}
            </div>
          )}
        </div>
        <div>
          <div
            className="menubar-bell-icon"
            onClick={this.handleToggleNotifications}
          >
            {this.context.notificationArr.length > 0 && (
              <div className="menubar-bell-new-notification"></div>
            )}
            <FontAwesomeIcon
              icon={["fas", "bell"]}
              size="lg"
              className="menu-bell-icon"
            />
          </div>
          <div
            className={`notifications-container ${
              this.state.showNotifications ? " show-notification" : ""
            }`}
          >
            <div className={"notifications-inner-container"}>
              <div
                className="notification-markallasread"
                onClick={this.handleMarkNotificationsAsSeen}
              >
                Alle als gelesen makieren
              </div>
              {!this.state.notificationsLoaded && (
                <div className="notification-loading-container">
                  <ClipLoader />
                </div>
              )}
              {this.context.notificationArr.map(
                (notification: any, index: number) => (
                  <div className="notification-inner-container" key={index}>
                    <div className="notification-title-container">
                      {notification.subject}
                    </div>
                    {notification.message}
                  </div>
                )
              )}
              {this.context.notificationArr.length === 0 && (
                <div className="notification-uptodate">
                  Du bist auf dem aktuellen Stand.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
