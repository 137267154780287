import React from "react";
import { Redirect } from "react-router-dom";
import { addressToStr } from "../functions/utils";
import "./CourseParticipantsTablePrint.css";

export default class CourseParticipantsTablePrint extends React.Component<
  {
    participants: Array<any>;
    moreInfo: boolean;
    printMode?: boolean;
    clubId: string;
    branchId: string;
    courseId: string;
  },
  {
    redirect: string | null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  handleRedirect = (redirect: string) => {
    this.setState({ redirect: redirect });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <table
        className={`table-course-users ${
          this.props.printMode ? "table-course-user-printmode" : ""
        }`}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th className="table-course-users-email-print">E-Mail</th>
            <th className="table-course-users-address">Addresse</th>
            <th className="table-course-users-phonenumber">Telefonnummer</th>
            <th className="table-course-users-birthdate">Geburtsdatum</th>
          </tr>
        </thead>
        <tbody>
          {this.props.participants
            .filter((currUser: any) => currUser.active)
            .map((user: any, index: number) => (
              <>
                <tr
                  key={index}
                  onClick={() => {
                    this.handleRedirect(
                      `/club/${this.props.clubId}/branch/${this.props.branchId}/participants/${this.props.courseId}/participant/${user.courseBookingId}`
                    );
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{user.user.fName}</td>
                  <td>{user.user.lName}</td>
                  <td className="table-course-users-email-print">
                    {user.user.email}
                  </td>
                  <td className="table-course-users-address">
                    {addressToStr(user.user.address)}
                  </td>
                  <td className="table-course-users-phonenumber">
                    {user.user.phoneNumber}
                  </td>
                  <td className="table-course-users-birthdate">
                    {user.user.birthDate}
                  </td>
                </tr>
              </>
            ))}
        </tbody>
      </table>
    );
  }
}
