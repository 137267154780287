import React from "react";
import "./Input.css";

export default class DropDown extends React.Component<
  {
    options: Array<any>;
    value: string;
    onChange: any;
    name: string;
    className?: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  onChange = (event: any) => {
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <select
        name={this.props.name}
        id="dropdown"
        onChange={this.onChange}
        value={this.props.value}
        className={`input-container ${this.props.className}`}
      >
        {this.props.options.map((val) => (
          <option
            value={val.id}
            key={val.id}
            selected={this.props.value === val.id}
          >
            {val.name}
          </option>
        ))}
      </select>
    );
  }
}
