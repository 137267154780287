import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./MultiSelector.css";

export default class MultiSelector extends React.Component<
  {
    arr: Array<any>;
    onSelect: any;
    onUnselect: any;
    viewOnly?: boolean;
    searchTextPlaceholder?: string;
  },
  { showAddUserWindow: boolean; searchText: string }
> {
  private wrapperRef: any;
  constructor(props: any) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      showAddUserWindow: false,
      searchText: "",
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.state.showAddUserWindow) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.hideAddUserWindow();
      }
    }
  }

  showAddUserWindow = () => {
    this.setState({ showAddUserWindow: true });
  };

  hideAddUserWindow = () => {
    this.setState({ showAddUserWindow: false });
  };

  toggleAddUserWindow = () => {
    this.setState({ showAddUserWindow: !this.state.showAddUserWindow });
  };

  handleChangeSearchText = (val: any) => {
    this.setState({ searchText: val.target.value });
  };

  render() {
    return (
      <>
        <div className="multiselector-container">
          {this.props.arr
            .filter((item: any) => item.selected)
            .map((item: any) => (
              <div
                className="multiselector-element-container"
                style={{ borderColor: item.color }}
                key={item.id}
              >
                <div
                  className="point"
                  style={{ backgroundColor: item.color }}
                  onClick={() => {
                    this.props.onUnselect(item.id);
                  }}
                >
                  {!this.props.viewOnly && (
                    <div className="cross-container">
                      <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                    </div>
                  )}
                </div>
                {item.name}
              </div>
            ))}
          {!this.props.viewOnly && (
            <div
              className="multiselector-element-container multiselector-add-element-container"
              onClick={this.showAddUserWindow}
              ref={this.wrapperRef}
            >
              <div
                className={`multiselector-add-container${
                  this.state.showAddUserWindow
                    ? " multiselector-show-add-container"
                    : ""
                }`}
              >
                <div className="multiselector-add-text-container">
                  <div className="text-input-container">
                    <input
                      placeholder={this.props.searchTextPlaceholder}
                      value={this.state.searchText}
                      onChange={this.handleChangeSearchText}
                    />
                  </div>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={["fas", "search"]} size="1x" />
                  </div>
                </div>
                <div className="multiselector-scroll-container">
                  {this.props.arr
                    .filter(
                      (item: any) =>
                        !item.selected &&
                        item.name
                          .toLowerCase()
                          .includes(this.state.searchText.toLowerCase())
                    )
                    .map((item: any) => (
                      <div
                        className="multiselector-select-element-container"
                        onClick={() => {
                          this.props.onSelect(item.id);
                        }}
                        key={item.id}
                      >
                        <div
                          className="point"
                          style={{ backgroundColor: item.color }}
                        ></div>
                        {item.name}
                      </div>
                    ))}
                </div>
              </div>
              +
            </div>
          )}
        </div>
      </>
    );
  }
}
