import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./BookingType.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";

import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { objectToArray, findWithAttr } from "../../functions/utils";
import ColerPicker from "../../components/ColorPicker";
import Input from "../../components/Input";
import Switch from "../../components/Switch";
import MultiSelector from "../../components/MultiSelector";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
    bookingTypeId: string;
  }>;

export default class BookingType extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    bookingTypeId: string;
    branch: any;
    branchLoaded: boolean;
    bookingTypeName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
    bookingTypeArr: Array<any>;
    allowTitle: boolean;
    allowDescription: boolean;
    allowWeeklyRepeat: boolean;
    allowDailyRepeat: boolean;
    allowPartners: boolean;
    minPartners: number;
    maxPartners: number;
    color: string;
    partnerRoles: Array<string>;
    deleteTime: number;
    roleArr: Array<any>;
  }
> {
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchLoaded: false,
      branchId: this.props.match.params.branchId,
      bookingTypeId: this.props.match.params.bookingTypeId,
      bookingTypeName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
      bookingTypeArr: [],
      allowTitle: false,
      allowDescription: false,
      allowWeeklyRepeat: false,
      allowDailyRepeat: false,
      allowPartners: false,
      minPartners: 0,
      maxPartners: 0,
      color: "#ffffff",
      partnerRoles: [],
      deleteTime: 0,
      roleArr: [],
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  componentDidUpdate = () => {
    if (this.state.bookingTypeId !== this.props.match.params.bookingTypeId) {
      this.setState(
        { bookingTypeId: this.props.match.params.bookingTypeId },
        this.updateBookingTypeData
      );
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdate = () => {
    this.setState({ updateLoading: false });
    this.props.refreshNavbar();
    this.requestBranch();
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      const bookingTypeArr: any = objectToArray(branch.bookingTypes);
      bookingTypeArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      this.setState(
        {
          branch: branch,
          bookingTypeArr,
        },
        this.updateBookingTypeData
      );
    }
  };

  updateBookingTypeData = () => {
    if (
      this.state.bookingTypeId &&
      this.state.branch?.bookingTypes &&
      this.state.bookingTypeId in this.state.branch.bookingTypes
    ) {
      const bookingType =
        this.state.branch.bookingTypes[this.state.bookingTypeId];

      const roleArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          const currBookingTypeRole = bookingType.partnerRoles;
          return {
            ...currBranchRole,
            ...{ selected: currBookingTypeRole.includes(roleId), id: roleId },
          };
        }
      );

      this.setState(
        {
          bookingTypeName: bookingType.name,
          allowTitle: bookingType.allowTitle,
          allowDescription: bookingType.allowDescription,
          allowWeeklyRepeat: bookingType.allowWeeklyRepeat,
          allowDailyRepeat: bookingType.allowDailyRepeat,
          allowPartners: bookingType.allowPartners,
          minPartners: bookingType.minPartners,
          maxPartners: bookingType.maxPartners,
          color: bookingType.color,
          partnerRoles: bookingType.partnerRoles,
          deleteTime: bookingType.deleteTime,
          roleArr,
        },
        this.checkChange
      );
    }
  };

  updateBookingType = () => {
    const partnerRoleIds: Array<string> = [];
    this.state.roleArr
      .filter((item: any) => item.selected)
      .forEach((item: any) => {
        partnerRoleIds.push(item.id);
      });
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBookingType",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        bookingTypeId: this.state.bookingTypeId,
        name: this.state.bookingTypeName,
        allowTitle: this.state.allowTitle,
        allowDescription: this.state.allowDescription,
        allowWeeklyRepeat: this.state.allowWeeklyRepeat,
        allowDailyRepeat: this.state.allowDailyRepeat,
        allowPartners: this.state.allowPartners,
        minPartners: this.state.minPartners,
        maxPartners: this.state.maxPartners,
        color: this.state.color,
        partnerRoles: partnerRoleIds,
        deleteTime: this.state.deleteTime,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleBookingTypeNameChange = (val: any) => {
    this.setState({ bookingTypeName: val }, this.checkChange);
  };

  handleColorChange = (val: any) => {
    this.setState({ color: val }, this.checkChange);
  };

  handleMinPartnersChange = (val: any) => {
    this.setState({ minPartners: val }, this.checkChange);
  };

  handleDeleteTimeChange = (val: any) => {
    this.setState({ deleteTime: val }, this.checkChange);
  };

  handleMaxPartnersChange = (val: any) => {
    this.setState({ maxPartners: val }, this.checkChange);
  };

  handleAllowTitleChange = () => {
    this.setState({ allowTitle: !this.state.allowTitle }, this.checkChange);
  };

  handleAllowDescriptionChange = () => {
    this.setState(
      { allowDescription: !this.state.allowDescription },
      this.checkChange
    );
  };

  handleAllowWeeklyRepeatChange = () => {
    this.setState(
      { allowWeeklyRepeat: !this.state.allowWeeklyRepeat },
      this.checkChange
    );
  };

  handleAllowDailyRepeatChange = () => {
    this.setState(
      { allowDailyRepeat: !this.state.allowDailyRepeat },
      this.checkChange
    );
  };

  handleAllowPartnersChange = () => {
    this.setState(
      { allowPartners: !this.state.allowPartners },
      this.checkChange
    );
  };

  checkChange = () => {
    // check if the bookingTypes have changed
    let partnerArrChange = false;
    const oldPartnerArr =
      this.state.branch.bookingTypes[this.state.bookingTypeId].partnerRoles;
    const newPartnerObj = this.state.roleArr.filter(
      (item: any) => item.selected
    );
    if (newPartnerObj.length !== oldPartnerArr.length) {
      partnerArrChange = true;
    } else {
      newPartnerObj.forEach((item: any) => {
        if (!oldPartnerArr.includes(item.id)) {
          partnerArrChange = true;
        }
      });
    }
    ///

    this.setState({
      change:
        this.state.bookingTypeName !==
          this.state.branch.bookingTypes[this.state.bookingTypeId]?.name ||
        this.state.allowTitle !==
          this.state.branch.bookingTypes[this.state.bookingTypeId].allowTitle ||
        this.state.allowDescription !==
          this.state.branch.bookingTypes[this.state.bookingTypeId]
            .allowDescription ||
        this.state.allowWeeklyRepeat !==
          this.state.branch.bookingTypes[this.state.bookingTypeId]
            .allowWeeklyRepeat ||
        this.state.allowDailyRepeat !==
          this.state.branch.bookingTypes[this.state.bookingTypeId]
            .allowDailyRepeat ||
        this.state.allowPartners !==
          this.state.branch.bookingTypes[this.state.bookingTypeId]
            .allowPartners ||
        this.state.minPartners !==
          this.state.branch.bookingTypes[this.state.bookingTypeId]
            .minPartners ||
        this.state.maxPartners !==
          this.state.branch.bookingTypes[this.state.bookingTypeId]
            .maxPartners ||
        this.state.color !==
          this.state.branch.bookingTypes[this.state.bookingTypeId].color ||
        this.state.deleteTime !==
          this.state.branch.bookingTypes[this.state.bookingTypeId].deleteTime ||
        partnerArrChange,
    });
  };

  handleDeleteBookingType = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleBookingTypeDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteBookingType",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        bookingTypeId: this.state.bookingTypeId,
      },
      1,
      5
    )
      .then(this.handleSuccessBookingTypeDeleted)
      .catch(this.handleError);
  };

  handleSuccessBookingTypeDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.setState(
        {
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/bookingType`,
        },
        () => {
          this.updateBookingTypeData();
          this.props.refreshNavbar();
        }
      );
    }
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ roleArr: newRoleArr }, this.checkChange);
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ roleArr: newRoleArr }, this.checkChange);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du die Buchungsart "${
            this.state.bookingTypeId &&
            this.state.branch?.bookingTypes[this.state.bookingTypeId]?.name
              ? this.state.branch.bookingTypes[this.state.bookingTypeId].name
              : "-"
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleBookingTypeDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.bookingTypeId &&
              Object.keys(this.state.branch.bookingTypes).length > 0 && (
                <>
                  <div className="subscreen-box-container">
                    <Title title="Einstellungen" />
                    <div className="branch-subtitle-container">
                      <h3>Name</h3>
                    </div>
                    <Input
                      name="Buchungsartname"
                      placeholder="Buchungsartname"
                      value={this.state.bookingTypeName}
                      onChange={this.handleBookingTypeNameChange}
                    />
                    <div>
                      <div className="branch-subtitle-container">
                        <h3>Farbe</h3>
                      </div>
                      <ColerPicker
                        value={this.state.color}
                        onChange={this.handleColorChange}
                        name="booking color picker"
                      />
                    </div>
                    <div className="branch-subtitle-container">
                      <h3>Titel erlaubt</h3>
                    </div>
                    <Switch
                      value={this.state.allowTitle}
                      onChange={this.handleAllowTitleChange}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Beschreibung erlaubt</h3>
                    </div>
                    <Switch
                      value={this.state.allowDescription}
                      onChange={this.handleAllowDescriptionChange}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Wöchentliche Wiederholung erlaubt</h3>
                    </div>
                    <Switch
                      value={this.state.allowWeeklyRepeat}
                      onChange={this.handleAllowWeeklyRepeatChange}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Tägliche Wiederholung erlaubt</h3>
                    </div>
                    <Switch
                      value={this.state.allowDailyRepeat}
                      onChange={this.handleAllowDailyRepeatChange}
                    />

                    <div className="branch-subtitle-container">
                      <h3>Partner erlaubt</h3>
                    </div>
                    <Switch
                      value={this.state.allowPartners}
                      onChange={this.handleAllowPartnersChange}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Minimale Partner</h3>
                    </div>
                    <Input
                      name="Minimale Partner"
                      type="number"
                      value={this.state.minPartners}
                      onChange={this.handleMinPartnersChange}
                      min={0}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Maximale Partner</h3>
                    </div>
                    <Input
                      name="Maximale Partner"
                      type="number"
                      value={this.state.maxPartners}
                      onChange={this.handleMaxPartnersChange}
                      min={1}
                    />

                    <div className="branch-subtitle-container">
                      <h3>Nur löschbar davor (in Minuten, -1 für ∞ )</h3>
                    </div>
                    <Input
                      name="deleteTime"
                      type="number"
                      value={this.state.deleteTime}
                      onChange={this.handleDeleteTimeChange}
                      min={-1}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Partnerrollen</h3>
                    </div>
                    <MultiSelector
                      searchTextPlaceholder="Rolle"
                      arr={this.state.roleArr}
                      onSelect={this.handleRoleSelect}
                      onUnselect={this.handleRoleUnselect}
                    />
                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updateBookingType}
                      title="Speichern"
                    />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.deleteLoading}
                      onClick={this.handleDeleteBookingType}
                      title="Buchungsart löschen"
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
