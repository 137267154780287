import React from "react";
import "./MyCourses.css";
import "../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  inPast,
  priceToStr,
} from "../functions/utils";
import SingleCourseElementOwn from "../components/SingleCourseElementOwn";
import BlockCourseElementOwn from "../components/BlockCourseElementOwn";
import InfinityCourseElementOwn from "../components/InfinityCourseElementOwn";
import { fetchRetry } from "../functions/request";
import Title from "../components/Title";

export default class MyCourses extends React.Component<
  {},
  {
    coursesLoaded: boolean;
    courses: Array<any>;
    showCreateModal: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      coursesLoaded: false,
      courses: [],
      showCreateModal: false,
    };
  }

  componentDidMount = () => {
    this.requestCourses();
  };

  requestCourses = () => {
    fetchRetry(
      "getCoursesOfUser",
      {
        limit: 50,
        onlyFuture: false,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCoursesSuccess = ({ data }: any) => {
    this.setState({ coursesLoaded: true });
    if (data.success) {
      const courses = data.data;
      courses.sort((a: any, b: any) => (a.startTime > b.startTime ? 1 : -1));
      this.setState({ courses });
    }
  };

  handleCloseCreateModal = () => {
    this.setState({ showCreateModal: false });
  };

  handleShowCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  render() {
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Meine Kurse" />
            {!this.state.coursesLoaded ? (
              <>
                <div className="loading-container">
                  <HashLoader color={"#c31924"} size={100} loading={true} />
                </div>
              </>
            ) : (
              <>
                {this.state.courses.length === 0 && (
                  <div className="no-courses-container">
                    Buche deinen ersten Kurs damit dieser hier angezeigt wird.
                  </div>
                )}
              </>
            )}
            {this.state.courses.map((course: any) => (
              <>
                {course.bookingArr.map((courseBooking: any) => (
                  <>
                    {course.courseType === "single-course" && (
                      <SingleCourseElementOwn
                        clubId={course.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        courseBooking={courseBooking}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        bookingCreated={dateToFullStr(
                          firebaseDateToDateObj(courseBooking.created_at)
                        )}
                        where={course.location.name}
                        freePlaces={course.freePlaces}
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        inPast={inPast(firebaseDateToDateObj(course.startTime))}
                        notice={course.bookingMailMessage}
                        status={
                          courseBooking.active
                            ? "Gebucht"
                            : courseBooking.refunded
                            ? "Storniert"
                            : "Nicht Gebucht"
                        }
                      />
                    )}
                    {course.courseType === "block-course" && (
                      <BlockCourseElementOwn
                        clubId={course.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        courseBooking={courseBooking}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        bookingCreated={dateToFullStr(
                          firebaseDateToDateObj(courseBooking.created_at)
                        )}
                        courses={course.courses}
                        freePlaces={course.freePlaces}
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        inPast={inPast(firebaseDateToDateObj(course.startTime))}
                        notice={course.bookingMailMessage}
                        key={course.id}
                        status={
                          courseBooking.active
                            ? "Gebucht"
                            : courseBooking.refunded
                            ? "Storniert"
                            : "Nicht Gebucht"
                        }
                      />
                    )}
                    {course.courseType === "infinity-course" && (
                      <InfinityCourseElementOwn
                        clubId={course.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        courseBooking={courseBooking}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        bookingCreated={dateToFullStr(
                          firebaseDateToDateObj(courseBooking.created_at)
                        )}
                        where={course.location?.name}
                        weekDay={course.weekDay}
                        freePlaces={course.freePlaces}
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        notice={course.bookingMailMessage}
                        key={course.id}
                        status={
                          courseBooking.active
                            ? "Gebucht"
                            : courseBooking.refunded
                            ? "Storniert"
                            : "Nicht Gebucht"
                        }
                      />
                    )}
                  </>
                ))}
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}
