import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./InfoContainer.css";

export default class InfoContainer extends React.Component<
  { active: boolean; text: string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.active && (
          <div className="info-container">
            <div>
              <div className="info-circle-icon-container">
                <FontAwesomeIcon
                  icon={["fas", "info-circle"]}
                  size="lg"
                  className="info-icon"
                />
              </div>
            </div>
            <div className="info-inner-container">{this.props.text}</div>
          </div>
        )}
      </>
    );
  }
}
