import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Modules.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import Module from "../../components/Module";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Modules extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branch: any;
    branchLoaded: boolean;
    updateLoading: boolean;
    useBooking: boolean;
    useBilling: boolean;
    useCourse: boolean;
  }
> {
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branch: {},
      branchLoaded: false,
      updateLoading: false,
      useBooking: false,
      useBilling: false,
      useCourse: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
        useBooking: branch.settings.useBooking,
        useBilling: branch.settings.useBilling,
        useCourse: branch.settings.useCourse,
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  updateBranch = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        settings: {
          useBooking: this.state.useBooking,
          useBilling: this.state.useBilling,
          useCourse: this.state.useCourse,
        },
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    this.props.refreshNavbar();
    this.requestBranch();
  };

  toggleBooking = () => {
    this.setState({ useBooking: !this.state.useBooking }, this.updateBranch);
  };

  toggleBilling = () => {
    this.setState({ useBilling: !this.state.useBilling }, this.updateBranch);
  };

  toggleCourse = () => {
    this.setState({ useCourse: !this.state.useCourse }, this.updateBranch);
  };

  render() {
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Module" />
            <div className="module-grid-container">
              {/* <Module
                iconPrefix="fas"
                iconName="calendar-alt"
                iconColor="cadetblue"
                title="Buchungssystem"
                description="Erlaubt deinen Mitgliedern Objekte zu buchen."
                isBeta={true}
                isPlanned={false}
                active={this.state.useBooking}
                onToggle={this.toggleBooking}
              /> */}
              <Module
                iconPrefix="fas"
                iconName="calendar-plus"
                iconColor="#f17e10"
                title="Kursbuchung"
                description="Erlaubt das Buchen von Kursen."
                isBeta={true}
                isPlanned={false}
                active={this.state.useCourse}
                onToggle={this.toggleCourse}
              />
              <Module
                iconPrefix="fas"
                iconName="euro-sign"
                iconColor="#4BA738"
                title="Zahlungen"
                description="Wickle Zahlungen, direkt über das Buchungssystem ab."
                isBeta={true}
                isPlanned={false}
                active={this.state.useBilling}
                onToggle={this.toggleBilling}
              />
              {/* <Module
                iconPrefix="fas"
                iconName="user-cog"
                iconColor="#B279D2"
                title="Mitgliedsverwaltung"
                description="Verwalte alle deine Mitglieder."
                isBeta={false}
                isPlanned={true}
                active={false}
                onToggle={() => {}}
              />
              <Module
                iconPrefix="fas"
                iconName="hammer"
                iconColor="rgb(211, 152, 43)"
                title="Arbeitsdienst"
                description="Verwalte den Arbeitsdienst deiner Mitglieder."
                isBeta={false}
                isPlanned={true}
                active={false}
                onToggle={() => {}}
              />
              <Module
                iconPrefix="fas"
                iconName="hotdog"
                iconColor="#7A4229"
                title="Kiosk-System"
                description="Wickle alle deine Getränke und Gerichte ab."
                isBeta={false}
                isPlanned={true}
                active={false}
                onToggle={() => {}}
              /> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
