import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./BackElement.css";

export default class BackElement extends React.Component<
  { text: string; to: string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Link to={this.props.to} className="back-container">
        <div>
          <FontAwesomeIcon
            icon={["fas", "long-arrow-alt-left"]}
            size="lg"
            onClick={() => {}}
            className="back-element-icon"
          />
          {this.props.text}
        </div>
      </Link>
    );
  }
}
