import React from "react";
import { Redirect } from "react-router-dom";

export default class Home extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    // return <Redirect to={"/clubsearch"} />;
    return <Redirect to={"/club/mXLre91ezRPccyRrLNGT"} />;
  }
}
