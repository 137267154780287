import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./CourseParticipant.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import TestMode from "../../components/TestMode";
import SubTitle from "../../components/SubTitle";
import Input from "../../components/Input";
import {
  dateToFullStr,
  dateToSmallStr,
  firebaseDateToDateObj,
  priceToStr,
  reformatDate,
  reformatDateReverse,
} from "../../functions/utils";
import Title from "../../components/Title";
import PaymentStatus from "../../components/PaymentStatus";
import SwitchSetting from "../../components/SwitchSetting";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
    courseParticipantId: string;
  }>;

export default class CourseParticipant extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBookingId: string;
    branchLoaded: boolean;
    branch: any;
    courseBookingLoaded: boolean;
    courseBookingData: any;
    email: string;
    fName: string;
    lName: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    note: string;
    paid: boolean;
    errorMessage: null | string;
    updateLoading: boolean;
    change: boolean;
    changeUserData: boolean;
    errorMessageUserData: null | string;
    updateLoadingUserData: boolean;
    showConfirmationModal: boolean;
    deleteLoading: boolean;
    errorMessageDelete: null | string;
    redirect: null | string;
    errorMsgCancellation: null | string;
    cancellationLoading: boolean;
    showConfirmationModalCancelCourse: boolean;
    refundNote: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      courseBookingId: this.props.match.params.courseParticipantId,
      branchLoaded: false,
      branch: {},
      courseBookingLoaded: false,
      courseBookingData: {},
      email: "",
      fName: "",
      lName: "",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      note: "",
      paid: false,
      errorMessage: null,
      updateLoading: false,
      change: false,
      changeUserData: false,
      errorMessageUserData: null,
      updateLoadingUserData: false,
      showConfirmationModal: false,
      deleteLoading: false,
      errorMessageDelete: null,
      redirect: null,
      errorMsgCancellation: null,
      cancellationLoading: false,
      showConfirmationModalCancelCourse: false,
      refundNote: "",
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestCourseBooking();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  requestCourseBooking = () => {
    fetchRetry(
      "getCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingSuccess = async ({ data }: any) => {
    this.setState({ courseBookingLoaded: true });
    if (data.success) {
      const courseBookingData = data.data;
      this.setState(
        {
          courseBookingData: courseBookingData,
          fName: courseBookingData?.user?.fName,
          lName: courseBookingData?.user?.lName,
          email: courseBookingData?.user?.email
            ? courseBookingData.user.email
            : "",
          street: courseBookingData?.user?.address?.street
            ? courseBookingData.user.address.street
            : "",
          city: courseBookingData?.user?.address?.city
            ? courseBookingData.user.address.city
            : "",
          houseNumber: courseBookingData?.user?.address?.houseNumber
            ? courseBookingData.user.address.houseNumber
            : "",
          zipcode: courseBookingData?.user?.address?.zipcode
            ? courseBookingData.user.address.zipcode
            : "",
          birthDate: reformatDateReverse(courseBookingData?.user?.birthDate),
          phoneNumber: courseBookingData?.user?.phoneNumber
            ? courseBookingData.user.phoneNumber
            : "",
          note: courseBookingData.note,
          paid: courseBookingData.paid,
        },
        () => {
          this.checkChange();
          this.checkChangeUserData();
        }
      );
    }
  };

  updateCourseBooking = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
        paid: this.state.paid,
        note: this.state.note,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingUpdatedSuccess)
      .catch(this.handleError);
  };

  updateCourseBookingUserData = () => {
    this.setState({ updateLoadingUserData: true });
    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
        fName: this.state.fName,
        lName: this.state.lName,
        address: {
          city: this.state.city,
          houseNumber: this.state.houseNumber,
          street: this.state.street,
          zipcode: this.state.zipcode,
        },
        birthDate: reformatDate(this.state.birthDate),
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingUserDataUpdatedSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingUserDataUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateLoadingUserData: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMessageUserData: null });
    } else {
      this.setState({ errorMessageUserData: data.errorMsgDe });
    }
  };

  requestCoursesBookingUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMessage: null });
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  handleRefundNoteChange = (val: any) => {
    this.setState({ refundNote: val });
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val }, this.checkChangeUserData);
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val }, this.checkChangeUserData);
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val }, this.checkChangeUserData);
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val }, this.checkChangeUserData);
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val }, this.checkChangeUserData);
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val }, this.checkChangeUserData);
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val }, this.checkChangeUserData);
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val }, this.checkChangeUserData);
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value }, this.checkChangeUserData);
  };

  togglePaid = () => {
    this.setState({ paid: !this.state.paid }, this.checkChange);
  };

  handleNoteChange = (val: any) => {
    this.setState({ note: val }, this.checkChange);
  };

  handleDeleteCourseBooking = () => {
    this.setState({ showConfirmationModal: true });
  };

  handleDeleteCourseBookingConfirm = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingDeleteSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingDeleteSuccess = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.setState({
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}`,
      });
      this.context.createInfo(
        "Die manuelle Buchung wurde erfolgreich gelöscht.",
        "success",
        4
      );
    } else {
      this.setState({ errorMessageDelete: data.errorMsgDe });
    }
  };

  handleCancellateCourse = () => {
    this.setState({ showConfirmationModalCancelCourse: true });
  };

  handleCancellateCourseConfirmed = () => {
    this.setState({
      cancellationLoading: true,
      showConfirmationModalCancelCourse: false,
    });
    fetchRetry(
      "createCourseRefund",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        courseBookingId: this.state.courseBookingId,
        refundNote: this.state.refundNote,
      },
      1,
      5
    )
      .then(this.createCancellationSuccess)
      .catch(this.handleError);
  };

  createCancellationSuccess = ({ data }: any) => {
    this.setState({ cancellationLoading: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMsgCancellation: null });
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.courseBookingData.note !== this.state.note ||
        this.state.courseBookingData.paid !== this.state.paid,
    });
  };

  checkChangeUserData = () => {
    /* eslint eqeqeq: 0 */
    this.setState({
      changeUserData:
        this.state.courseBookingData.user?.fName != this.state.fName ||
        this.state.courseBookingData.user?.lName != this.state.lName ||
        this.state.courseBookingData.user?.email != this.state.email ||
        reformatDateReverse(this.state.courseBookingData.user?.birthDate) !=
          this.state.birthDate ||
        this.state.courseBookingData.user?.phoneNumber !=
          this.state.phoneNumber ||
        this.state.courseBookingData.user?.address?.city != this.state.city ||
        this.state.courseBookingData.user?.address?.houseNumber !=
          this.state.houseNumber ||
        this.state.courseBookingData.user?.address?.street !=
          this.state.street ||
        this.state.courseBookingData.user?.address?.zipcode !=
          this.state.zipcode,
    });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  hideConfirmationModalCourse = () => {
    this.setState({ showConfirmationModalCancelCourse: false });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded || !this.state.courseBookingLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    const isCustom = this.state.courseBookingData?.method === "manuell";
    const hasAccount =
      !!this.state.courseBookingData?.userId ||
      this.state.branch?.user?.role !== "Owner";
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg="Willst du diese Kurs Buchung wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
          handleConfirm={this.handleDeleteCourseBookingConfirm}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModalCancelCourse}
          handleClose={this.hideConfirmationModalCourse}
          title="Bist du sicher?"
          msg="Willst du diese Kurs Buchung wirklich stornieren? Dieser Vorgang kann nicht rückgängig gemacht werden."
          handleConfirm={this.handleCancellateCourseConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Teilnehmerliste"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}`}
            />

            <TestMode active={this.state.branch.billing?.mode !== "live"} />
            <Title title="Buchungs Informationen" />
            <div>
              <table className="booking-information-table">
                <tbody>
                  <tr className="no-border">
                    <td>Status</td>
                    <td>
                      {this.state.courseBookingData.active
                        ? "Gebucht"
                        : this.state.courseBookingData.refunded
                        ? "Storniert"
                        : "Nicht Gebucht"}
                    </td>
                  </tr>
                  <tr>
                    <td>Methode</td>
                    <td>
                      {this.state.courseBookingData.method
                        ? this.state.courseBookingData.method
                        : "-"}
                    </td>
                  </tr>
                  {this.state.courseBookingData.price !== null && (
                    <tr>
                      <td>Preis</td>
                      <td>{priceToStr(this.state.courseBookingData.price)}€</td>
                    </tr>
                  )}
                  {this.state.courseBookingData.bookerId && (
                    <tr>
                      <td>Eingetragen von</td>
                      <td>{`${this.state.courseBookingData.booker?.fName} ${this.state.courseBookingData.booker?.lName}`}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Bezahl Status</td>
                    <td>
                      <div className="flex">
                        <PaymentStatus
                          type={this.state.courseBookingData.paymentStatus}
                          paid={this.state.paid}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Buchungszeitpunkt</td>
                    <td>
                      {dateToSmallStr(
                        firebaseDateToDateObj(
                          this.state.courseBookingData.created_at
                        )
                      )}
                    </td>
                  </tr>
                  {this.state.courseBookingData.refunded ? (
                    <>
                      <tr>
                        <td>Stornierungs Status</td>
                        <td>
                          <div className="flex">
                            <PaymentStatus
                              type={this.state.courseBookingData.refundStatus}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Stornierungszeitpunkt</td>
                        <td>
                          {dateToSmallStr(
                            firebaseDateToDateObj(
                              this.state.courseBookingData.refundCreatedAt
                            )
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {this.state.courseBookingData.cancellationDays !=
                        null && (
                        <>
                          <tr>
                            <td>Stornierungsfrist</td>
                            <td>
                              {" bis "}
                              {dateToFullStr(
                                firebaseDateToDateObj(
                                  this.state.courseBookingData.cancellationDate
                                )
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                  <tr>
                    <td>Konto</td>
                    <td>
                      {this.state.courseBookingData.userId
                        ? "Registriert"
                        : "Gast"}
                    </td>
                  </tr>
                </tbody>
              </table>
              {!isCustom && this.state.branch?.user?.role === "Owner" && (
                <div className="cancellationContainer">
                  <ErrorMessage message={this.state.errorMsgCancellation} />
                  <Input
                    value={this.state.refundNote}
                    onChange={this.handleRefundNoteChange}
                    placeholder="Stornierungsnachricht"
                  />
                  <SecButton
                    change={!this.state.courseBookingData.refunded}
                    color="red"
                    loading={this.state.cancellationLoading}
                    onClick={this.handleCancellateCourse}
                    title="Kurs stornieren"
                  />
                </div>
              )}
              {isCustom && this.state.branch?.user?.role === "Owner" && (
                <>
                  <SubTitle title="Notiz" />
                  <Input
                    value={this.state.note}
                    onChange={this.handleNoteChange}
                    placeholder="Notiz"
                  />
                  <SwitchSetting
                    value={this.state.paid}
                    name="Bezahlt"
                    onChange={this.togglePaid}
                  />
                  <ErrorMessage message={this.state.errorMessage} />
                  <SecButton
                    change={this.state.change}
                    color="green"
                    loading={this.state.updateLoading}
                    onClick={this.updateCourseBooking}
                    title="Speichern"
                    className="btn save-courseBooking-btn"
                  />
                  <ErrorMessage message={this.state.errorMessageDelete} />
                  <SecButton
                    change={true}
                    color="red"
                    loading={this.state.deleteLoading}
                    onClick={this.handleDeleteCourseBooking}
                    title="Buchung löschen"
                    className="delete-courseBooking-btn"
                  />
                </>
              )}
            </div>
            <Title title="Teilnehmer Informationen" />
            <div>
              <SubTitle title="Name" />
              <div className="course-time-input-container">
                <Input
                  value={this.state.fName}
                  onChange={this.handleFNameChange}
                  placeholder="Vorname"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.lName}
                  onChange={this.handleLNameChange}
                  placeholder="Nachname"
                  disabled={hasAccount}
                />
              </div>
              <SubTitle title={`E-Mail ${isCustom ? "(optional)" : ""}`} />
              <Input
                value={this.state.email}
                onChange={this.handleEmailChange}
                placeholder="E-Mail"
                disabled={hasAccount}
              />
              <SubTitle title={`Adresse ${isCustom ? "(optional)" : ""}`} />
              <div className="course-time-input-container">
                <Input
                  value={this.state.street}
                  onChange={this.handleStreetChange}
                  placeholder="Straße"
                  className="street-input"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.houseNumber}
                  onChange={this.handleHouseNumberChange}
                  placeholder="Nr."
                  className="house-number-input"
                  disabled={hasAccount}
                />
              </div>
              <div className="profile-location-container">
                <Input
                  value={this.state.zipcode}
                  onChange={this.handleZipcodeChange}
                  placeholder="PLZ"
                  className="zipcode-input"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  placeholder="Stadt"
                  className="city-input"
                  disabled={hasAccount}
                />
              </div>
              <SubTitle
                title={`Telefonnummer ${isCustom ? "(optional)" : ""}`}
              />
              <Input
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumberChange}
                placeholder="Telefonnummer"
                disabled={hasAccount}
              />
              <SubTitle
                title={`Geburtsdatum ${isCustom ? "(optional)" : ""}`}
              />
              <input
                type="date"
                className="course-input"
                value={this.state.birthDate}
                onChange={this.handleBirthDateChange}
                disabled={hasAccount}
              />

              {!hasAccount &&
                isCustom &&
                this.state.branch?.user?.role === "Owner" && (
                  <>
                    <ErrorMessage message={this.state.errorMessageUserData} />
                    <SecButton
                      change={this.state.changeUserData}
                      color="green"
                      loading={this.state.updateLoadingUserData}
                      onClick={this.updateCourseBookingUserData}
                      title="Speichern"
                      className="btn save-courseBooking-btn"
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
