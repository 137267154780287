import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./SubTitle.css";

export default class SubTitle extends React.Component<
  { title: string; helpText?: string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="subtitle-container">
        <h3>{this.props.title}</h3>
        {this.props.helpText && (
          <div className="info-icon-container">
            <FontAwesomeIcon
              icon={["far", "question-circle"]}
              size="1x"
              onClick={() => {}}
              className="back-element-icon"
            />
            <div className="info-text-container">
              <div className="info-text-triangle"></div>
              <div className="info-text-inner-container">
                {this.props.helpText}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
