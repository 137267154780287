import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import "./Main.css";
import HashLoader from "react-spinners/HashLoader";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Main extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    branchesMember: Array<any>;
    branchesRequested: Array<any>;
    branchesOthers: Array<any>;
    selectedBranch: any;
    showJoinModal: boolean;
    joinModalLoading: boolean;
    joinModalMsg: null | string;
    joinModalType: "error" | "success";
    showRequestedModal: boolean;
    requestedModalLoading: boolean;
    requestedModalMsg: null | string;
    requestedModalType: "error" | "success";
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      branchesMember: [],
      branchesOthers: [],
      branchesRequested: [],
      selectedBranch: {},
      showJoinModal: false,
      joinModalLoading: false,
      joinModalMsg: null,
      joinModalType: "error",
      showRequestedModal: false,
      requestedModalLoading: false,
      requestedModalMsg: null,
      requestedModalType: "error",
    };
  }

  componentDidMount = () => {
    this.handleClubContext();
  };

  handleClubContext = () => {
    this.setState({ clubLoaded: true });
    const club = this.context.club;
    const branches = club.branches;
    const branchesMember: Array<any> = [];
    const branchesRequested: Array<any> = [];
    const branchesOthers: Array<any> = [];
    for (const branchId in branches) {
      const branch = branches[branchId];
      branch.id = branchId;
      if (branch.user.isMember && branch.user.accepted) {
        branchesMember.push(branch);
      } else if (branch.user.isMember) {
        branchesRequested.push(branch);
      } else {
        branchesOthers.push(branch);
      }
    }
    branchesMember.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    branchesRequested.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    branchesOthers.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

    this.setState({
      club: club,
      branchesMember: branchesMember,
      branchesRequested: branchesRequested,
      branchesOthers: branchesOthers,
    });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    const club = this.context.club;
    const branches = club.branches;
    const branchesMember: Array<any> = [];
    const branchesRequested: Array<any> = [];
    const branchesOthers: Array<any> = [];
    for (const branchId in branches) {
      const branch = branches[branchId];
      branch.id = branchId;
      if (branch.user.isMember && branch.user.accepted) {
        branchesMember.push(branch);
      } else if (branch.user.isMember) {
        branchesRequested.push(branch);
      } else {
        branchesOthers.push(branch);
      }
    }
    branchesMember.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    branchesRequested.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    branchesOthers.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

    return (
      <>
        <div className="big-banner-container">
          <>
            {this.context.club?.bannerHighRes && (
              <img
                src={this.context.club.bannerHighRes}
                alt="Vereinsbanner"
                className="club-banner-big"
              />
            )}
            {this.context.club?.settings?.showLogoInBanner &&
              this.context.club?.logoHighRes && (
                <img
                  src={this.context.club.logoHighRes}
                  alt="Vereinslogo"
                  className="club-logo-big"
                />
              )}
          </>
        </div>
        <div className="inner-container-club">
          {!this.context.clubLoaded && (
            <>
              <div className="loading-container">
                <HashLoader color={"#c31924"} size={100} loading={true} />
              </div>
            </>
          )}
          <div className="club-name-container">
            <h1>{this.context.club?.name}</h1>
          </div>
          <div className="branches-container">
            {branchesMember.length > 0 && (
              <>
                <span className="branch-title">Mitglied</span>
                <div className="branch-grid">
                  {branchesMember.map((branch: any) => (
                    <Link
                      to={`/club/${this.context.clubId}/branch/${branch.id}`}
                      className="branch-container no-underline"
                      key={branch.id}
                    >
                      <span>{branch.name}</span>
                    </Link>
                  ))}
                </div>
              </>
            )}
            {branchesRequested.length > 0 && (
              <>
                <span className="branch-title">Angefragt</span>
                <div className="branch-grid">
                  {branchesRequested.map((branch: any) => (
                    <Link
                      to={`/club/${this.context.clubId}/branch/${branch.id}`}
                      className="no-underline"
                      key={branch.id}
                    >
                      <div className="branch-container" key={branch.id}>
                        <span>{branch.name}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            )}
            {branchesOthers.length > 0 && (
              <>
                <span className="branch-title">Bereiche</span>
                <div className="branch-grid">
                  {branchesOthers.map((branch: any) => (
                    <Link
                      to={`/club/${this.context.clubId}/branch/${branch.id}`}
                      className="no-underline"
                      key={branch.id}
                    >
                      <div className="branch-container" key={branch.id}>
                        <span>{branch.name}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            )}
            {branchesMember.length === 0 &&
              branchesRequested.length === 0 &&
              branchesOthers.length === 0 &&
              this.context.clubLoaded && (
                <div className="center no-branches-available">
                  Es stehen keine Bereiche zur Verfügung.
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}
