import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./Users.css";
import "../../App.css";
import { addIdToObject } from "../../functions/utils";
import Input from "../../components/Input";
import MultiSelector from "../../components/MultiSelector";
import { fetchRetry } from "../../functions/request";
import { HashLoader } from "react-spinners";
import { roles } from "../../services/constants";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Users extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branch: any;
    branchId: string;
    branchLoaded: boolean;
    userSearchVal: string;
    users: any;
    userArr: Array<any>;
    filteredUsers: Array<any>;
    usersLoaded: boolean;
    redirect: string | null;
  }
> {
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchId: this.props.match.params.branchId,
      branchLoaded: false,
      userSearchVal: "",
      users: {},
      userArr: [],
      filteredUsers: [],
      usersLoaded: false,
      redirect: null,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
        },
        this.requestUsers
      );
    }
  };

  requestUsers = () => {
    fetchRetry(
      "getUsersFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleUsers)
      .catch(this.handleError);
  };

  handleUsers = ({ data }: any) => {
    this.setState({ usersLoaded: true });
    if (data.success) {
      const users = addIdToObject(data.data);
      this.setState({ users: users }, this.handleCombindUsers);
    }
  };

  handleCombindUsers = () => {
    const userData = this.state.users;
    const userArr = [];
    for (const userId in userData) {
      const user = userData[userId];
      user.id = userId;
      const roles = this.state.branch.roles;
      user.roleArr = user.roles.map((roleId: string) => {
        return { id: roleId, name: roles[roleId].name, selected: true };
      });
      userArr.push(user);
    }
    userArr.sort((a: any, b: any) => (a.lName < b.lName ? 1 : -1));
    userArr.sort((a: any, b: any) => (a.fName < b.fName ? 1 : -1));
    userArr.sort((a: any, b: any) => (a.accepted > b.accepted ? 1 : -1));
    this.setState(
      {
        userArr: userArr,
      },
      this.filterUsers
    );
  };

  handleError = (err: any) => {
    console.error(err);
  };

  filterUsers = () => {
    let filterText = this.state.userSearchVal;
    filterText = filterText.toLocaleLowerCase();
    const filteredUsers = this.state.userArr.filter((user: any) => {
      let fName = user.fName ? user.fName : "";
      fName = fName.toLowerCase();
      let lName = user.lName ? user.lName : "";
      lName = lName.toLowerCase();
      let email = user.email ? user.email : "";
      email = email.toLowerCase();
      let role = user.role ? user.role : "";
      role = role.toLowerCase();
      if (
        `${fName} ${lName}`.includes(filterText) ||
        `${lName} ${fName}`.includes(filterText) ||
        (email && email.includes(filterText)) ||
        (role && role.includes(filterText)) ||
        (this.state.branch?.clubSettings?.useMembershipNumber &&
          user?.membershipNumber &&
          user?.membershipNumber.includes(filterText))
      ) {
        return true;
      }
      return false;
    });
    this.setState({ filteredUsers: filteredUsers });
  };

  handleChangeClubSearchVal = (val: any) => {
    this.setState({ userSearchVal: val }, this.filterUsers);
  };

  handleUserClick = (userId: any) => {
    this.setState({
      redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${userId}`,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded || !this.state.usersLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Input
              name="max-booking-length"
              value={this.state.userSearchVal}
              placeholder="Nutzersuche"
              onChange={this.handleChangeClubSearchVal}
            />
            <table className="user-table">
              <thead>
                <tr>
                  {this.state.branch?.clubSettings?.useMembershipNumber && (
                    <th className="user-table-membershipNumber-title"></th>
                  )}
                  <th>Vorname</th>
                  <th>Nachname</th>
                  <th className="user-table-email">E-Mail</th>
                  <th className="user-table-rights">Rechte</th>
                  <th className="user-table-status">Status</th>
                  <th className="user-table-roles">Rollen</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredUsers.map((userData, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      this.handleUserClick(userData.id);
                    }}
                  >
                    {this.state.branch?.clubSettings?.useMembershipNumber && (
                      <td>{userData.membershipNumber}</td>
                    )}
                    <td>{userData.fName}</td>
                    <td>{userData.lName}</td>
                    <td className="user-table-email">{userData.email}</td>
                    <td className="user-table-rights">
                      {roles[userData.role].germName}
                    </td>
                    <td className="user-table-status">
                      {userData.accepted ? "Aktiv" : "Angefragt"}
                    </td>
                    <td className="user-table-roles">
                      <MultiSelector
                        viewOnly
                        onSelect={() => {}}
                        onUnselect={() => {}}
                        arr={userData.roleArr}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
