import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Billing.css";
import "../../App.css";
import { HashLoader, ClipLoader } from "react-spinners";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import TestMode from "../../components/TestMode";
import Selector from "../../components/Selector";
import SecButton from "../../components/SecButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoContainer from "../../components/InfoContainer";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

const statusMsg: any = {
  "needs-data": "Benötigt weitere Informationen",
  "in-review": "wird bearbeitet",
  completed: "Einsatzbereit",
  "not-connected": "Nicht Verbunden",
  open: "Nicht Verbunden",
};

export default class Billing extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    requestNeeded: boolean;
    branch: any;
    branchLoaded: boolean;
    change: boolean;
    updateLoading: boolean;
    mollieConnectLoading: boolean;
    mollieIdentifier: string;
    mollieData: any;
    mollieLoaded: boolean;
    profileArr: Array<any>;
    selectedProfil: string;
    profilesLoaded: boolean;
    updateProfileLoading: boolean;
    lockLoading: boolean;
  }
> {
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      requestNeeded: false,
      branch: {},
      branchLoaded: false,
      change: false,
      updateLoading: false,
      mollieConnectLoading: false,
      mollieIdentifier: "",
      mollieData: {
        canReceivePayments: false,
        canReceiveSettlements: false,
        name: "",
        status: "",
      },
      mollieLoaded: false,
      profileArr: [],
      selectedProfil: "",
      profilesLoaded: false,
      updateProfileLoading: false,
      lockLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestMollieStatus();
    this.requestProfiles();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
          requestNeeded: branch.settings.requestNeeded,
        },
        this.checkChange
      );
    }
  };

  requestProfiles = () => {
    this.setState({ profilesLoaded: false });
    fetchRetry(
      "getMollieProfiles",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleProfiles)
      .catch(this.handleError);
  };

  handleProfiles = ({ data }: any) => {
    this.setState({ profilesLoaded: true });
    if (data.success) {
      const profileArr = data.data;
      this.setState(
        {
          profileArr,
        },
        this.checkChange
      );
    }
  };

  updateProfile = () => {
    this.setState({ updateProfileLoading: true });
    fetchRetry(
      "updateMollieProfile",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        profileId: this.state.selectedProfil,
      },
      1,
      5
    )
      .then(this.handleProfileUpdatedSuccess)
      .catch(this.handleError);
  };

  handleProfileUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateProfileLoading: false });
    if (data.success) {
      this.requestMollieStatus();
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  checkChange = () => {
    this.setState({
      change: false,
    });
  };

  updateBranch = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBranch",
      { clubId: this.state.clubId, branchId: this.state.branchId },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    this.requestBranch();
  };

  handleMollieConnect = () => {
    this.setState({ mollieConnectLoading: true });
    fetchRetry(
      "connectMollie",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleSuccessMollieConnect)
      .catch(this.handleError);
  };

  requestMollieStatus = () => {
    fetchRetry(
      "getMollieStatus",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleMollieStatus)
      .catch(this.handleError);
  };

  handleMollieStatus = ({ data }: any) => {
    this.setState({ mollieLoaded: true });
    if (data.success) {
      this.setState({
        mollieData: data.data,
        selectedProfil: data.data.profileId,
      });
    }
  };

  handleSuccessMollieConnect = ({ data }: any) => {
    this.setState({ mollieConnectLoading: false });
    if (data.success) {
      const identifier = data.identifier;
      this.setState({
        mollieIdentifier: identifier,
      });
      window.location.href = `https://www.mollie.com/oauth2/authorize?client_id=app_VkNJvsxhR8FrK4ym3Kyaay6e&state=${identifier}&scope=payments.read payments.write refunds.read refunds.write customers.read customers.write onboarding.write onboarding.read subscriptions.read subscriptions.write mandates.read mandates.write profiles.read profiles.write&response_type=code&approval_prompt=auto`;
    }
  };

  handleProfilSelected = (profileId: string) => {
    this.setState({ selectedProfil: profileId });
  };

  unlockBilling = () => {
    this.setState({ lockLoading: true });
    fetchRetry(
      "setBillingLock",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        locked: false,
      },
      1,
      5
    )
      .then(this.handleLockSuccess)
      .catch(this.handleError);
  };

  lockBilling = () => {
    this.setState({ lockLoading: true });
    fetchRetry(
      "setBillingLock",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        locked: true,
      },
      1,
      5
    )
      .then(this.handleLockSuccess)
      .catch(this.handleError);
  };

  handleLockSuccess = ({ data }: any) => {
    this.setState({ lockLoading: false });
    if (data.success) {
      this.requestMollieStatus();
    }
  };

  render() {
    if (
      !this.state.branchLoaded ||
      !this.state.mollieLoaded ||
      !this.state.profilesLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <div className="title-icon-container">
              <Title title="Abrechnung" className="no-margin" />
              <div className="flex">
                {this.state.lockLoading && (
                  <div className="lock-loading-container">
                    <ClipLoader color={"#6D6D6D"} size={10} loading={true} />
                  </div>
                )}
                {this.state.mollieData.locked ? (
                  <FontAwesomeIcon
                    icon={["fas", "lock"]}
                    size="lg"
                    onClick={this.unlockBilling}
                    className="lock-icon"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={["fas", "lock-open"]}
                    size="lg"
                    onClick={this.lockBilling}
                    className="lock-icon"
                  />
                )}
              </div>
            </div>
            <InfoContainer
              text={`Die Zahlungs-Einstellungen können aus Sicherheitsgründen nur von ${this.state.mollieData.lockedUser?.fName} ${this.state.mollieData.lockedUser?.lName} geändert werden. Nur  ${this.state.mollieData.lockedUser?.fName} ${this.state.mollieData.lockedUser?.lName} kann die Zahlungs-Einstellungen wieder freigeben.`}
              active={this.state.mollieData.locked}
            />
            <TestMode active={this.state.mollieData.mode !== "live"} />
            <div className="mollie-onboarding-container">
              <div
                onClick={this.handleMollieConnect}
                className="mollie-connect-container"
              >
                <div>
                  <span className="connect-text">
                    <span className="font-mollie">mollie</span> verbinden
                  </span>
                </div>
                {this.state.mollieConnectLoading && (
                  <div className="mollie-loading-container">
                    <ClipLoader color={"white"} size={30} loading={true} />
                  </div>
                )}
              </div>
              <div className="mollie-onboarding-info-container">
                <div className="mollie-onboarding-info-inner-container">
                  <div className="mollie-onboarding-row">
                    <div className="mollie-onboarding-title">
                      <span>Name:</span>
                    </div>
                    <div className="mollie-onboarding-value">
                      <span>
                        {this.state.mollieData.name
                          ? this.state.mollieData.name
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="mollie-onboarding-row">
                    <div className="mollie-onboarding-title">
                      <span>Status:</span>
                    </div>
                    <div className="mollie-onboarding-value">
                      <span>{statusMsg[this.state.mollieData.status]}</span>
                    </div>
                  </div>
                  <div className="mollie-onboarding-row">
                    <div className="mollie-onboarding-title">
                      <span>Kann Zahlungen Empfangen:</span>
                    </div>
                    <div className="mollie-onboarding-value">
                      <span>
                        {this.state.mollieData.canReceivePayments
                          ? "Ja"
                          : "Nein"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateBranch}
              title="Speichern"
            /> */}
            <Title title="Profil" />
            <Selector
              placeholder="Molli Profil"
              options={this.state.profileArr}
              value={this.state.selectedProfil}
              onSelect={this.handleProfilSelected}
              default={null}
            />
            <div className="medium-space"></div>
            <SecButton
              change={
                this.state.selectedProfil !== this.state.mollieData.profileId
              }
              color="green"
              loading={this.state.updateProfileLoading}
              onClick={this.updateProfile}
              title="Speichern"
            />
          </div>
        </div>
      </>
    );
  }
}
