import React from "react";
import "./TextArea.css";

export default class TextArea extends React.Component<
  {
    value: string | number;
    onChange: any;
    name?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  handleChange = (val: any) => {
    this.props.onChange(val.target.value);
  };

  render() {
    return (
      <>
        <textarea
          className={`text-area-input-container ${this.props.className}`}
          name={this.props.name ? this.props.name : "input"}
          value={this.props.value}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          onChange={this.handleChange}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}
