import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import "./Users.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import Input from "../../components/Input";
import { fetchRetry } from "../../functions/request";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Users extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    userSearchVal: string;
    users: Array<any>;
    filteredUsers: Array<any>;
    usersLoaded: boolean;
    selectedUser: any;
    redirect: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      userSearchVal: "",
      users: [],
      filteredUsers: [],
      usersLoaded: false,
      selectedUser: {},
      redirect: null,
    };
  }

  componentDidMount = () => {
    this.requestClub();
    this.requestUsers();
  };

  requestClub = () => {
    fetchRetry("getClubsFromClubId", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleClub)
      .catch(this.handleError);
  };

  requestUsers = () => {
    fetchRetry("getUsersFromClub", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleUsers)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data[this.state.clubId];
      this.setState({
        club: club,
      });
    }
  };

  handleUsers = ({ data }: any) => {
    this.setState({ usersLoaded: true });
    if (data.success) {
      const userData = data.data;
      const userArr = [];
      for (const userId in userData) {
        const user = userData[userId];
        user.id = userId;
        userArr.push(user);
      }
      userArr.sort((a: any, b: any) => (a.lName < b.lName ? 1 : -1));
      userArr.sort((a: any, b: any) => (a.fName < b.fName ? 1 : -1));
      userArr.sort((a: any, b: any) => (a.accepted > b.accepted ? 1 : -1));
      this.setState(
        {
          users: userArr,
        },
        this.filterUsers
      );
    }
  };

  filterUsers = () => {
    let filterText = this.state.userSearchVal;
    filterText = filterText.toLocaleLowerCase();
    const filteredUsers = this.state.users.filter((user: any) => {
      let fName = user.fName ? user.fName : "";
      fName = fName.toLowerCase();
      let lName = user.lName ? user.lName : "";
      lName = lName.toLowerCase();
      let email = user.email ? user.email : "";
      email = email.toLowerCase();
      let role = user.role ? user.role : "";
      role = role.toLowerCase();
      if (
        `${fName} ${lName}`.includes(filterText) ||
        `${lName} ${fName}`.includes(filterText) ||
        (email && email.includes(filterText)) ||
        (role && role.includes(filterText)) ||
        (this.state.club?.settings?.useMembershipNumber &&
          user?.membershipNumber &&
          user?.membershipNumber.includes(filterText))
      ) {
        return true;
      }
      return false;
    });
    this.setState({ filteredUsers: filteredUsers });
  };

  handleChangeClubSearchVal = (val: any) => {
    this.setState({ userSearchVal: val }, this.filterUsers);
  };

  handleUserClick = (userData: any) => {
    this.setState({
      redirect: `/club-settings/${this.state.clubId}/users/${userData.id}`,
    });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.clubLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Input
              name="max-booking-length"
              value={this.state.userSearchVal}
              placeholder="Nutzersuche"
              onChange={this.handleChangeClubSearchVal}
            />

            <table className="user-table">
              <thead>
                <tr>
                  {this.state.club?.settings?.useMembershipNumber && (
                    <th className="user-table-membershipNumber-title"></th>
                  )}
                  <th>Vorname</th>
                  <th>Nachname</th>
                  <th className="user-table-email">E-Mail</th>
                  <th className="user-table-rights">Rechte</th>
                  <th className="user-table-status">Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredUsers.map((userData, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      this.handleUserClick(userData);
                    }}
                  >
                    {this.state.club?.settings?.useMembershipNumber && (
                      <td>{userData.membershipNumber}</td>
                    )}
                    <td>{userData.fName}</td>
                    <td>{userData.lName}</td>
                    <td className="user-table-email">{userData.email}</td>
                    <td className="user-table-rights">
                      {userData.role === "Owner" ? "Admin" : "Mitglied"}
                    </td>
                    <td className="user-table-status">
                      {userData.accepted ? "Aktiv" : "Angefragt"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!this.state.usersLoaded && (
              <div className="loading-table-container">
                <HashLoader color={"#c31924"} size={100} loading={true} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
