import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./Course.css";
import "./ManageCourses.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SingleCourseElementManage from "../../components/SingleCourseElementManage";
import InfinityCourseElementManage from "../../components/InfinityCourseElementManage";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  priceToStr,
} from "../../functions/utils";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestMode from "../../components/TestMode";
import Title from "../../components/Title";
import BlockCourseElementManage from "../../components/BlockCourseElementManage";
import SearchInput from "../../components/SearchInput";
import TextCheckbox from "../../components/TextCheckbox";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class ManageCourses extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branchLoaded: boolean;
    coursesLoaded: boolean;
    branch: any;
    courses: Array<any>;
    coursesSearch: Array<any>;
    courseSearch: string;
    showDeletedCourses: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branchLoaded: false,
      coursesLoaded: false,
      branch: {},
      courses: [],
      coursesSearch: [],
      courseSearch: "",
      showDeletedCourses: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestCourses();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  requestCourses = () => {
    fetchRetry(
      "getCoursesOfBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        limit: 50,
        onlyFuture: false,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCoursesSuccess = ({ data }: any) => {
    if (data.success) {
      const courses = data.data;
      courses.sort((a: any, b: any) =>
        a.startTime._seconds > b.startTime._seconds ? 1 : -1
      );
      this.setState(
        { courses, coursesSearch: courses, coursesLoaded: true },
        () => {
          this.handleChangeCourseSearch(this.state.courseSearch);
        }
      );
    }
  };

  handleChangeCourseSearch = (val: string) => {
    const searchTerm = val.toLowerCase();
    const coursesSearch: Array<any> = this.state.courses.filter(
      (course: any) => {
        return (
          course.title.toLowerCase().includes(searchTerm) &&
          (this.state.showDeletedCourses || !course.deleted)
        );
      }
    );
    this.setState({ courseSearch: val, coursesSearch });
  };

  toggleShowDeletedCourses = () => {
    this.setState(
      { showDeletedCourses: !this.state.showDeletedCourses },
      () => {
        this.handleChangeCourseSearch(this.state.courseSearch);
      }
    );
  };

  render() {
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    const testMode = this.state.branch?.billing?.mode !== "live";
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Kurse" />
            <TestMode active={testMode} />
            {(this.state.branch?.user?.role === "Owner" ||
              this.context.isAdmin) && (
              <div className="inline-block">
                <Link
                  className="create-btn"
                  to={`/club/${this.state.clubId}/branch/${this.state.branchId}/createCourse`}
                >
                  <div className="create-icon-container">
                    <FontAwesomeIcon icon={["fas", "plus-circle"]} size="1x" />
                  </div>
                  Kurs erstellen
                </Link>
              </div>
            )}
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            <TextCheckbox
              text="gelöschte Kurse anzeigen"
              val={this.state.showDeletedCourses}
              onChange={this.toggleShowDeletedCourses}
            />
            {!this.state.coursesLoaded && (
              <>
                <div className="loading-container">
                  <HashLoader color={"#c31924"} size={100} loading={true} />
                </div>
              </>
            )}
            {this.state.coursesSearch.map((course: any) => (
              <>
                {course.courseType === "single-course" && (
                  <SingleCourseElementManage
                    clubId={this.state.clubId}
                    branchId={this.state.branchId}
                    courseId={course.id}
                    title={course.title}
                    description={course.description}
                    when={dateToFullStr(
                      firebaseDateToDateObj(course.startTime)
                    )}
                    where={course.location.name}
                    freePlaces={
                      testMode ? course.freePlacesTest : course.freePlaces
                    }
                    price={priceToStr(course.price)}
                    imgUrl={course.imgLowRes}
                    editable={
                      this.state.branch?.user?.role === "Owner" ||
                      this.context.isAdmin
                    }
                    viewable={this.state.branch?.user?.role === "ViewOnly"}
                    deleted={course.deleted}
                    key={course.id}
                  />
                )}
                {course.courseType === "block-course" && (
                  <BlockCourseElementManage
                    clubId={this.state.clubId}
                    branchId={this.state.branchId}
                    courseId={course.id}
                    title={course.title}
                    description={course.description}
                    when={dateToFullStr(
                      firebaseDateToDateObj(course.startTime)
                    )}
                    courses={course.courses}
                    freePlaces={
                      testMode ? course.freePlacesTest : course.freePlaces
                    }
                    price={priceToStr(course.price)}
                    imgUrl={course.imgLowRes}
                    editable={
                      this.state.branch?.user?.role === "Owner" ||
                      this.context.isAdmin
                    }
                    viewable={this.state.branch?.user?.role === "ViewOnly"}
                    deleted={course.deleted}
                    key={course.id}
                  />
                )}
                {course.courseType === "infinity-course" && (
                  <InfinityCourseElementManage
                    clubId={this.state.clubId}
                    branchId={this.state.branchId}
                    courseId={course.id}
                    title={course.title}
                    description={course.description}
                    when={dateToFullStr(
                      firebaseDateToDateObj(course.startTime)
                    )}
                    where={course.location?.name}
                    weekDay={course.weekDay}
                    freePlaces={
                      testMode ? course.freePlacesTest : course.freePlaces
                    }
                    price={priceToStr(course.price)}
                    imgUrl={course.imgLowRes}
                    editable={
                      this.state.branch?.user?.role === "Owner" ||
                      this.context.isAdmin
                    }
                    viewable={this.state.branch?.user?.role === "ViewOnly"}
                    deleted={course.deleted}
                    key={course.id}
                  />
                )}
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}
