import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./Course.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SingleCourseElement from "../../components/SingleCourseElement";
import BlockCourseElement from "../../components/BlockCourseElement";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  priceToStr,
} from "../../functions/utils";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import JoinBranchModal from "../../components/modals/JoinBranchModal";
import RequestedBranchModal from "../../components/modals/RequestedBranchModal";
import InfinityCourseElement from "../../components/InfinityCourseElement";
import TestMode from "../../components/TestMode";
import Title from "../../components/Title";
import SearchInput from "../../components/SearchInput";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Course extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branchLoaded: boolean;
    coursesLoaded: boolean;
    branch: any;
    courses: Array<any>;
    coursesSearch: Array<any>;
    showJoinModal: boolean;
    joinModalLoading: boolean;
    joinModalMsg: null | string;
    joinModalType: "error" | "success";
    showRequestedModal: boolean;
    requestedModalLoading: boolean;
    requestedModalMsg: null | string;
    requestedModalType: "error" | "success";
    courseSearch: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branchLoaded: false,
      coursesLoaded: false,
      branch: {},
      courses: [],
      coursesSearch: [],
      showJoinModal: false,
      joinModalLoading: false,
      joinModalMsg: null,
      joinModalType: "error",
      showRequestedModal: false,
      requestedModalLoading: false,
      requestedModalMsg: null,
      requestedModalType: "error",
      courseSearch: "",
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestCourses();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  requestCourses = () => {
    fetchRetry(
      "getCoursesOfBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        limit: 50,
        onlyFuture: true,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCoursesSuccess = ({ data }: any) => {
    this.setState({ coursesLoaded: true });
    if (data.success) {
      const courses = data.data;
      courses.sort((a: any, b: any) =>
        a.startTime._seconds > b.startTime._seconds ? 1 : -1
      );
      this.setState({ courses, coursesSearch: courses });
    }
  };

  handleChangeCourseSearch = (val: string) => {
    const searchTerm = val.toLowerCase();
    const coursesSearch: Array<any> = this.state.courses.filter(
      (course: any) => {
        return course.title.toLowerCase().includes(searchTerm);
      }
    );
    this.setState({ courseSearch: val, coursesSearch });
  };

  handleShowJoinModal = () => {
    this.setState({ showJoinModal: true });
  };

  handleBranchJoinModalClose = () => {
    this.setState({ showJoinModal: false, joinModalMsg: null });
  };

  handleShowRequestedModal = () => {
    this.setState({ showRequestedModal: true });
  };

  handleBranchRequestedModalClose = () => {
    this.setState({ showRequestedModal: false, requestedModalMsg: null });
  };

  render() {
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    const testMode = this.state.branch?.billing?.mode !== "live";
    return (
      <>
        <JoinBranchModal
          clubId={this.state.clubId}
          branchId={this.state.branchId}
          show={this.state.showJoinModal}
          clubName={this.state.branch?.club?.name}
          branchName={this.state.branch?.name}
          handleClose={this.handleBranchJoinModalClose}
          requestNeeded={
            this.state.branch?.settings?.requestNeeded &&
            !(this.state.branch?.user?.role === "Owner") &&
            !this.context.isAdmin
          }
          refresh={this.requestBranch}
        />
        <RequestedBranchModal
          clubId={this.state.clubId}
          branchId={this.state.branchId}
          show={this.state.showRequestedModal}
          clubName={this.state.branch?.club?.name}
          branchName={this.state.branch?.name}
          handleClose={this.handleBranchRequestedModalClose}
          refresh={this.requestBranch}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Kurse" />
            <TestMode active={testMode} />
            {!this.context.user && (
              <div className="member-container">
                <h3>
                  Um alle Kurse angezeigt zu bekommen musst du ein SVF Mitglied
                  sein.
                </h3>
                <div className="member-align-container">
                  <div className="member-inner-container">
                    Du bist bereits ein SVF Mitglied?
                    <Link to="/login" className="no-underline">
                      <div className="member-login-btn">Anmelden</div>
                    </Link>
                  </div>
                  <div className="member-inner-container">
                    Du willst ein SVF Mitglied werden?
                    <Link to="/login" className="no-underline">
                      <div className="member-login-btn">
                        {this.state.branch?.settings?.requestNeeded &&
                        !(this.state.branch?.user?.role === "Owner") &&
                        !this.context.isAdmin
                          ? "Beitrittsanfrage senden"
                          : "Bereich beitreten"}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {this.state.branch?.user?.member === false && (
              <div className="member-container">
                <h3>Einige Kurse sind nur für SVF Mitglieder buchbar.</h3>
                <div className="member-align-container">
                  <div className="member-inner-container">
                    Du bist bereits ein SVF Mitglied?
                    <div
                      onClick={this.handleShowJoinModal}
                      className="member-login-btn"
                    >
                      {this.state.branch?.settings?.requestNeeded &&
                      !(this.state.branch?.user?.role === "Owner") &&
                      !this.context.isAdmin
                        ? "Beitrittsanfrage senden"
                        : "Bereich beitreten"}
                    </div>
                  </div>
                  <div className="member-inner-container">
                    Du willst ein SVF Mitglied werden?
                    <a
                      href="https://www.svfellbach.de/informationen/downloads.html"
                      className="no-underline"
                    >
                      <div className="member-login-btn">
                        SVF Mitglied werden
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {this.state.branch?.user?.member &&
              this.state.branch?.user?.requested && (
                <div className="member-container">
                  <h3>Einige Kurse sind nur für Mitglieder buchbar.</h3>
                  <div className="member-align-container">
                    <div className="member-inner-container">
                      Deine Beitrittsanfrage wird bearbeitet.
                      <div
                        onClick={this.handleShowRequestedModal}
                        className="member-login-btn"
                      >
                        Beitrittsanfrage zurückziehen
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            {!this.state.coursesLoaded ? (
              <>
                <div className="loading-container">
                  <HashLoader color={"#c31924"} size={100} loading={true} />
                </div>
              </>
            ) : (
              <>
                {this.state.courses.length === 0 &&
                  this.state.courseSearch === "" && (
                    <div className="no-courses-container">
                      In diesem Bereich gibts es aktuell keine Kurse.
                    </div>
                  )}
                {this.state.courseSearch !== "" &&
                  this.state.coursesSearch.length === 0 && (
                    <div className="no-courses-container">
                      Mit diesem Filter gibt es aktuell keine Kurse.
                    </div>
                  )}
                {this.state.coursesSearch.map((course: any) => (
                  <>
                    {course.courseType === "single-course" && (
                      <SingleCourseElement
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={course.id}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        where={course.location.name}
                        freePlaces={
                          testMode ? course.freePlacesTest : course.freePlaces
                        }
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        bookableFrom={firebaseDateToDateObj(
                          course.bookableDate
                        )}
                      />
                    )}
                    {course.courseType === "block-course" && (
                      <BlockCourseElement
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={course.id}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        courses={course.courses}
                        freePlaces={
                          testMode ? course.freePlacesTest : course.freePlaces
                        }
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        bookableFrom={firebaseDateToDateObj(
                          course.bookableDate
                        )}
                      />
                    )}
                    {course.courseType === "infinity-course" && (
                      <InfinityCourseElement
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={course.id}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        where={course.location?.name}
                        weekDay={course.weekDay}
                        freePlaces={
                          testMode ? course.freePlacesTest : course.freePlaces
                        }
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        bookableFrom={firebaseDateToDateObj(
                          course.bookableDate
                        )}
                      />
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
