import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./CourseParticipants.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import CourseParticipantsTable from "../../components/CourseParticipantsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactToPrint from "react-to-print";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import CourseParticipantsTablePrint from "../../components/CourseParticipantsTablePrint";
import CourseParticipantsType from "../../components/CourseParticipantsType";
import {
  addressToStr,
  dateToStr,
  dateToTimeStr,
  priceToFullStr,
} from "../../functions/utils";
import { CSVLink } from "react-csv";
import TestMode from "../../components/TestMode";
import SecButton from "../../components/SecButton";
import TextArea from "../../components/TextArea";
import SubTitle from "../../components/SubTitle";
import ErrorMessage from "../../components/ErrorMessage";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
  }>;

export default class CourseParticipants extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseMembers: Array<any>;
    courseMembersCSV: Array<any>;
    courseMembersLoaded: boolean;
    branchLoaded: boolean;
    branch: any;
    moreInfo: boolean;
    showType: number;
    courseLoaded: boolean;
    course: any;
    emailStr: string;
    emailsCopied: boolean;
    sendMailLoading: boolean;
    mailMessage: string;
    mailSendErrMsg: string | null;
    totalPriceTracked: number;
    totalPriceConfirmed: number;
    totalPriceAll: number;
  }
> {
  private printRef: any;
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      courseMembers: [],
      courseMembersCSV: [],
      courseMembersLoaded: false,
      branchLoaded: false,
      branch: {},
      moreInfo: false,
      showType: 1,
      courseLoaded: false,
      course: {},
      emailStr: "",
      emailsCopied: false,
      sendMailLoading: false,
      mailMessage: "",
      mailSendErrMsg: null,
      totalPriceTracked: 0,
      totalPriceConfirmed: 0,
      totalPriceAll: 0,
    };
  }

  componentDidMount = () => {
    this.requestUsersOfCourse();
    this.requestCourse();
    this.requestBranch();
  };

  requestUsersOfCourse = () => {
    this.setState({ courseMembersLoaded: false });
    fetchRetry(
      "getUsersOfCourse",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
      },
      1,
      5
    )
      .then(this.handleUsersOfCourse)
      .catch(this.handleError);
  };

  requestCourse = () => {
    fetchRetry(
      "getCourseFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  handleCourseSuccess = ({ data }: any) => {
    this.setState({ courseLoaded: true });
    if (data.success) {
      this.setState({ course: data.data[0] });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleUsersOfCourse = ({ data }: any) => {
    this.setState({ courseMembersLoaded: true });
    if (data.success) {
      const courseMembers = data.data;
      courseMembers.sort((a: any, b: any) =>
        a.created_at._seconds > b.created_at._seconds ? -1 : 1
      );
      courseMembers.sort((a: any, b: any) => (a.active > b.active ? -1 : 1));
      const courseMembersCSV = courseMembers
        .filter((member: any) => member.active)
        .map((member: any, index: number) => {
          return {
            "#": index + 1,
            Vorname: member.user.fName,
            Nachname: member.user.lName,
            Email: member.user.email,
            Addresse: addressToStr(member.address),
            Telefonnummer: member.phoneNumber,
            Geburtsdatum: member.birthDate,
          };
        });

      const emailStr = courseMembers
        .filter((member: any) => member.active)
        .map((member: any) => member.user.email)
        .join(";");

      const totalPriceAll = courseMembers
        .filter((member: any) => member.active)
        .reduce((acc: any, member: any) => {
          return acc + member.price;
        }, 0);
      const totalPriceTracked = courseMembers
        .filter(
          (member: any) =>
            member.active &&
            ((member.method === "manuell" && !member.paid) ||
              (member.method !== "manuell" && member.paymentStatus !== "paid"))
        )
        .reduce((acc: any, member: any) => {
          return acc + member.price;
        }, 0);
      const totalPriceConfirmed = courseMembers
        .filter(
          (member: any) =>
            member.active &&
            ((member.method === "manuell" && member.paid) ||
              (member.method !== "manuell" && member.paymentStatus === "paid"))
        )
        .reduce((acc: any, member: any) => {
          return acc + member.price;
        }, 0);

      this.setState({
        courseMembers,
        courseMembersCSV,
        emailStr,
        totalPriceAll,
        totalPriceTracked,
        totalPriceConfirmed,
      });
    }
  };

  handleMoreInfoChange = () => {
    this.setState({ moreInfo: !this.state.moreInfo });
  };

  selectShowType = (num: number) => {
    this.setState({ showType: num });
  };

  sendMail = () => {
    this.setState({ sendMailLoading: true });
    fetchRetry(
      "sendParticipantsMail",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        message: this.state.mailMessage,
      },
      1,
      5
    )
      .then(this.handleSendParticipantsMailSuccess)
      .catch(this.handleError);
  };

  handleSendParticipantsMailSuccess = ({ data }: any) => {
    this.setState({ sendMailLoading: false });
    if (data.success) {
      this.setState({
        mailMessage: "",
        mailSendErrMsg: null,
      });
      this.context.createInfo("Mail wurde erfolgreich gesendet.", "success", 4);
    } else {
      this.setState({ mailSendErrMsg: data.errorMsgDe });
    }
  };

  handleMailMessageChange = (val: string) => {
    this.setState({ mailMessage: val });
  };

  render() {
    if (
      !this.state.courseMembersLoaded ||
      !this.state.courseLoaded ||
      !this.state.branchLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Kursauswahl"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course`}
            />

            <div className="course-title-print-container">
              <h1>Teilnehmer</h1>
              <ReactToPrint
                trigger={() => {
                  return (
                    <FontAwesomeIcon
                      icon={["fas", "print"]}
                      size="lg"
                      className="course-print-icon"
                    />
                  );
                }}
                content={() => this.printRef}
              />
              <CSVLink
                data={this.state.courseMembersCSV}
                filename={`Teilnehmerliste_${this.state.branch?.name}_${
                  this.state.course?.title
                }_${dateToStr(new Date())}_${dateToTimeStr(new Date()).replace(
                  ":",
                  "."
                )}.csv`}
              >
                <FontAwesomeIcon
                  icon={["fas", "file-csv"]}
                  size="lg"
                  className="course-print-icon"
                />
              </CSVLink>
            </div>
            <TestMode active={this.state.branch?.billing?.mode !== "live"} />
            {this.state.branch?.user?.role === "Owner" && (
              <CourseParticipantsType
                type={this.state.showType}
                onChange={this.selectShowType}
              />
            )}
            <div>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(this.state.emailStr);
                  this.setState({ emailsCopied: true });
                }}
                className={`email-copy-container ${
                  this.state.emailsCopied ? "email-copy-container-copied" : ""
                }`}
              >
                <div className="flex">
                  E-Mail Adressen kopieren
                  {this.state.emailsCopied && (
                    <div className="email-copy-check-container">
                      <FontAwesomeIcon
                        icon={["fas", "check-circle"]}
                        size="sm"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.state.branch?.user?.role === "Owner" &&
              (this.state.course.courseType === "single-course" ||
                this.state.course.courseType === "block-course") && (
                <div className="inline-block">
                  <Link
                    className="create-btn"
                    to={`/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}/book-participant`}
                  >
                    <div className="create-icon-container">
                      <FontAwesomeIcon
                        icon={["fas", "plus-circle"]}
                        size="1x"
                      />
                    </div>
                    Mitglied nachbuchen
                  </Link>
                </div>
              )}
            <div className="print-container" ref={(el) => (this.printRef = el)}>
              <h1 className="print-title">Teilnehmerliste</h1>
              <div className="print-title-container">
                <div>{this.state.branch?.name}</div>
                <div>-</div>
                <div>{this.state.course?.title}</div>
                <div>-</div>
                <div>
                  Stand: {dateToStr(new Date())} {dateToTimeStr(new Date())}
                </div>
              </div>
              <CourseParticipantsTablePrint
                participants={this.state.courseMembers}
                moreInfo={this.state.moreInfo}
                printMode
                clubId={this.state.clubId}
                branchId={this.state.branchId}
                courseId={this.state.courseId}
              />
            </div>
            {this.state.showType === 1 ? (
              <>
                <CourseParticipantsTablePrint
                  participants={this.state.courseMembers}
                  moreInfo={this.state.moreInfo}
                  clubId={this.state.clubId}
                  branchId={this.state.branchId}
                  courseId={this.state.courseId}
                />
                <div className="medium-space"></div>
                {this.state.branch?.user?.role === "Owner" && (
                  <>
                    <SubTitle title="Mail an Teilnehmer senden" />
                    <TextArea
                      value={this.state.mailMessage}
                      onChange={this.handleMailMessageChange}
                      placeholder="Mail Nachricht"
                    />
                    <ErrorMessage message={this.state.mailSendErrMsg} />
                    <SecButton
                      change={!!this.state.mailMessage}
                      color="green"
                      loading={this.state.sendMailLoading}
                      onClick={this.sendMail}
                      title="Mail senden"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {/* <div className="course-participants-more-information-switch">
                  <SwitchSetting
                    value={this.state.moreInfo}
                    onChange={this.handleMoreInfoChange}
                    name="Weitere Informationen"
                  />
                </div> */}
                <CourseParticipantsTable
                  participants={this.state.courseMembers}
                  moreInfo={this.state.moreInfo}
                  clubId={this.state.clubId}
                  branchId={this.state.branchId}
                  courseId={this.state.courseId}
                />
                <div className="medium-space"></div>
                <SubTitle title="Einnahmen" />
                <div className="earnings-main-container">
                  <div className="earnings-inner-container">
                    <div className="earnings-title">Bestätigt </div>
                    <div className="earnings-price">
                      {priceToFullStr(this.state.totalPriceConfirmed)}€
                    </div>
                  </div>
                  <div className="earnings-inner-container">
                    <div className="earnings-title">Ausstehend </div>
                    <div className="earnings-price">
                      {priceToFullStr(this.state.totalPriceTracked)}€
                    </div>
                  </div>
                  <div className="earnings-inner-container">
                    <div className="earnings-title">Gesamt </div>
                    <div className="earnings-price">
                      {priceToFullStr(this.state.totalPriceAll)}€
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
