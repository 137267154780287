import React from "react";
import { Link } from "react-router-dom";
import "./NavbarElement.css";
import { MainContext } from "../../../contexts/MainContext";

export default class UserNavbarElement extends React.Component<
  { to: string },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Link
        to={this.props.to}
        className="navbar-element navbar-element-user"
        onClick={() => {}}
      >
        <div className="navbar-icon-container">
          <img
            src="/images/icons/colorUser.svg"
            className="navbar-profile-pic"
            alt="Benutzer Profilbild"
          />
        </div>
        <div className="navbar-element-user-textcontainer">
          <div className="white">
            {!this.context.user ? (
              "Nicht angemeldet"
            ) : (
              <>
                {!this.context.userData?.fName &&
                !this.context.userData?.lName ? (
                  <div className="name-spaceholder-container">
                    <div className="name-spaceholder name-spaceholder-fName"></div>
                    <div className="name-spaceholder name-spaceholder-lName"></div>
                  </div>
                ) : (
                  <>
                    {this.context.userData.fName} {this.context.userData.lName}
                  </>
                )}
              </>
            )}
          </div>
          {this.context.user ? (
            <Link
              className="navbar-element-user-logout"
              onClick={this.context.logout}
              to="/"
            >
              <span>Abmelden</span>
            </Link>
          ) : (
            <Link
              className="navbar-element-user-logout"
              onClick={this.context.logout}
              to="/login"
            >
              <span>Anmelden</span>
            </Link>
          )}
        </div>
      </Link>
    );
  }
}
