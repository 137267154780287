import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./CourseBooking.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { generateQRCode } from "../../functions/qr";
import BackElement from "../../components/BackElement";
import Title from "../../components/Title";
import TestMode from "../../components/TestMode";
import PaymentStatus from "../../components/PaymentStatus";
import {
  dateToFullStr,
  dateToSmallStr,
  firebaseDateToDateObj,
  formatCode,
  priceToStr,
  strToDate,
} from "../../functions/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { weekDays } from "../../services/constants";
import SecButton from "../../components/SecButton";
import ErrorMessage from "../../components/ErrorMessage";
import ConfirmationModal from "../../components/modals/ConfirmationModal";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
    courseBookingId: string;
    clientKey: string | undefined;
  }>;

export default class CourseBooking extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBookingId: string;
    clientKey: string | undefined;
    courseBookingLoaded: boolean;
    courseBookingData: any;
    qrCode: string;
    course: any;
    courseLoaded: boolean;
    cancellationLoading: boolean;
    errorMsgCancellation: null | string;
    showConfirmCancelCourse: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      courseBookingId: this.props.match.params.courseBookingId,
      clientKey: this.props.match.params.clientKey,
      courseBookingLoaded: false,
      courseBookingData: {},
      qrCode: "",
      course: {},
      courseLoaded: false,
      cancellationLoading: false,
      errorMsgCancellation: null,
      showConfirmCancelCourse: false,
    };
  }

  componentDidMount = () => {
    this.requestCourse();
    this.requestCoursesBooking();
  };

  requestCourse = () => {
    fetchRetry(
      "getCourseFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCourseSuccess = ({ data }: any) => {
    this.setState({ courseLoaded: true });
    if (data.success) {
      this.setState({ course: data.data[0] });
    }
  };

  requestCoursesBooking = () => {
    this.setState({ courseBookingLoaded: false });
    fetchRetry(
      "getCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
        clientKey: this.state.clientKey,
      },
      1,
      5
    )
      .then(this.handleCoursesBookingSuccess)
      .catch(this.handleError);
  };

  handleCoursesBookingSuccess = async ({ data }: any) => {
    this.setState({ courseBookingLoaded: true });
    if (data.success) {
      const courseBookingData = data.data;
      this.setState({
        courseBookingData: courseBookingData,
        qrCode: await generateQRCode(courseBookingData.courseBookingSimpleId),
      });
    }
  };

  handleCancellateCourse = () => {
    this.setState({ showConfirmCancelCourse: true });
  };

  hideConfirmationCancelCourse = () => {
    this.setState({ showConfirmCancelCourse: false });
  };

  handleCancellateCourseConfirmed = () => {
    this.setState({
      cancellationLoading: true,
      showConfirmCancelCourse: false,
    });
    fetchRetry(
      "cancelCourse",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        courseBookingId: this.state.courseBookingId,
        clientKey: this.state.clientKey,
      },
      1,
      5
    )
      .then(this.handleCoursesCanceledSuccess)
      .catch(this.handleError);
  };

  handleCoursesCanceledSuccess = ({ data }: any) => {
    this.setState({ cancellationLoading: false });
    if (data.success) {
      this.setState({ errorMsgCancellation: null });
      this.requestCoursesBooking();
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  render() {
    if (!this.state.courseBookingLoaded || !this.state.courseLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmCancelCourse}
          title="Kurs stornieren"
          msg="Bist du sicher, dass du diesen Kurs stornieren willst?"
          handleClose={this.hideConfirmationCancelCourse}
          handleConfirm={this.handleCancellateCourseConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            {this.context.user ? (
              <BackElement
                text="zurück zu meinen Kursen"
                to={`/club/${this.state.clubId}/branch/${this.state.branchId}/mycourses`}
              />
            ) : (
              <BackElement
                text="zurück zur Kursauswahl"
                to={`/club/${this.state.clubId}/branch/${this.state.branchId}/course`}
              />
            )}
            <TestMode active={this.state.courseBookingData.testmode} />
            <Title title="Kurs" />
            <div className="courseBooking-course-info-container">
              <div>
                {!this.state.course.imgLowRes ? (
                  <div className="single-course-img">
                    <FontAwesomeIcon
                      icon={["fas", "image"]}
                      size="6x"
                      className="image-icon"
                    />
                  </div>
                ) : (
                  <img
                    className="single-course-img"
                    src={this.state.course.imgLowRes}
                    alt="Kursbild"
                  />
                )}
              </div>
              <div>
                {this.state.course.courseType === "single-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        {dateToFullStr(
                          firebaseDateToDateObj(this.state.course.startTime)
                        )}
                      </span>
                    </div>
                    {this.state.course?.location?.name && (
                      <div className="course-text-container">
                        Wo:{" "}
                        <span className="normal-weight">
                          {this.state.course.location?.name}
                        </span>
                      </div>
                    )}
                    {this.state.course.bookingMailMessage && (
                      <div className="course-text-container">
                        Notiz:{" "}
                        <span className="normal-weight">
                          {this.state.course.bookingMailMessage}
                        </span>
                      </div>
                    )}
                  </>
                )}
                {this.state.course.courseType === "block-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        {this.state.course.courses.map(
                          (course: any, index: number) => (
                            <div key={index}>
                              -{" "}
                              {dateToFullStr(
                                firebaseDateToDateObj(course.startTime)
                              )}{" "}
                              ({course.location.name})
                            </div>
                          )
                        )}
                      </span>
                    </div>
                    {this.state.course.bookingMailMessage && (
                      <div className="course-text-container">
                        Notiz:{" "}
                        <span className="normal-weight">
                          {this.state.course.bookingMailMessage}
                        </span>
                      </div>
                    )}
                  </>
                )}
                {this.state.course.courseType === "infinity-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        jeden {weekDays[this.state.course.weekDay].name}
                      </span>
                    </div>
                    {this.state.course.negativeDates &&
                      this.state.course.negativeDates.length > 0 && (
                        <div className="course-text-container">
                          Ausfallende Termine:{" "}
                          <span className="normal-weight">
                            {this.state.course.negativeDates
                              .filter((dateStr: string) => {
                                const date = strToDate(dateStr);
                                return date > new Date();
                              })
                              .sort((a: any, b: any) =>
                                strToDate(a).getTime() > strToDate(b).getTime()
                                  ? 1
                                  : -1
                              )
                              .map((date: string, index: number) => (
                                <div key={index}>- {date} </div>
                              ))}
                          </span>
                        </div>
                      )}
                    {this.state.course?.location?.name && (
                      <div className="course-text-container">
                        Wo:{" "}
                        <span className="normal-weight">
                          {this.state.course?.location?.name}
                        </span>
                      </div>
                    )}
                    {this.state.course.bookingMailMessage && (
                      <div className="course-text-container">
                        Notiz:{" "}
                        <span className="normal-weight">
                          {this.state.course.bookingMailMessage}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <Title title="Buchungs Informationen" />
            <div>
              <table className="course-booking-information-table">
                <tbody>
                  <tr className="no-border">
                    <td>Status</td>
                    <td>
                      {this.state.courseBookingData.active
                        ? "Gebucht"
                        : this.state.courseBookingData.refunded
                        ? "Storniert"
                        : "Nicht Gebucht"}
                    </td>
                  </tr>
                  <tr>
                    <td>Methode</td>
                    <td>
                      {this.state.courseBookingData.method
                        ? this.state.courseBookingData.method
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Bezahl Status</td>
                    <td>
                      <div className="flex">
                        <PaymentStatus
                          type={this.state.courseBookingData.paymentStatus}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Preis</td>
                    <td>{priceToStr(this.state.courseBookingData.price)}€</td>
                  </tr>
                  <tr>
                    <td>Buchungszeitpunkt</td>
                    <td>
                      {dateToSmallStr(
                        firebaseDateToDateObj(
                          this.state.courseBookingData.created_at
                        )
                      )}
                    </td>
                  </tr>
                  {this.state.courseBookingData.refunded ? (
                    <>
                      <tr>
                        <td>Stornierungs Status</td>
                        <td>
                          <div className="flex">
                            <PaymentStatus
                              type={this.state.courseBookingData.refundStatus}
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {this.state.courseBookingData.cancellationDays !=
                        null && (
                        <>
                          <tr>
                            <td>Stornierungsfrist</td>
                            <td>
                              {" bis "}
                              {dateToFullStr(
                                firebaseDateToDateObj(
                                  this.state.courseBookingData.cancellationDate
                                )
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <div className="qrCode-container">
                <img src={this.state.qrCode} alt="qr-code" />
                <span>
                  <span className="bold">Code </span>
                  {formatCode(
                    this.state.courseBookingData.courseBookingSimpleId
                  )}
                </span>
              </div>
              {this.state.course.cancellationDays !== null && (
                <div className="cancellationContainer">
                  <ErrorMessage message={this.state.errorMsgCancellation} />
                  <SecButton
                    change={!this.state.courseBookingData.refunded}
                    color="red"
                    loading={this.state.cancellationLoading}
                    onClick={this.handleCancellateCourse}
                    title="Kurs stornieren"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
