import React from "react";
import "./Switch.css";

export default class Switch extends React.Component<
  { value: boolean; onChange: any },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <label className="switch">
          <input
            type="checkbox"
            checked={this.props.value}
            onChange={this.props.onChange}
          />
          <span className="slider round"></span>
        </label>
      </>
    );
  }
}
