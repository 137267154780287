import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Settings.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import SwitchSetting from "../../components/SwitchSetting";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Settings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    requestNeeded: boolean;
    change: boolean;
    updateLoading: boolean;
    useMembershipNumber: boolean;
    askForMembershipNumber: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      requestNeeded: false,
      change: false,
      updateLoading: false,
      useMembershipNumber: false,
      askForMembershipNumber: false,
    };
  }

  componentDidMount = () => {
    this.requestClub();
  };

  requestClub = () => {
    fetchRetry(
      "getClubsFromClubId",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data[this.state.clubId];
      this.setState(
        {
          club: club,
          useMembershipNumber: club.settings?.useMembershipNumber,
          askForMembershipNumber: club.settings?.askForMembershipNumber,
        },
        this.checkChange
      );
    }
  };

  updateClub = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateClub",
      {
        clubId: this.state.clubId,
        useMembershipNumber: this.state.useMembershipNumber,
        askForMembershipNumber: this.state.askForMembershipNumber,
      },
      1,
      5
    )
      .then(this.handleSuccessClubUpdate)
      .catch(this.handleError);
  };

  handleSuccessClubUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.context.requestClub(this.state.clubId);
      this.requestClub();
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleUseMembershipNumber = () => {
    this.setState(
      { useMembershipNumber: !this.state.useMembershipNumber },
      this.checkChange
    );
  };

  handleAskForMembershipNumber = () => {
    this.setState(
      { askForMembershipNumber: !this.state.askForMembershipNumber },
      this.checkChange
    );
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.useMembershipNumber !==
          this.state.club?.settings?.useMembershipNumber ||
        this.state.askForMembershipNumber !==
          this.state.club?.settings?.askForMembershipNumber,
    });
  };

  render() {
    if (!this.state.clubLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Einstellungen" />
            <SwitchSetting
              value={this.state.useMembershipNumber}
              onChange={this.handleUseMembershipNumber}
              name="Mitgliedsnummer verwenden"
            />
            <SwitchSetting
              value={this.state.askForMembershipNumber}
              onChange={this.handleAskForMembershipNumber}
              name="Mitglieder nach Mitgliedsnummer fragen"
            />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateClub}
              title="Speichern"
            />
          </div>
        </div>
      </>
    );
  }
}
