import React from "react";
import {
  dateToFullStr,
  formatDateUnit,
  getMonthNameFromDate,
  priceToStr,
} from "../functions/utils";
import "./CourseTerms.css";

export default class CourseTerms extends React.Component<
  {
    single: boolean;
    termsAccepted: boolean;
    toggleTermsAccepted: any;
    initialPrice: number;
    price: number;
    startDate: any;
    noticePeriod: number;
    noticePeriodDateUnit: string;
    cancellationDays: number;
    cancellationDate: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.single ? (
          <div className="accept-container">
            <input
              type="checkbox"
              className="accept-container-checkbox"
              checked={this.props.termsAccepted}
              onClick={this.props.toggleTermsAccepted}
            />
            <div>
              Dieser Kurs kostet {priceToStr(this.props.price)}€.
              {this.props.cancellationDays !== null ? (
                <>
                  {" "}
                  Die Stornierungsfrist dieses Kurses ist{" "}
                  {dateToFullStr(this.props.cancellationDate)}.
                </>
              ) : (
                <> Dieser Kurs kann nicht storniert werden.</>
              )}
            </div>
          </div>
        ) : (
          <div className="accept-container">
            <input
              type="checkbox"
              className="accept-container-checkbox"
              checked={this.props.termsAccepted}
              onClick={this.props.toggleTermsAccepted}
            />
            <div>
              {this.props.initialPrice === this.props.price ? (
                <>
                  Der Kursstart deines Kurses ist{" "}
                  {getMonthNameFromDate(this.props.startDate)}. Es werden{" "}
                  {priceToStr(this.props.price)}€ pro Monat abgerechnet.
                </>
              ) : (
                <>
                  Der Kursstart deines Kurses ist{" "}
                  {getMonthNameFromDate(this.props.startDate)}{" "}
                  {this.props.startDate.getFullYear()}, wir berechnen für{" "}
                  {getMonthNameFromDate(this.props.startDate)}{" "}
                  {priceToStr(this.props.initialPrice)}€. Fortlaufend werden{" "}
                  {priceToStr(this.props.price)}€ abgerechnet.
                </>
              )}{" "}
              Die Kündigungsfrist beträgt {this.props.noticePeriod}{" "}
              {formatDateUnit(
                this.props.noticePeriodDateUnit,
                this.props.noticePeriod
              )}
              .
            </div>
          </div>
        )}
      </>
    );
  }
}
