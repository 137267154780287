import React from "react";
import "./Terms.css";

export default class Privacy extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="main-info-screen">
          <h1>Nutzungsbedinungen</h1>
        </div>
      </>
    );
  }
}
