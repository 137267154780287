import React from "react";
import { RouteComponentProps, Route, Redirect } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import "./Branch.css";
import TopMenuBar from "../components/TopMenuBar";
import Booking from "./BranchSubScreens/Booking";
import Course from "./BranchSubScreens/Course";
import EditCourse from "./BranchSubScreens/EditCourse";
import SingleCourse from "./BranchSubScreens/SingleCourse";
import BranchNavbar from "../components/navbars/BranchNavbar";
import Footer from "../components/Footer";
import MyCourses from "./MyCourses";
import CreateCourse from "./BranchSubScreens/CreateCourse";
import CourseParticipants from "./BranchSubScreens/CourseParticipants";
import CourseBookingConfirmation from "./BranchSubScreens/CourseBookingConfirmation";
import Profile from "./Profile";
import { HashLoader } from "react-spinners";
import { fetchRetry } from "../functions/request";
import BookParticipant from "./BranchSubScreens/BookParticipant";
import CourseParticipant from "./BranchSubScreens/CourseParticipant";
import ManageCourses from "./BranchSubScreens/ManageCourses";
import CourseBooking from "./BranchSubScreens/CourseBooking";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    site: string;
  }>;

export default class Branch extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branch: any;
    branchLoaded: boolean;
    showMobileNav: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branch: {},
      branchLoaded: false,
      showMobileNav: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data?.data;
      this.setState({
        branch,
      });
    }
  };

  handleShowMobileNav = () => {
    this.setState({ showMobileNav: true });
  };

  handleHideMobileNav = () => {
    this.setState({ showMobileNav: false });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <div className="br-main-container">
          <BranchNavbar
            selectedScreen={this.props.match.params.site}
            clubId={this.state.clubId}
            branchId={this.state.branchId}
            isAdmin={
              this.context.branch?.user?.role === "Owner" ||
              this.context.isAdmin
            }
            isViewOnly={this.context.branch?.user?.role === "ViewOnly"}
            showMobile={this.state.showMobileNav}
            hideMobileNav={this.handleHideMobileNav}
            useBooking={this.context.branch?.settings?.useBooking}
            useCourse={this.context.branch?.settings?.useCourse}
          />
          <TopMenuBar handleShowMobileNav={this.handleShowMobileNav} />
          <div className="br-inner-container">
            <div className="br-padding-container">
              {!this.state.branchLoaded ? (
                <>
                  <div className="loading-container">
                    <HashLoader color={"#c31924"} size={100} loading={true} />
                  </div>
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/booking"
                    component={Booking}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/courseBooking/:courseBookingId"
                    component={CourseBookingConfirmation}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/courseBooking/:courseBookingId/:clientKey"
                    component={CourseBookingConfirmation}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/manage-course"
                    component={ManageCourses}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/mycourses/:courseId/courseBooking/:courseBookingId/:clientKey"
                    component={CourseBooking}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/mycourses/:courseId/courseBooking/:courseBookingId"
                    component={CourseBooking}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/course"
                    component={Course}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/mycourses"
                    component={MyCourses}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/createCourse"
                    component={CreateCourse}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/course/:courseId"
                    component={SingleCourse}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/edit-course/:courseId"
                    component={EditCourse}
                  />

                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/participants/:courseId/book-participant"
                    component={BookParticipant}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/participants/:courseId/participant/:courseParticipantId"
                    component={CourseParticipant}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/participants/:courseId"
                    component={CourseParticipants}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch/:branchId/profile"
                    component={Profile}
                  />
                  <Route exact path="/club/:clubId/branch/:branchId">
                    {this.state.branch.settings?.useBooking && (
                      <Redirect
                        to={`/club/${this.state.clubId}/branch/${this.state.branchId}/booking`}
                      />
                    )}
                    {!this.state.branch.settings?.useBooking &&
                      this.state.branch.settings?.useCourse && (
                        <Redirect
                          to={`/club/${this.state.clubId}/branch/${this.state.branchId}/course`}
                        />
                      )}
                  </Route>
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
