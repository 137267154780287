import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./Profile.css";
import "../App.css";
import HashLoader from "react-spinners/HashLoader";
import { fetchRetry } from "../functions/request";
import SubTitle from "../components/SubTitle";
import Input from "../components/Input";
import SecButton from "../components/SecButton";
import { reformatDate, reformatDateReverse } from "../functions/utils";
import Title from "../components/Title";
import ErrorMessage from "../components/ErrorMessage";
import qs from "qs";

type Props = {};
type ComposedProps = Props & RouteComponentProps<{}>;

export default class Profile extends React.Component<
  ComposedProps,
  {
    userLoaded: boolean;
    userData: any;
    updateLoading: boolean;
    fName: string;
    lName: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    change: boolean;
    errorMessage: string | null;
    redirect: string | null;
    completeTitle: boolean;
  }
> {
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      userLoaded: false,
      userData: {},
      fName: "",
      lName: "",
      updateLoading: false,
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      change: false,
      errorMessage: null,
      redirect: null,
      completeTitle:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).complete === "true",
    };
  }

  componentDidMount = () => {
    this.requestUser();
  };

  requestUser = () => {
    fetchRetry("getOwnUser", {}, 1, 5)
      .then(this.handleUserData)
      .catch(this.handleError);
  };

  handleUserData = ({ data }: any) => {
    this.setState({ userLoaded: true });
    if (data.success) {
      const userData = data.data;
      this.setState({
        userData: userData,
        fName: userData.fName ? userData.fName : "",
        lName: userData.lName ? userData.lName : "",
        street: userData.address?.street ? userData.address.street : "",
        houseNumber: userData.address?.houseNumber
          ? userData.address.houseNumber
          : "",
        zipcode: userData.address?.zipcode ? userData.address.zipcode : "",
        city: userData.address?.city ? userData.address.city : "",
        phoneNumber: userData.phoneNumber ? userData.phoneNumber : "",
        birthDate: reformatDateReverse(userData.birthDate),
      });
    }
  };

  handleUpdateUserData = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateUserData",
      {
        fName: this.state.fName,
        lName: this.state.lName,
        address: {
          city: this.state.city,
          zipcode: this.state.zipcode,
          street: this.state.street,
          houseNumber: this.state.houseNumber,
        },
        phoneNumber: this.state.phoneNumber,
        birthDate: reformatDate(this.state.birthDate),
      },
      1,
      5
    )
      .then(this.handleSuccessUpdatedUserData)
      .catch(this.handleError);
  };

  handleSuccessUpdatedUserData = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      const redirect: any = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }).redirect;
      if (redirect) {
        this.setState({ redirect });
      }
      this.setState({ errorMessage: null });
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val }, this.checkChange);
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val }, this.checkChange);
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val }, this.checkChange);
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val }, this.checkChange);
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val }, this.checkChange);
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val }, this.checkChange);
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val }, this.checkChange);
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value }, this.checkChange);
  };

  checkChange = () => {
    /* eslint eqeqeq: 0 */
    this.setState({
      change:
        this.state.fName != this.state.userData.fName ||
        this.state.lName != this.state.userData.lName ||
        this.state.street != this.state.userData.address?.street ||
        this.state.houseNumber != this.state.userData.address?.houseNumber ||
        this.state.zipcode != this.state.userData.address?.zipcode ||
        this.state.city != this.state.userData.address?.city ||
        this.state.phoneNumber != this.state.userData.phoneNumber ||
        this.state.birthDate != this.state.userData.birthDate,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.userLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title
              title={
                this.state.completeTitle
                  ? "Bitte vervollständige zuerst dein Profil"
                  : "Profil"
              }
            />
            <SubTitle title="Name" />
            <div className="course-time-input-container">
              <Input
                value={this.state.fName}
                onChange={this.handleFNameChange}
                placeholder="Vorname"
              />
              <Input
                value={this.state.lName}
                onChange={this.handleLNameChange}
                placeholder="Nachname"
              />
            </div>
            <SubTitle title="Adresse" />
            <div className="course-time-input-container">
              <Input
                value={this.state.street}
                onChange={this.handleStreetChange}
                placeholder="Straße"
                className="street-input"
              />
              <Input
                value={this.state.houseNumber}
                onChange={this.handleHouseNumberChange}
                placeholder="Nr."
                className="house-number-input"
              />
            </div>
            <div className="profile-location-container">
              <Input
                value={this.state.zipcode}
                onChange={this.handleZipcodeChange}
                placeholder="PLZ"
                className="zipcode-input"
              />
              <Input
                value={this.state.city}
                onChange={this.handleCityChange}
                placeholder="Stadt"
                className="city-input"
              />
            </div>
            <SubTitle title="Telefonnummer" />
            <Input
              value={this.state.phoneNumber}
              onChange={this.handlePhoneNumberChange}
              placeholder="Telefonnummer"
            />
            <SubTitle title="Geburtsdatum" />
            <input
              type="date"
              className="course-input"
              value={this.state.birthDate}
              onChange={this.handleBirthDateChange}
            />
            <ErrorMessage message={this.state.errorMessage} />
            <div className="modal-btn-container">
              <SecButton
                title="Speichern"
                onClick={this.handleUpdateUserData}
                color="green"
                change={true}
                loading={this.state.updateLoading}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
