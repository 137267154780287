import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export default class Footer extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footer-container">
        <Link to="/impressum" className="no-underline footer-links">
          Impressum
        </Link>
        <Link to="/privacy" className="no-underline footer-links">
          Datenschutzerklärung
        </Link>
        {/* <Link to="/terms" className="white no-underline">
          Nutzungsbedinungen
        </Link> */}
      </div>
    );
  }
}
