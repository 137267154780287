import { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { auth } from "../services/firebase";
import { fetchRetry } from "../functions/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const [userData, setUserData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [infoArr, setInfoArr] = useState([]);
  const [clubId, setClubId] = useState(null);
  const [clubLoaded, setClubLoaded] = useState(false);
  const [club, setClub] = useState({});
  const [branchId, setBranchId] = useState(null);
  const [branchLoaded, setBranchLoaded] = useState(false);
  const [branch, setBranch] = useState({});
  const [path, setPath] = useState([]);

  const [notificationArr, setNotificationArr] = useState([]);
  const [notificationsLoaded, setNotificationsLoaded] = useState([]);

  // notification Context
  useEffect(() => {
    requestUnreadNotifications();
    // const interval = setInterval(() => {
    //   // requestUnreadNotifications();
    // }, 300000);
    // return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function requestUnreadNotifications() {
    fetchRetry(
      "getUnseenNotifications",
      {
        limit: 10,
      },
      1,
      5
    )
      .then(handleUnreadNotifications)
      .catch(handleError);
  }

  function handleUnreadNotifications({ data }) {
    setNotificationsLoaded(true);
    if (data.success) {
      const notifications = data.data;
      const notificationArr = [];
      for (const notificationId in notifications) {
        const notification = notifications[notificationId];
        notificationArr.push(notification);
      }
      setNotificationArr(notificationArr);
    }
  }

  // info Context
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authChange);
    window.ononline = function () {
      deleteNoInternetInfo();
    };

    window.onoffline = function () {
      createNoInternetInfo();
    };

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function deleteInfo(id) {
    const newInfoArr = [...infoArr].filter((currInfo) => currInfo.id !== id);
    setInfoArr(newInfoArr);
  }

  function createNoInternetInfo() {
    const newInfoArr = [...infoArr];
    newInfoArr.push({
      text: "Keine Internetverbindung",
      type: "error",
      time: -1,
      id: "no-internet-connection",
    });
    setInfoArr(newInfoArr);
  }

  function deleteNoInternetInfo() {
    const newInfoArr = [...infoArr].filter(
      (currInfo) => currInfo.id !== "no-internet-connection"
    );
    setInfoArr(newInfoArr);
  }

  function createInfo(text, type, time) {
    const newInfoArr = [...infoArr];
    newInfoArr.push({
      text,
      type,
      time,
      id: Math.random().toString(36).substring(2, 15),
    });
    setInfoArr(newInfoArr);
  }

  // auth context
  function authChange(user) {
    setUser(user);
    requestOwnUserData();
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        setIsAdmin(!!idTokenResult.claims.admin);
      });
    } else {
      setIsAdmin(false);
    }
    requestData();
  }

  function requestOwnUserData() {
    setUserLoaded(false);
    fetchRetry("getOwnUser", {}, 1, 5).then(handleUserData).catch(handleError);
  }

  function handleUserData({ data }) {
    setUserLoaded(true);
    setUserData(data.data);
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function forgotPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function signInWithGoogle() {
    const base_provider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(base_provider);
    // .signInWithRedirect(base_provider)
  }

  function logout() {
    setUser(null);
    setUserData(null);
    return auth.signOut();
  }

  function requestClub(reqClubId) {
    setClubId(reqClubId);
    fetchRetry(
      "getClubsFromClubId",
      {
        clubId: reqClubId,
      },
      1,
      5
    )
      .then(({ data }) => {
        handleClub(reqClubId, data);
      })
      .catch(handleError);
  }

  function handleClub(reqClubId, data) {
    setClubLoaded(true);
    if (data.success) {
      const club = data.data[reqClubId];
      setClub(club);
      setClubId(reqClubId);
      if (getPathDepth() === 1) {
        setPath([
          {
            name: club.name,
            link: `/club/${reqClubId}`,
          },
        ]);
      }
    }
  }

  function requestData() {
    // initialy load branch
    const urlArr = history.location.pathname.split("/");
    if (urlArr[1] === "club" || urlArr[1] === "club-settings") {
      const newClubId = urlArr[2];
      if (newClubId !== "" && newClubId !== clubId) {
        requestClub(newClubId);
      }
    }
    if (urlArr[1] === "club") {
      const newClubId = urlArr[2];
      if (urlArr[3] === "branch" || urlArr[3] === "branch-settings") {
        const newBranchId = urlArr[4];
        if (newBranchId !== "" && newBranchId !== branchId) {
          requestBranch(newClubId, newBranchId);
        }
      }
    }
  }

  // branch context
  useEffect(() => {
    requestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPathDepth() {
    const urlArr = history.location.pathname.split("/");
    if (urlArr[1] === "club" || urlArr[1] === "club-settings") {
      if (urlArr[3] === "branch" || urlArr[3] === "branch-settings") {
        return 2;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  }

  useEffect(() => {
    return history.listen((location) => {
      const urlArr = location.pathname.split("/");
      if (urlArr[1] === "club" || urlArr[1] === "club-settings") {
        const newClubId = urlArr[2];
        if (newClubId === "") {
          setClubId("");
          setClubLoaded(false);
          setClub({});
        } else if (newClubId !== clubId) {
          setClubId(newClubId);
          setClubLoaded(false);
          setClub({});
          requestClub(newClubId);
        }
      }
      if (urlArr[1] === "club") {
        const newClubId = urlArr[2];
        if (urlArr[3] === "branch" || urlArr[3] === "branch-settings") {
          const newBranchId = urlArr[4];
          if (newBranchId === "") {
            setBranchId("");
            setBranchLoaded(false);
            setBranch({});
          } else if (newBranchId !== branchId) {
            setBranchId(newBranchId);
            setBranchLoaded(false);
            setBranch({});
            requestBranch(newClubId, newBranchId);
          } else if (branchLoaded) {
            setPath([
              {
                name: branch.club?.name,
                link: `/club/${clubId}`,
              },
              {
                name: branch.name,
                link: `/club/${clubId}/branch/${branchId}`,
              },
            ]);
          }
        }
      }
      const depth = getPathDepth();
      if (path.length > depth) {
        setPath(path.slice(0, depth));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, clubId, branchId, path]);

  function requestBranch(reqClubId, reqBranchId) {
    // if (reqClubId !== clubId) {
    //   setClubLoaded(false);
    // }
    setBranchId(reqBranchId);
    fetchRetry(
      "getBranchFromId",
      {
        clubId: reqClubId,
        branchId: reqBranchId,
      },
      1,
      5
    )
      .then(({ data }) => {
        handleBranch(reqClubId, reqBranchId, data);
      })
      .catch(handleError);
  }

  function handleBranch(reqClubId, reqBranchId, data) {
    setBranchLoaded(true);
    if (data.success) {
      const branch = data.data;
      setBranch(branch);
      setBranchId(reqBranchId);

      if (getPathDepth() === 2) {
        setPath([
          {
            name: branch.club?.name,
            link: `/club/${reqClubId}`,
          },
          {
            name: branch.name,
            link: `/club/${reqClubId}/branch/${reqBranchId}`,
          },
        ]);
      }
    }
  }

  const value = {
    user,
    userData,
    signup,
    login,
    signInWithGoogle,
    logout,
    forgotPassword,
    isAdmin,
    userLoaded,
    createInfo,
    clubId,
    club,
    clubLoaded,
    requestClub,
    branchId,
    branch,
    branchLoaded,
    requestBranch,
    path,
    setPath,
    notificationArr,
    notificationsLoaded,
    requestUnreadNotifications,
  };

  function handleError(err) {
    console.error(err);
  }

  return (
    <MainContext.Provider value={value}>
      <div className="main-info-container">
        {infoArr.map((info, i) => (
          <div className="main-info-element" key={i}>
            <div className="main-info-inner-element">
              <div className="main-info-icon-container">
                {info.type === "error" && (
                  <FontAwesomeIcon
                    icon={["fas", "exclamation-circle"]}
                    size="6x"
                    className="main-info-template-icon main-info-error-icon"
                  />
                )}
                {info.type === "info" && (
                  <FontAwesomeIcon
                    icon={["fas", "info-circle"]}
                    size="6x"
                    className="main-info-template-icon main-info-info-icon"
                  />
                )}
                {info.type === "success" && (
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    size="6x"
                    className="main-info-template-icon main-info-success-icon"
                  />
                )}
              </div>
              <div className="main-info-text-container">{info.text}</div>
              <div
                className="main-info-close-icon"
                onClick={() => {
                  deleteInfo(info.id);
                }}
              >
                <FontAwesomeIcon icon={["fas", "times"]} />
              </div>
            </div>
            {info.time !== -1 && (
              <div
                className="main-info-progress-container"
                onAnimationEnd={() => {
                  deleteInfo(info.id);
                }}
                style={{ animationDuration: `${info.time}s` }}
              ></div>
            )}
          </div>
        ))}
      </div>
      {children}
    </MainContext.Provider>
  );
};
