import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./BranchModal.css";
import "./JoinBranchModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageContainer from "../MessageContainer";
import SecButton from "../SecButton";
import Input from "../Input";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";

export default class JoinBranchModal extends React.Component<
  {
    clubId: string;
    branchId: string;
    show: boolean;
    branchName: string;
    clubName: string;
    handleClose: any;
    requestNeeded: boolean;
    refresh: any;
  },
  {
    membershipId: string;
    loading: boolean;
    msg: string;
    msgType: "error" | "success";
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      membershipId: "",
      loading: false,
      msg: "",
      msgType: "success",
    };
  }

  handleMembershipIdChange = (val: string) => {
    this.setState({ membershipId: val });
  };

  handleJoinBranch = () => {
    this.setState({ loading: true });
    fetchRetry(
      "requestToJoinBranch",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        membershipNumber: this.state.membershipId
          ? this.state.membershipId
          : null,
      },
      1,
      5
    )
      .then(this.handleRequestSuccess)
      .catch(this.handleError);
  };

  handleRequestSuccess = ({ data }: any) => {
    this.setState({ loading: false });
    if (data.success) {
      if (this.props.requestNeeded) {
        this.context.createInfo("Anfrage erfolgreich gesendet.", "success", 4);
      } else {
        this.context.createInfo(
          "Du bist diesem Bereich erfolgreich beigetreten.",
          "success",
          4
        );
      }
      this.props.refresh();
      this.props.handleClose();
    } else {
      this.setState({
        msg: data.errorMsgDe,
        msgType: "error",
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="join Branch"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="branch-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon
                icon={["fas", "times"]}
                className="join-branch-cross"
              />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">
                {this.props.branchName}{" "}
                <small className="branch-title"> | {this.props.clubName}</small>
              </span>
            </div>
            <MessageContainer msg={this.state.msg} type={this.state.msgType} />
            <br />
            <div className="margin-bottom">
              <span className="branch-text">
                Um diesem Bereich beizutreten, klicke auf{" "}
                <b>
                  "
                  {this.props.requestNeeded
                    ? "Anfrage senden"
                    : "Bereich beitreten"}
                  "
                </b>
                .<br />
                {this.props.requestNeeded && (
                  <>
                    Sobald du vom Administrator akzeptiert wirst, kannst du
                    Buchungen in diesem Bereich vornehmen.
                  </>
                )}
              </span>
            </div>
            <Input
              name="membershipId-input"
              placeholder="SVF Mitgliedsnummer falls vorhanden"
              value={this.state.membershipId}
              onChange={this.handleMembershipIdChange}
            />
            <SecButton
              change={true}
              color="green"
              loading={this.state.loading}
              onClick={this.handleJoinBranch}
              title={
                this.props.requestNeeded
                  ? "Anfrage senden"
                  : "Bereich beitreten"
              }
            />
          </div>
        </Modal>
      </>
    );
  }
}
