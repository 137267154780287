export const molliePaymentStatus: any = {
  paid: { name: "bezahlt", color: "#4cd964", active: true },
  open: { name: "offen", color: "#ffb100", active: true },
  authorized: { name: "autorisiert", color: "#ffb100", active: true },
  failed: { name: "fehlgeschlagen", color: "#ff3b30", active: false },
  expired: { name: "verfallen", color: "#b3b3b3", active: false },
  canceled: { name: "abgebrochen", color: "#b3b3b3", active: false },
  free: { name: "gebucht", color: "#4cd964", active: true },
  manuelly: { name: "gebucht", color: "#ffb100", active: true },
  manuellyPaid: { name: "gebucht", color: "#4cd964", active: true },
  pending: { name: "ausstehend", color: "#ffb100", active: true },
  refunded: { name: "rückerstattet", color: "#07f", active: true },
};

export const month: any = [
  { name: "Januar", shortName: "Jan." },
  { name: "Februar", shortName: "Feb." },
  { name: "März", shortName: "März" },
  { name: "April", shortName: "Apr." },
  { name: "Mai", shortName: "Mai" },
  { name: "Juni", shortName: "Jun." },
  { name: "Juli", shortName: "Jul." },
  { name: "August", shortName: "Aug." },
  { name: "September", shortName: "Sept." },
  { name: "Oktober", shortName: "Okt." },
  { name: "November", shortName: "Nov." },
  { name: "Dezember", shortName: "Dez." },
];

export const weekDays: any = [
  {
    id: "1",
    name: "Montag",
  },
  {
    id: "2",
    name: "Dienstag",
  },
  {
    id: "3",
    name: "Mittwoch",
  },
  {
    id: "4",
    name: "Donnerstag",
  },
  {
    id: "5",
    name: "Freitag",
  },
  {
    id: "6",
    name: "Samstag",
  },
  {
    id: "0",
    name: "Sonntag",
  },
];

export const dateUnits: any = [
  {
    id: "1",
    name: "Tage",
    dbName: "day",
  },
  {
    id: "2",
    name: "Wochen",
    dbName: "week",
  },
  {
    id: "3",
    name: "Monate",
    dbName: "month",
  },
  {
    id: "4",
    name: "Jahre",
    dbName: "year",
  },
];

export const dateUnitsArr: Array<string> = ["day", "week", "month", "year"];

export const courseTypes: any = {
  "infinity-course": {
    name: "Fortlaufender Kurs",
  },
  "single-course": {
    name: "Einmaliger Kurs",
  },
  "block-course": {
    name: "Blockkurs",
  },
};

export const roles: any = {
  Owner: {
    germName: "Admin",
  },
  ViewOnly: {
    germName: "Mitarbeiter",
  },
  Member: {
    germName: "Mitglied",
  },
};
